import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { ReactNode, useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import { authServiceAtom, refetchCurrentUserAtom } from '../core/atoms'
import { ForgotPasswordModal } from './forgot_password_modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { imgCloseWhite } from 'core/images'

import Modal from 'react-bootstrap/Modal'

type Props = {
  isModalShowing: boolean
  onClose?: () => void
}

export function LoginModal({ isModalShowing, onClose }: Props) {
  const [showingForgotPasswordModal, setShowingForgotPasswordModal] =
    useState(false)

  const handleShowForgotPasswordModal = () =>
    setShowingForgotPasswordModal(true)
  const handleCloseForgotPasswordModal = () =>
    setShowingForgotPasswordModal(false)

  return (
    <>
      <Modal size="xl" show={isModalShowing} onHide={onClose} centered>
        <LoginModalContent
          header="Welcome back!"
          onShowForgotPasswordClick={handleShowForgotPasswordModal}
          onClose={onClose}
        />
      </Modal>

      <ForgotPasswordModal
        isModalShowing={showingForgotPasswordModal}
        onClose={handleCloseForgotPasswordModal}
      />
    </>
  )
}

type LoginModalContentProps = {
  header: ReactNode
  onShowForgotPasswordClick?: () => void
  onClose?: () => void
}

export function LoginModalContent({
  header,
  onShowForgotPasswordClick,
  onClose
}: LoginModalContentProps) {
  const authService = useAtomValue(authServiceAtom)
  const refetchCurrentUser = useSetAtom(refetchCurrentUserAtom)

  const initialValues = {
    email: '',
    password: ''
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      await authService.login({
        authData: values,
        onSuccess: handleLoginSuccessful
      })
    },
    validate: (values) => {
      const error = {}

      if (values.email.length === 0) {
        error['email'] = 'Please provide an email'
      }

      if (values.password.length === 0) {
        error['password'] = 'Please provide a password'
      }

      return error
    }
  })

  async function handleLoginSuccessful() {
    await refetchCurrentUser()

    onClose?.()
  }

  return (
    <form className="LoginModal" onSubmit={formik.handleSubmit}>
      <div className="header">
        <h3>{header}</h3>
        {onClose && (
          <button
            type="button"
            className="closeLoginModal"
            data-dismiss="modal"
            onClick={onClose}
          >
            <img src={imgCloseWhite} alt="Close login modal" />
          </button>
        )}
      </div>

      <div className="loginForm">
        <input
          type="email"
          id="user_email_detail"
          name="email"
          placeholder="Email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />

        {formik.errors.email && (
          <span className="errorMessage">{formik.errors.email}</span>
        )}

        <input
          type="password"
          id="user_password_detail"
          name="password"
          placeholder="Enter Password"
          onChange={formik.handleChange}
          value={formik.values.password}
        />

        {formik.errors.password && (
          <span className="errorMessage">{formik.errors.password}</span>
        )}

        <button
          type="button"
          className="forgotPassword"
          data-toggle="modal"
          onClick={() => {
            onClose?.()
            onShowForgotPasswordClick?.()
          }}
        >
          Forgot your password?
        </button>

        <ul className="actions">
          <li>
            <FontAwesomeIcon
              icon={faFacebook}
              color="white"
              className="social-sign-in"
              onClick={async () => {
                await authService.socialLogin({
                  provider: 'facebook',
                  onSuccess: handleLoginSuccessful
                })
              }}
            />
          </li>

          <div className="separator">|</div>

          <li>
            <FontAwesomeIcon
              icon={faGoogle}
              color="white"
              className="social-sign-in"
              onClick={async () => {
                await authService.socialLogin({
                  onSuccess: handleLoginSuccessful
                })
              }}
            />
          </li>

          <div className="separator">|</div>

          <li>
            <Link to="/register" className="link2">
              Sign up!
            </Link>
          </li>
        </ul>

        <input
          className="submitBtn"
          id="loginDetail"
          type="submit"
          name="loginDetail"
          value="Submit!"
        />
      </div>
    </form>
  )
}
