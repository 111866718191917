import { useAtomValue } from 'jotai'
import { subscriptionBannerAtom } from '../core/atoms'
import { LoadingView, RichText } from 'components'
import { BasicPageModule } from 'data'
import { SubscriptionUserSignedIn } from '../components/subscription_user_signed_in'
import { Suspense, useEffect, useState } from 'react'
import { SubscriptionCreateAccount } from '../components/subscription_create_account'
import { Link } from 'react-router-dom'
import { LoginModal, currentUserAtom } from 'pages/auth'
import { registerGoogleAnalyticsCustomEvent } from 'utils/google_analytics'
import { imgCloseBlack, imgLogo } from 'core/images'

export function SubscriptionPage() {
  const subscriptionBanner = useAtomValue(subscriptionBannerAtom)

  const currentUser = useAtomValue(currentUserAtom)
  const [showingLoginModal, setShowingLoginModal] = useState(false)

  const handleShowLoginModal = () => setShowingLoginModal(true)
  const handleCloseLoginModal = () => setShowingLoginModal(false)

  useEffect(() => {
    registerGoogleAnalyticsCustomEvent('subscription', 'Subscription page')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isUserSignedIn = currentUser !== undefined && currentUser !== null

  return (
    <div className="SubscriptionPage">
      <div className="container">
        <div className="header">
          <img
            src={imgLogo}
            className="img-fluid logo"
            alt="Application logo"
          />
          <Link className="close" to="/">
            <img src={imgCloseBlack} alt="Close button" />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-4">
            <SubscriptionPromotionalText
              isUserSignedIn={isUserSignedIn}
              subscriptionBanner={subscriptionBanner}
            />
          </div>
          <div className="col-md-8">
            <div className="subscription">
              <Suspense fallback={<LoadingView />}>
                {isUserSignedIn ? (
                  <SubscriptionUserSignedIn
                    name={currentUser.name}
                    email={currentUser.email}
                  />
                ) : (
                  <SubscriptionCreateAccount
                    handleShowLoginModalClick={handleShowLoginModal}
                  />
                )}
              </Suspense>
            </div>
          </div>
        </div>
      </div>

      <LoginModal
        isModalShowing={showingLoginModal}
        onClose={handleCloseLoginModal}
      />
    </div>
  )
}

type Props = {
  isUserSignedIn: boolean
  subscriptionBanner?: BasicPageModule | null
}

function SubscriptionPromotionalText({
  isUserSignedIn,
  subscriptionBanner
}: Props) {
  if (!subscriptionBanner) return <></>

  if (isUserSignedIn) {
    return (
      <RichText
        content={subscriptionBanner?.moduleText2?.json}
        textColor={subscriptionBanner?.contentTextColor?.value}
      />
    )
  }

  return (
    <RichText
      content={subscriptionBanner?.moduleText1?.json}
      textColor={subscriptionBanner?.contentTextColor?.value}
    />
  )
}
