import { useMemo } from 'react'
import { MenuSideBar } from '../components/menu_side_bar'
import { WelcomeKit } from '../components/welcome_kit/welcome_kit'
import { ResourceRoomTabKey } from '../core/types'
import { ChildStyle } from '../components/child_style/child_style'
import { YourPostcards } from '../components/your_postcards/your_postcards'
import { UserDashboard } from '../components/user_dashboard/user_dashboard'
import { useAtomValue } from 'jotai'
import { currentUserAtom } from 'pages/auth'

type Props = {
  tab: ResourceRoomTabKey
}

export function ResourceRoomPage({ tab }: Props) {
  const currentUser = useAtomValue(currentUserAtom)

  const body = useMemo(() => {
    const userFirstName = currentUser ? currentUser.name.split(' ')[0] : ''

    switch (tab) {
      case 'welcome_kit':
        return <WelcomeKit userFirstName={userFirstName} />

      case 'child_style':
        return <ChildStyle userFirstName={userFirstName} />

      case 'your_postcards':
        return <YourPostcards userFirstName={userFirstName} />

      case 'account_info':
        return <UserDashboard />
    }
  }, [currentUser, tab])

  return (
    <section className="ResourceRoomPage">
      <div className="container">
        <div className="row">
          <aside className="col-lg-3 col-md-4 mb-md-0 mb-5">
            <MenuSideBar currentTab={tab} />
          </aside>
          <aside className="col-lg-9 col-md-8">{body}</aside>
        </div>
      </div>
    </section>
  )
}
