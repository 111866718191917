import { contentfulApiAtom } from 'data'
import { atom } from 'jotai'
import { atomsWithQuery } from 'jotai-tanstack-query'

export const toolIdAtom = atom<string | undefined>(undefined)

function toolDetailsKey(toolId?: string) {
  return ['toolDetailsAtom', toolId]
}

export const [toolDetailsAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const toolId = get(toolIdAtom)

  return {
    queryKey: toolDetailsKey(toolId),
    queryFn: async () => {
      if (!toolId) return

      const toolDetails = await api.getToolDetails({
        id: toolId
      })

      return toolDetails.tool
    }
  }
})

function toolkitKey(toolkitId?: string) {
  return ['toolkitAtom', toolkitId]
}

export const [toolkitAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const toolkitId = process.env.REACT_APP_CONTENTFUL_TOOLKIT_PAGE!

  return {
    queryKey: toolkitKey(toolkitId),
    queryFn: async () => {
      const toolkitPageContent = await api.getToolkitPageContent({
        id: toolkitId
      })

      return toolkitPageContent.newPage
    }
  }
})

type ToolCategoryFilterData = {
  categoryId?: string
  limit: number
  skip: number
}

export const toolCategoryFilterDataAtom = atom<
  ToolCategoryFilterData | undefined
>(undefined)

function toolsByCategoryKey(toolCategoryFilterData?: ToolCategoryFilterData) {
  return [
    'toolsByCategoryAtom',
    toolCategoryFilterData?.categoryId,
    toolCategoryFilterData?.limit,
    toolCategoryFilterData?.skip
  ]
}

export const [toolsByCategoryAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const toolCategoryFilterData = get(toolCategoryFilterDataAtom)

  return {
    queryKey: toolsByCategoryKey(toolCategoryFilterData),
    queryFn: async () => {
      if (!toolCategoryFilterData || !toolCategoryFilterData?.categoryId)
        return undefined

      const response = await api.getToolsByCategory({
        id: toolCategoryFilterData.categoryId,
        limit: toolCategoryFilterData.limit,
        skip: toolCategoryFilterData.skip
      })

      return response.toolCollection
    }
  }
})
