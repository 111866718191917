import { Suspense, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { OverrideAtom } from 'utils/jotai'
import { postcardsSearchAtom, postcardsSearchDataAtom } from './atoms'
import { useAtom, useAtomValue } from 'jotai'
import { LoadingView } from 'components/loading_view'
import { Pagination, PostcardPreview } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useDebouncedCallback } from 'use-debounce'
import { imgCloseRed } from 'core/images'

type Props = {
  searchInput: string
  isModalShowing: boolean
  onClose?: () => void
}

// Total number of postcards we want to show per page
const postcardsPerPage = 20

/**
 * Renders a modal that let's users search for postcards containing a certain
 * input.
 *
 * *WARNING*: for this component to work properly, do not forget to integrate it in
 * the component tree and update [isModalShowing] according to the desired visibility.
 *
 * @param isModalShowing controls whether the modal should be visible or not.
 * @param searchInput initial query value user is searching for.
 * @param onClose callback function triggered whenever the modal is closed.
 */
export function SearchModal({ isModalShowing, searchInput, onClose }: Props) {
  return (
    <Modal size="xl" show={isModalShowing} onHide={onClose} centered scrollable>
      <SearchModalContent searchInput={searchInput} onClose={onClose} />
    </Modal>
  )
}

type SearchModalContentProps = {
  searchInput: string
  onClose?: () => void
}

function SearchModalContent({ searchInput, onClose }: SearchModalContentProps) {
  return (
    <OverrideAtom
      atom={postcardsSearchDataAtom}
      value={{ searchQuery: searchInput, limit: postcardsPerPage, skip: 0 }}
    >
      <Suspense fallback={<LoadingView />}>
        <PostcardsSearchListContent onClose={onClose} />
      </Suspense>
    </OverrideAtom>
  )
}

function PostcardsSearchListContent({ onClose }: { onClose?: () => void }) {
  const [postcardsSearchData, setPostcardsSearchDataAtom] = useAtom(
    postcardsSearchDataAtom
  )

  const postcards = useAtomValue(postcardsSearchAtom)

  const [currentPage, setCurrentPage] = useState(1)

  // Because we don't want to trigger a search everytime the input changes, we
  // use a debounced callback which basically starts a timer and only starts the
  // search when time runs out.
  const debounceChange = useDebouncedCallback((event) => {
    event.stopPropagation()

    const input = event.target.value

    setPostcardsSearchDataAtom({
      searchQuery: input,
      limit: postcardsPerPage,
      // For every new search, we want to go back to the very beginning of the list.
      skip: 0
    })
  }, 500)

  // Whenever [currentPage] changes, this hook is triggered which in turn runs a new
  // search.
  useEffect(() => {
    setPostcardsSearchDataAtom({
      ...postcardsSearchData!,
      // currentPage starts at 1, that's why we're removing 1 from it to calculate skip.
      skip: (currentPage - 1) * postcardsPerPage
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const numberOfPages = Math.ceil((postcards?.total ?? 0) / postcardsPerPage)

  let total = postcards?.total ?? 0

  return (
    <>
      <Modal.Header className="PostcardsSearchListContent header">
        <div className="row">
          <div className="col-lg-5">
            <h2>Total {total} results for...</h2>
          </div>

          <div className="col-lg-7 searchInput">
            <input
              type="text"
              name="inputSearchPostcards"
              placeholder="Search Postcards"
              onChange={(event) => debounceChange(event)}
              defaultValue={postcardsSearchData?.searchQuery}
            />

            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div>

        <div className="closeContainer">
          <button
            type="button"
            className="closeSearchModal"
            data-dismiss="modal"
            onClick={onClose}
          >
            <img src={imgCloseRed} alt="Close search modal" />
          </button>
        </div>
      </Modal.Header>

      <Modal.Body className="PostcardsSearchListContent body">
        <section className="row row-eq-height">
          {total === 0 ? (
            <div className="row noResults">
              <h4>No results found</h4>
            </div>
          ) : (
            postcards?.items.map((data) => {
              if (!data) return <></>

              return (
                <div key={data.sys.id} className="col-md-4 col-sm-6">
                  {
                    <PostcardPreview
                      postcard={data}
                      showReadMore={false}
                      delayPriorToNavigating={200}
                      onClick={onClose}
                    />
                  }
                </div>
              )
            })
          )}
        </section>
      </Modal.Body>

      {total > 0 && (
        <Modal.Footer className="PostcardsSearchListContent footer">
          <Pagination
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Modal.Footer>
      )}
    </>
  )
}
