import { CustomText, RichText } from 'components'
import { BasicPageModule } from 'data'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'

import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

type Props = {
  moduleData: BasicPageModule
}

export function PageModuleWelcome({ moduleData }: Props) {
  return (
    <div className="row align-items-center">
      <div className="col-md-6">
        <LiteYouTubeEmbed
          id={moduleData.moduleImagesCollection?.items[0]?.title ?? ''}
          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
        />
      </div>
      <div className="col-md-6">
        <CustomText
          textStyle="headerLarge"
          textColor={moduleData.titleTextColor?.value}
        >
          {moduleData.title}
        </CustomText>
        <RichText
          textClassName="CustomText headerNormal"
          title={moduleData.title}
          content={moduleData.moduleText1?.json}
          textColor={moduleData.contentTextColor?.value}
          fontFamily="Libre Baskerville"
        />
      </div>
    </div>
  )
}
