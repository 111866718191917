import { LoadingView, RichText } from 'components'
import { useAtomValue } from 'jotai'
import { welcomeTabContentAtom } from 'pages/resource_room/core/atoms'
import { Suspense } from 'react'

export function WelcomeTab() {
  return (
    <Suspense fallback={<LoadingView />}>
      <WelcomeTabContent />
    </Suspense>
  )
}

function WelcomeTabContent() {
  const content = useAtomValue(welcomeTabContentAtom)

  return (
    <div>
      <RichText content={content?.pageText?.json} />
    </div>
  )
}
