import { useAtomValue } from 'jotai'
import { subscriptionThankYouAtom } from '../core/atoms'
import { AssetRenderer, RichText } from 'components'

export function SubscriptionThankYouPage() {
  const content = useAtomValue(subscriptionThankYouAtom)

  return (
    <div className="SubscriptionThankYouPage">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <RichText
              content={content?.description?.json}
              textColor={content?.contentTextColor?.value}
            />
          </div>
          <div className="col-md-6">
            <AssetRenderer asset={content?.image} />
          </div>
        </div>
      </div>
    </div>
  )
}
