import {
  faFacebook,
  faGoogle,
  faLinkedin,
  faTwitter,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

type Props = {
  title?: string | null
  titleColor?: string | null
  categories?:
    | {
        id?: string | null
        label?: string | null
        url?: string | null
      }[]
    | null
  shareURL?: string
}

/**
 * Renders a page header that contains a title, categories and all
 * sharing options available.
 *
 * @param title title to be rendered on the top of the component.
 * @param titleColor color of the title.
 * @param categories list of categories to be rendered below
 * the title.
 * @param shareURL default URL to be used when sharing the content
 * associated with this header.
 */
export function StoryPageHeader({
  title,
  titleColor,
  categories,
  shareURL
}: Props) {
  // If there is no [shareURL] we don't want to render anything.
  if (!shareURL) return <></>

  return (
    <section className="StoryPageHeader">
      <h1 style={{ color: titleColor ?? '' }}>{title}</h1>

      <div className="d-flex justify-content-between container">
        <Row>
          {categories?.map((category) => {
            if (!category.url) return <></>

            return (
              <Col key={category.id} className="category">
                <Link to={category.url}>{category.label}</Link>
              </Col>
            )
          })}
        </Row>

        <Row>
          <Col className="share">
            <span>Share on</span>
          </Col>
          <Col>
            <Link
              to={`https://www.facebook.com/sharer.php?u=${shareURL}`}
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
          </Col>
          <Col>
            <Link
              to={`https://twitter.com/share?url=${shareURL}`}
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
          </Col>
          <Col>
            <Link
              to={`https://www.linkedin.com/sharing/share-offsite?url=${shareURL}`}
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </Link>
          </Col>
          <Col>
            <Link
              to={`https://web.whatsapp.com/send?text=${shareURL}`}
              rel="nofollow"
              target="_blank"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </Link>
          </Col>
          <Col>
            <Link
              to={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${title}&body=${shareURL}&ui=2&tf=1&pli=1`}
              target="_blank"
            >
              <FontAwesomeIcon icon={faGoogle} />
            </Link>
          </Col>
        </Row>
      </div>
    </section>
  )
}
