import { GaugeChart } from './gauge_chart'
import { BarChart } from './bar_chart'
import { ParentQuizAdditionalResults } from 'pages/quiz/core/types'

const chartColor = '#1C9970'

const gaugeChartMinRange = -6
const gaugeChartMaxRange = 6

const barChartLabels = ['Limits', 'Routine', 'Expectations', 'Autonomy']

type Props = {
  data: number
  additionalResults?: ParentQuizAdditionalResults
}

export function EmpowermentGraph({ data, additionalResults }: Props) {
  return (
    <div className="EmpowermentGraph row">
      <h2 className="title">Empowerment</h2>
      <h2 className="subtitle">
        The balance of challenge with appropriate support
      </h2>
      <div className="col-lg-6 col-md-6">
        <GaugeChart
          data={buildGaugeChartData(data)}
          chartColor={chartColor}
          targetZone={[30, 40, 30]}
        />
      </div>
      <div className="col-lg-6 col-md-6 my-auto">
        {additionalResults && (
          <BarChart
            data={buildBarChartData(additionalResults)}
            labels={barChartLabels}
          />
        )}
      </div>
    </div>
  )
}

function buildGaugeChartData(data: number) {
  const filledPortion =
    ((data - gaugeChartMinRange) * 100) /
    (gaugeChartMaxRange - gaugeChartMinRange)
  const notFilledPortion = 100 - filledPortion

  return {
    datasets: [
      {
        data: [filledPortion, notFilledPortion],
        backgroundColor: [chartColor, 'transparent'],
        borderColor: 'transparent'
      }
    ]
  }
}

function buildBarChartData(additionalResults: ParentQuizAdditionalResults) {
  return {
    labels: barChartLabels,
    datasets: [
      {
        data: [
          additionalResults.limits,
          additionalResults.routine,
          additionalResults.expectations,
          additionalResults.autonomy
        ],
        borderColor: 'transparent',
        backgroundColor: chartColor,
        barThickness: 16
      }
    ]
  }
}
