import { ConversationalForm, RichText } from 'components'
import { createRef, useEffect, useRef, useState } from 'react'
import { QuizItem } from '../components/quiz_item'
import { BasicQuizQuestion } from 'data/contentful/api.graphql'
import { customColors } from 'styles/colors'
import { KidQuiz } from '../core/types'
import { useAtomValue } from 'jotai'
import { kidQuizAtom } from '../core/atoms'
import { User, currentUserAtom } from 'pages/auth'
import { KidQuizUserSignedOut } from '../components/kid_quiz_user_signed_out'
import { KidQuizGetResults } from '../components/kid_quiz_get_results'
import { KidQuizGradeSelection } from '../components/kid_quiz_grade_selection'
import { KidQuizNoActiveSubscription } from '../components/kid_quiz_no_active_subscription'
import { useSearchParams } from 'react-router-dom'

export function KidQuizPage() {
  const currentUser = useAtomValue(currentUserAtom)

  const kidQuizData = useAtomValue(kidQuizAtom)

  if (!kidQuizData) {
    return <></>
  }

  if (!currentUser) {
    return <KidQuizUserSignedOut />
  }

  if (!currentUser.has_active_subscription) {
    return <KidQuizNoActiveSubscription />
  }

  return (
    <KidQuizPageContent currentUser={currentUser} kidQuizData={kidQuizData} />
  )
}

type KidQuizPageContentProps = {
  currentUser: User
  kidQuizData: Array<BasicQuizQuestion | null>
}

function KidQuizPageContent({
  currentUser,
  kidQuizData
}: KidQuizPageContentProps) {
  const [searchParams] = useSearchParams()

  const [quizAnswers, setQuizAnswers] = useState<KidQuiz>({
    sliderQuestions: []
  })
  const [currentPosition, setCurrentPosition] = useState(0)

  const elementsRef = useRef(
    kidQuizData?.map(() => createRef<HTMLDivElement>())
  )

  useEffect(() => {
    const grade = searchParams.get('grade')

    if (grade && !quizAnswers.grade) {
      setQuizAnswers({ ...quizAnswers, grade: parseInt(grade) })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  function updateQuestionValue({
    index,
    variableName,
    value
  }: {
    index: number
    variableName: string
    value: number
  }) {
    const answers = quizAnswers.sliderQuestions

    answers[index] = {
      variableName: variableName,
      value: value
    }

    const newAnswers = {
      ...quizAnswers,
      sliderQuestions: answers
    }

    setQuizAnswers(newAnswers)

    return newAnswers
  }

  return (
    <section className="ParentQuizPage">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-5 description">
            <RichText
              content={kidQuizData[currentPosition]?.leftColumnCopy?.json}
              textColor={customColors.green}
            />
          </div>
          <div className="col-md-7 my-auto">
            <ConversationalForm
              elementsRef={elementsRef}
              currentPosition={currentPosition}
              height={450}
              showActionButtons={currentPosition !== 0}
              isScrollEnabled={currentPosition !== 0}
              onCurrentPositionChanged={(currentPosition) => {
                setCurrentPosition(currentPosition)
              }}
            >
              {kidQuizData?.map((quizQuestion, index) => {
                if (!quizQuestion) return null

                if (index === 0) {
                  return (
                    <KidQuizGradeSelection
                      key={quizQuestion.sys.id}
                      currentUser={currentUser}
                      quizAnswers={quizAnswers}
                      setQuizAnswers={setQuizAnswers}
                      onGoToNextSection={(grade) => {
                        setQuizAnswers({ ...quizAnswers, grade: grade })
                        setCurrentPosition(currentPosition + 1)
                      }}
                    />
                  )
                }

                if (index === kidQuizData.length - 1)
                  return (
                    <KidQuizGetResults
                      key={quizQuestion.sys.id}
                      quizQuestion={quizQuestion}
                      quizAnswers={quizAnswers}
                    />
                  )

                const answers = quizAnswers.sliderQuestions
                if (answers.length <= index) {
                  answers[index] = {
                    variableName: quizQuestion.variableName!,
                    value: 4
                  }
                  setQuizAnswers({ ...quizAnswers, sliderQuestions: answers })
                }

                return (
                  <QuizItem
                    key={quizQuestion.sys.id}
                    reference={elementsRef?.current[index]}
                    quizQuestion={quizQuestion}
                    quizAnswer={answers[index]}
                    onAnswerChanged={(value) => {
                      updateQuestionValue({
                        index: index,
                        variableName: quizQuestion.variableName!,
                        value: value
                      })
                    }}
                    onAnswerMouseUp={(value) => {
                      updateQuestionValue({
                        index: index,
                        variableName: quizQuestion.variableName!,
                        value: value
                      })

                      setCurrentPosition(currentPosition + 1)
                    }}
                  />
                )
              })}
            </ConversationalForm>
          </div>
        </div>
      </div>
    </section>
  )
}
