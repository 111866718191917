import { useElements, PaymentElement } from '@stripe/react-stripe-js'
import { CustomButton } from 'components'
import { useAtomValue } from 'jotai'
import { stripeAtom } from 'pages/subscription/core/atoms'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CouponElement } from './coupon_element'
import { Coupon, Subscription } from '../../core/types'
import { p4pApiAtom } from 'data'
import { useState } from 'react'

type Props = {
  name: string
  email: string
  subscription: Subscription
  coupon?: Coupon
  setCoupon: React.Dispatch<React.SetStateAction<Coupon | undefined>>
}

export function CheckoutFormContent({
  name,
  email,
  subscription,
  coupon,
  setCoupon
}: Props) {
  const api = useAtomValue(p4pApiAtom)

  const stripePromise = useAtomValue(stripeAtom)
  const elements = useElements()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  async function handleConfirmPayment() {
    setIsLoading(true)

    if (!elements) {
      setIsLoading(false)
      return
    }

    const responseFormValidation = await elements.submit()

    if (responseFormValidation.error) {
      setIsLoading(false)
      return
    }

    if (coupon && !coupon.valid) {
      toast.error('Selected coupon is not valid')
      setIsLoading(false)
      return
    }

    const response = await api.getClientSecret({
      email,
      name,
      product_id: subscription.product_id,
      price_id: subscription.price_id,
      coupon: coupon?.coupon
    })

    if (response.status === 200) {
      const { type, client_secret } = response.data

      if (type === 'coupon') {
        toast.success('Subscription paid successfully')
        navigate('/thank-you')
        return
      }

      const confirm =
        type === 'payment'
          ? stripePromise.confirmPayment
          : stripePromise.confirmSetup

      const responseConfirmIntent = await confirm({
        elements,
        clientSecret: client_secret,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.origin}/thank-you`
        }
      })

      if (!responseConfirmIntent.error) {
        if (
          responseConfirmIntent['paymentIntent']?.status === 'succeeded' ||
          responseConfirmIntent['setupIntent']?.status === 'succeeded'
        ) {
          toast.success('Subscription paid successfully')
          navigate('/thank-you')
        }
      } else {
        toast.error(
          `Error while confirming payment: ${responseConfirmIntent.error.message}`
        )
      }
    } else {
      toast.error(`Error while confirming payment: ${response.data.error}`)
    }

    setIsLoading(false)
  }

  return (
    <div className="CheckoutFormContent">
      <CouponElement
        product_id={subscription.product_id}
        coupon={coupon}
        isLoading={isLoading}
        setCoupon={setCoupon}
        setIsLoading={setIsLoading}
      />

      {!(
        coupon?.valid &&
        coupon?.applies_to_product &&
        !coupon?.requires_payment_info
      ) && (
        <>
          <PaymentElement />

          <p className="consent">
            By continuing, you agree to Postcards for Parents'{' '}
            <Link style={{ color: 'blue' }} to={'/terms-of-use'}>
              Terms & Conditions
            </Link>{' '}
            and{' '}
            <Link style={{ color: 'blue' }} to={'/privacy-policy'}>
              Privacy Policy
            </Link>
          </p>
        </>
      )}

      <CustomButton enabled={!isLoading} onClick={handleConfirmPayment}>
        Confirm & pay subscription
      </CustomButton>
    </div>
  )
}
