import { AssetRenderer, RichText, StoryPageHeader } from 'components'
import { BasicToolDetails } from 'data/contentful/api.graphql'
import { customColors } from 'styles/colors'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper/modules'
import { Accordion } from 'react-bootstrap'
import { appUrl } from 'core/consts'

type Props = {
  tool: BasicToolDetails
}

export function ToolkitDetailsContent({ tool }: Props) {
  const toolkitURL = `${appUrl}toolkitDetails/${tool.sys.id}`

  return (
    <div className="ToolkitDetailsContent">
      <StoryPageHeader
        title={tool.name}
        titleColor={customColors.green}
        categories={tool.toolCategoryCollection?.items.map((category) => ({
          id: category?.sys.id,
          label: category?.toolCategoryName,
          url: `/catlist/${category?.sys.id}`
        }))}
        shareURL={toolkitURL}
      />

      <RichText textClassName="toolText" content={tool.toolText?.json} />

      <Swiper
        slidesPerView={1}
        spaceBetween={32}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
          waitForTransition: true
        }}
        speed={1000}
        navigation={true}
        modules={[Autoplay, Navigation]}
      >
        {tool.toolImageCollection?.items.map((item) => (
          <SwiperSlide key={item?.sys.id}>
            <div className="slider-image">
              <AssetRenderer asset={item} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {tool.howToDoIt && <h3 className="sectionHeader">HOW TO DO IT</h3>}

      <RichText content={tool.howToDoIt?.json} />

      <Accordion>
        {tool.toolInstructionsCollection?.items.map((instruction, index) => {
          if (!instruction) return <></>

          return (
            <Accordion.Item
              key={instruction.sys.id}
              eventKey={instruction.sys.id}
            >
              <Accordion.Header>
                {index + 1}. {instruction.toolInstructionName}
              </Accordion.Header>
              <Accordion.Body>
                <RichText content={instruction.toolInstructionText?.json} />
              </Accordion.Body>
            </Accordion.Item>
          )
        })}
      </Accordion>

      <RichText content={tool.toolConclusion?.json} />

      <AssetRenderer asset={tool.toolIcon} />
    </div>
  )
}
