import { BasicAsset } from 'data/contentful/api.graphql'

type Props = {
  asset?: BasicAsset | null
}

/**
 * Component that renders a Conteful's asset.
 *
 * If no asset is provided, nothing is rendered.
 *
 * @param asset Contentful asset.
 */
export function AssetRenderer({ asset }: Props) {
  if (!asset) return <></>

  if (asset.contentType === 'video/mp4') {
    return (
      <video className="img-fluid" controls>
        <source src={asset.url!} type="video/mp4" />
      </video>
    )
  }

  return <img src={asset.url!} className="img-fluid" alt="" />
}
