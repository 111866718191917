import { GraphQLClient } from 'graphql-request'
import * as API from './api.graphql'

/**
 * Returns an instance of the Contentful API client.
 */
export function createContentfulApi() {
  const client = new GraphQLClient(
    process.env.REACT_APP_CONTENTFUL_GRAPHQL_ENDPOINT!
  )

  return API.getSdk(client)
}

export type ContentfulAPI = ReturnType<typeof createContentfulApi>
