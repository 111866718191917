import { useAtomValue } from 'jotai'
import { homePageDataAtom } from '../core/atoms'
import { PageModule } from '../components/page_module'
import {
  CustomButton,
  DraggableNotice,
  LoadingView,
  PageBanner
} from 'components'
import { RecentPostcards } from '../components/recent_postcards'
import { Suspense } from 'react'

export function HomePage() {
  return (
    <Suspense fallback={<LoadingView />}>
      <HomePageContent />
    </Suspense>
  )
}

function HomePageContent() {
  const homePageData = useAtomValue(homePageDataAtom)

  return (
    <div className="HomePage">
      <PageBanner
        pageBanner={homePageData?.pageBanner}
        additionalComponent={
          <CustomButton onClick={() => window.location.replace(`/#learnMore`)}>
            Sign up!
          </CustomButton>
        }
      />

      {homePageData?.pageModulesCollection?.items?.map((module) => {
        if (!module) return <></>

        return <PageModule key={module.sys.id} moduleData={module} />
      })}

      <RecentPostcards />

      <DraggableNotice />
    </div>
  )
}
