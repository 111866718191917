import { useLocation } from 'react-router-dom'

/**
 * Hook that listens for location changes and returns the current hash, if any.
 *
 * This is mostly intended when parsing URLs coming from the backend. For this
 * to successfully remove unwanted values after the hash, it needs to have an
 * ending string of '%23/'.
 *
 * @returns the hash value from the current URL. If there is no hash, an empty
 * string is returned.
 */
export function useLocationHash() {
  const { hash } = useLocation()
  return parseHash(hash)
}

function parseHash(hash: string) {
  let parsedHash = hash

  if (parsedHash.includes('%23/')) {
    const split = hash.split('%23/')
    parsedHash = split[0]
  }

  if (parsedHash.startsWith('#')) {
    return parsedHash.slice(1)
  }

  return parsedHash
}
