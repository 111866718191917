import { contentfulApiAtom } from 'data'
import { atomsWithQuery } from 'jotai-tanstack-query'

function contentCategoryCollectionKey() {
  return ['getContentCategoryCollectionAtom']
}

// Queries all postcard categories we want users to have access to
export const [contentCategoryCollectionAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)

  return {
    queryKey: contentCategoryCollectionKey(),
    queryFn: async () => {
      const contentCategoryCollection = await api.getContentCategoryCollection()

      return contentCategoryCollection.contentCategoryCollection?.items
    }
  }
})
