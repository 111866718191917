import { contentfulApiAtom } from 'data'
import { atom } from 'jotai'
import { atomsWithQuery } from 'jotai-tanstack-query'

/**
 * @param searchQuery query containing the text user wants to search for.
 * @param limit max number of results to return. Used for pagination.
 * @param skip number of results to skip. Used for pagination.
 */
export type PostcardsSearchData = {
  searchQuery?: string
  limit: number
  skip: number
}

/**
 * Atom to hold the data for the search query. Used in {@link postcardsSearchAtom}.
 */
export const postcardsSearchDataAtom = atom<PostcardsSearchData | undefined>(
  undefined
)

function postcardsSearchKey(postcardsSearchData?: PostcardsSearchData) {
  return [
    'postcardsSearchAtom',
    postcardsSearchData?.searchQuery,
    postcardsSearchData?.limit,
    postcardsSearchData?.skip
  ]
}

/**
 * Searches postcards that contains search data's searchQuery in introText, title or tag.
 */
export const [postcardsSearchAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const postcardsSearchData = get(postcardsSearchDataAtom)

  return {
    queryKey: postcardsSearchKey(postcardsSearchData),
    queryFn: async () => {
      // If there is no search data, just return an empty result.
      if (!postcardsSearchData || !postcardsSearchData.searchQuery)
        return {
          total: 0,
          items: []
        }

      const response = await api.searchPostcards({
        searchQuery: postcardsSearchData.searchQuery,
        limit: postcardsSearchData.limit,
        skip: postcardsSearchData.skip
      })

      return response.postcardCollection
    }
  }
})
