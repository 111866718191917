import { RichText } from 'components'
import { BasicReflection } from 'data/contentful/api.graphql'

type Props = {
  reflection?: BasicReflection | null
}

export function PostcardDetailsReflection({ reflection }: Props) {
  if (!reflection) return <></>

  return (
    <div className="PostcardDetailsReflection">
      <h3 className="sectionHeader">REFLECTION</h3>
      <RichText content={reflection.json} />
    </div>
  )
}
