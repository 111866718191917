import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  BLOCKS,
  Block,
  Document,
  Inline,
  MARKS
} from '@contentful/rich-text-types'
import { ReactNode, useMemo } from 'react'

type Props = {
  textClassName?: string
  content?: Document | null
  title?: string | null
  textColor?: string | null
  fontFamily?: string
}

/**
 * Renders a markdown text coming from Contentful.
 *
 * This is a highly specialized component and should only be used when the text
 * you want to render comes from Contentful.
 *
 * If no [content] is passed, this component does not render anything.
 *
 * @param textClassName additional className to add to each individual paragraph
 * in the text.
 * @param content content to be rendered. See {@link Document} to learn more.
 * @param title some old content have the title built in the content itself, but
 * usually the title must have a very style. So passing the title in this parameter
 * ensures it is ignored when rendering the content.
 * @param textColor the color for the whole content.
 * @param fontFamily the font for the whole content.
 */
export function RichText({
  textClassName,
  content,
  title,
  textColor,
  fontFamily
}: Props) {
  const components = useMemo(() => {
    if (!content) return undefined

    const options = {
      renderMark: {
        [MARKS.BOLD]: (text: string) => (
          <Bold textColor={textColor}>{text}</Bold>
        )
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (_: Block | Inline, children: ReactNode) => (
          <Paragraph
            textClassName={textClassName}
            textColor={textColor}
            fontFamily={fontFamily}
          >
            {children}
          </Paragraph>
        )
      },
      renderText: (text) => {
        // If the current text to be rendered is equal to the [title], it gets ignored
        if (title?.trim().replace('\n', '') === text.trim().replace('\n', '')) {
          return undefined
        }

        return text
      }
    }

    return documentToReactComponents(content, options)
  }, [content, fontFamily, textClassName, textColor, title])

  if (!components) return <></>

  return <div className="RichText">{components}</div>
}

type ComponentProps = {
  textClassName?: string
  children: ReactNode
  textColor?: string | null
  fontFamily?: string
}

function Bold({ children, textColor }: ComponentProps) {
  return (
    <span className="bold" style={{ ...(textColor && { color: textColor }) }}>
      {children}
    </span>
  )
}

function Paragraph({
  textClassName,
  children,
  textColor,
  fontFamily
}: ComponentProps) {
  return (
    <p
      className={textClassName}
      style={{
        ...(textColor && { color: textColor }),
        ...(fontFamily && { fontFamily: fontFamily })
      }}
    >
      {children}
    </p>
  )
}
