import { useAtomValue } from 'jotai'
import { defaultErrorMessageAtom } from './atoms'
import { AppWrapper } from 'components/app_wrapper'
import { RichText } from 'components/rich_text'
import { Container } from 'react-bootstrap'

export function DefaultErrorMessage() {
  const defaultErrorMessage = useAtomValue(defaultErrorMessageAtom)

  return (
    <AppWrapper>
      <div className="DefaultErrorMessage">
        <Container>
          <RichText content={defaultErrorMessage?.moduleText1?.json} />
        </Container>
      </div>
    </AppWrapper>
  )
}
