import { AssetRenderer, RichText, StoryPageHeader } from 'components'
import { appUrl } from 'core/consts'
import { BasicPostcardDetails } from 'data/contentful/api.graphql'

type Props = {
  postcard: BasicPostcardDetails
}

export function PostcardDetailsContent({ postcard }: Props) {
  const postcardURL = `${appUrl}details/${postcard.sys.id}`

  return (
    <section className="PostcardDetailsContent">
      <div className="container">
        <StoryPageHeader
          title={postcard.title}
          titleColor={postcard.contentType?.contentColor?.value}
          categories={[
            {
              id: postcard.contentType?.sys.id,
              label: postcard?.contentType?.contentType,
              url: `/catlist/${postcard.contentType?.sys.id}`
            },
            {
              id: postcard.gradeLevel?.sys.id,
              label: postcard?.gradeLevel?.gradeTitle,
              url: `/gradelist/${postcard.gradeLevel?.sys.id}`
            }
          ]}
          shareURL={postcardURL}
        />

        <RichText
          textClassName="contextAndIssue"
          content={postcard.contextAndIssue?.json}
          textColor={postcard.contentType?.contentColor?.value}
        />

        <AssetRenderer asset={postcard.illustration} />

        <h3 className="sectionHeader">WHAT’S GOING ON?</h3>

        <RichText content={postcard.whatsGoingOnText?.json} />
      </div>
    </section>
  )
}
