import { useEffect, useMemo, useState } from 'react'
import { AboutTab } from './tabs/about_tab'
import { KidQuizResults } from 'pages/quiz'
import { cn } from 'utils/cn'
import { KitTab } from 'pages/resource_room/core/types'
import { getChildLabel } from 'core/grades'
import { ChildStyleItemTab } from './tabs/child_style_item_tab'
import { useAtomValue } from 'jotai'
import { currentUserAtom } from 'pages/auth'
import { CustomTooltip } from 'components'
import { PageModuleSale } from 'components/page_module_sale/page_module_sale'
import { useSearchParams } from 'react-router-dom'

type Props = {
  kidQuizResults: KidQuizResults[]
}

export function ChildStyleContent({ kidQuizResults }: Props) {
  const currentUser = useAtomValue(currentUserAtom)

  const [searchParams, setSearchParams] = useSearchParams()

  const [currentTab, setCurrentTab] = useState<string | undefined>()
  const [tabs, setTabs] = useState<string[]>([])

  const content = useMemo<Array<KitTab | undefined> | undefined>(() => {
    return ['about', ...kidQuizResults].map((item) => {
      if (typeof item === 'string')
        return {
          key: item,
          tab: <button>About</button>,
          body: <AboutTab />,
          enabled: true
        }

      const tab = getChildLabel(parseInt(item.grade))

      // If the current item is not yet in the list of tabs from our state,
      // we have to add it
      if (!tabs.includes(tab)) {
        setTabs([...tabs, tab])
      }

      let tabEnabled = currentUser?.has_active_subscription ?? false

      if (!tabEnabled) {
        return {
          key: item.grade,
          tab: (
            <CustomTooltip
              message="Subscribe below to learn more about your child!"
              placement="bottom"
              key={tab}
              children={
                <li className={cn('nav-item', 'disabled')}>
                  <button>{tab}</button>
                </li>
              }
            />
          ),
          body: <></>,
          enabled: false
        }
      }

      return {
        key: item.grade,
        tab: <button>{tab}</button>,
        body: <ChildStyleItemTab key={item.grade} results={item} />,
        enabled: true
      }
    })
  }, [currentUser?.has_active_subscription, kidQuizResults, tabs])

  useEffect(() => {
    const selectedChild = searchParams.get('child')

    const childIndex = kidQuizResults.findIndex(
      (value) => value.grade === selectedChild
    )

    if (childIndex >= 0) {
      setCurrentTab(selectedChild!)
    } else {
      setCurrentTab('about')
    }
  }, [currentTab, kidQuizResults, searchParams])

  return (
    <section className="ChildStyleContent">
      <div className="container">
        <ul className="nav nav-tabs">
          {content?.map((item) => (
            <li
              key={item?.key}
              className={cn(
                'nav-item',
                currentTab === item?.key ? 'active' : ''
              )}
              onClick={() => {
                if (item?.enabled) {
                  setCurrentTab(item!.key)

                  // If the selected tab is the About tab, we don't want the url
                  // to contain any search params.
                  if (item!.key === 'about') {
                    setSearchParams({})
                  } else {
                    setSearchParams({ child: item!.key })
                  }
                }
              }}
            >
              {item?.tab}
            </li>
          ))}
        </ul>

        <div className="tab-content">
          {content?.filter((item) => item?.key === currentTab)[0]?.body}
        </div>

        {!currentUser?.has_active_subscription && <PageModuleSale />}
      </div>
    </section>
  )
}
