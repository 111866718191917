import { useAtom, useAtomValue } from 'jotai'
import {
  emailWrapperPaginationDataAtom,
  getAllEmailWrappersAtom
} from '../core/atoms'
import { useEffect, useState } from 'react'
import { Pagination } from 'components'
import { EmailWrapperItem } from './email_wrapper_item'
import { Spinner } from 'react-bootstrap'

const recordsPerPage = 20

/**
 * This component renders the actual content of the
 * administrative page.
 */
export function AdminPageContent() {
  const emailWrappers = useAtomValue(getAllEmailWrappersAtom)

  const [, setPaginationData] = useAtom(emailWrapperPaginationDataAtom)

  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setPaginationData({
      limit: recordsPerPage,
      skip: (currentPage - 1) * recordsPerPage
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  let totalOfItems = emailWrappers?.emailWrapperCollection?.total ?? 0

  const numberOfPages = Math.ceil(totalOfItems / recordsPerPage)

  return (
    <div className="AdminPageContent container">
      <div
        className="loading"
        style={{ visibility: isLoading ? 'visible' : 'hidden' }}
      >
        <Spinner animation="border" />
        <h4>Processing request</h4>
      </div>

      <section className="content">
        {totalOfItems === 0 ? (
          <div className="noResults">
            <h4>No email wrappers found</h4>
          </div>
        ) : (
          emailWrappers?.emailWrapperCollection?.items.map((emailWrapper) => {
            if (!emailWrapper) return <></>

            return (
              <div key={emailWrapper.sys.id} className="item">
                <EmailWrapperItem
                  emailWrapper={emailWrapper}
                  setIsLoading={setIsLoading}
                />
              </div>
            )
          })
        )}
      </section>

      <section className="pagination">
        {totalOfItems > 0 && (
          <section>
            <Pagination
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </section>
        )}
      </section>
    </div>
  )
}
