import { parentQuizResultsAtom } from 'pages/resource_room/core/atoms'
import { Quotes } from '../quotes'
import { WelcomeKitContent } from './welcome_kit_content/welcome_kit_content'
import { useAtomValue } from 'jotai'
import { WelcomeKitQuizNotAnswered } from './welcome_kit_quiz_not_answered'

type Props = {
  userFirstName: string
}

export function WelcomeKit({ userFirstName }: Props) {
  const parentQuizResults = useAtomValue(parentQuizResultsAtom)

  return (
    <section className="WelcomeKit">
      <div className="container">
        <h2>{userFirstName}’s Room</h2>

        <Quotes />

        {parentQuizResults ? (
          <WelcomeKitContent results={parentQuizResults} />
        ) : (
          <WelcomeKitQuizNotAnswered />
        )}
      </div>
    </section>
  )
}
