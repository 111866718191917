import { Link } from 'react-router-dom'

export function UserSubscriptionNoActiveSubscription() {
  return (
    <div>
      <p>You do not have any currently active subscription</p>
      <Link to="/register">Subscribe now!</Link>
    </div>
  )
}
