import { CustomButton, RichText } from 'components'
import { useAtomValue } from 'jotai'
import { WelcomeKitContent } from 'pages/resource_room'
import { quizResultsContentAtom } from 'pages/resource_room/core/atoms'
import { ParentQuizResults } from '../core/types'
import { PageModuleSale } from 'components/page_module_sale/page_module_sale'
import { currentUserAtom } from 'pages/auth'

type Props = {
  quizResults: ParentQuizResults
}

export function ParentQuizResultsContent({ quizResults }: Props) {
  const currentUser = useAtomValue(currentUserAtom)

  const quizResultsContent = useAtomValue(quizResultsContentAtom)

  const showSubscriptionCTA =
    !currentUser || !currentUser.has_active_subscription

  return (
    <section className="ParentQuizResultsContent">
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <h2
              style={{ color: quizResultsContent?.titleTextColor?.value ?? '' }}
            >
              {quizResultsContent?.title}
            </h2>

            <p />

            <RichText
              content={quizResultsContent?.moduleText1?.json}
              title={quizResultsContent?.title}
              textColor={quizResultsContent?.textColor?.value}
            />

            {showSubscriptionCTA && (
              <CustomButton
                className="subscribe"
                onClick={() => window.location.replace(`/quiz#learnMore`)}
              >
                Subscribe
              </CustomButton>
            )}
          </div>

          <div className="col-xl-8">
            <WelcomeKitContent results={quizResults} isQuizResults={true} />
          </div>
        </div>

        {showSubscriptionCTA && <PageModuleSale />}
      </div>
    </section>
  )
}
