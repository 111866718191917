import { User } from 'pages/auth'
import { NavbarUserActionsSocials } from './navbar_user_actions_socials'
import { SearchModal } from 'components/search_modal/search_modal'
import { useState } from 'react'
import { NavbarAuthSection } from './navbar_auth_section'

type Props = {
  currentUser?: User | null
  onClickLogin?: (event) => void
  onClickLogout?: (event) => void
}

/**
 * Renders what we call the "condensed" version of the navigation bar actions view.
 *
 * The biggest difference for this version is how items are laid out and we render just a
 * button to open the search modal.
 *
 * @param currentUser currently signed in user.
 * @param onClickLogin callback function triggered whenever the user taps the log in button.
 * @param onClickLogout callback function triggered whenever the user taps the log out button.
 */
export function NavbarUserActionsCondensedView({
  currentUser,
  onClickLogin,
  onClickLogout
}: Props) {
  const [showingSearchModal, setShowingSearchModal] = useState(false)

  return (
    <div className="NavbarUserActions condensed col-md-4 col-sm-6 order-md-3">
      <ul className="actions">
        <div className="primaryContainer">
          <li>
            <button
              className="btnOpenSearchModal"
              data-toggle="modal"
              data-target="/searchModal"
              onClick={() => {
                setShowingSearchModal(true)
              }}
            >
              Search
            </button>
          </li>

          <div className="condensedSeparator">|</div>

          <NavbarAuthSection
            currentUser={currentUser}
            isCondensed={true}
            onClickLogin={onClickLogin}
            onClickLogout={onClickLogout}
          />
        </div>

        <NavbarUserActionsSocials />
      </ul>

      <SearchModal
        isModalShowing={showingSearchModal}
        searchInput=""
        onClose={() => {
          setShowingSearchModal(false)
        }}
      />
    </div>
  )
}
