import { useAtomValue } from 'jotai'
import { parentQuizAtom } from '../core/atoms'
import { ConversationalForm, RichText } from 'components'
import { createRef, useRef, useState } from 'react'
import { QuizItem } from '../components/quiz_item'
import { BasicQuizQuestion } from 'data/contentful/api.graphql'
import { customColors } from 'styles/colors'
import { ParentQuizGetResults } from '../components/parent_quiz_get_results'
import { ParentQuiz, ParentQuizResults } from '../core/types'
import { ParentQuizResultsContent } from '../components/parent_quiz_results_content'
import { parentQuizResultsAtom } from 'pages/resource_room/core/atoms'

export function ParentQuizPage() {
  const parentQuizData = useAtomValue(parentQuizAtom)

  if (!parentQuizData) {
    return <></>
  }

  return <ParentQuizPageContent parentQuizData={parentQuizData} />
}

type ParentQuizPageContentProps = {
  parentQuizData: Array<BasicQuizQuestion | null>
}

function ParentQuizPageContent({ parentQuizData }: ParentQuizPageContentProps) {
  const previousQuizResults = useAtomValue(parentQuizResultsAtom)

  const [quizAnswers, setQuizAnswers] = useState<ParentQuiz>({
    sliderQuestions: [],
    grades: []
  })
  const [currentPosition, setCurrentPosition] = useState(0)
  const [quizResults, setQuizResults] = useState<
    ParentQuizResults | undefined
  >()

  const elementsRef = useRef(
    parentQuizData?.map(() => createRef<HTMLDivElement>())
  )

  function updateQuestionValue({
    index,
    variableName,
    value
  }: {
    index: number
    variableName: string
    value: number
  }) {
    const newAnswers = quizAnswers.sliderQuestions

    newAnswers[index] = {
      variableName: variableName,
      value: value
    }

    setQuizAnswers({
      ...quizAnswers,
      sliderQuestions: newAnswers
    })
  }

  if (quizResults) {
    return <ParentQuizResultsContent quizResults={quizResults} />
  }

  const isGetResultsView = currentPosition === parentQuizData.length - 1

  const leftColumnCopy = parentQuizData[currentPosition]?.leftColumnCopy?.json

  return (
    <section className="ParentQuizPage">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-5 description">
            <RichText
              content={
                isGetResultsView
                  ? {
                      ...leftColumnCopy,
                      content: leftColumnCopy?.content?.slice(0, 2)
                    }
                  : leftColumnCopy
              }
              textColor={customColors.green}
            />
          </div>
          <div className="col-md-7 my-auto quiz">
            <ConversationalForm
              elementsRef={elementsRef}
              currentPosition={currentPosition}
              height={500}
              onCurrentPositionChanged={(currentPosition) => {
                setCurrentPosition(currentPosition)
              }}
            >
              {parentQuizData?.map((quizQuestion, index) => {
                if (!quizQuestion) return null

                if (index === parentQuizData.length - 1)
                  return (
                    <ParentQuizGetResults
                      key={quizQuestion.variableName}
                      reference={elementsRef?.current[index]}
                      quizQuestion={quizQuestion}
                      quizAnswers={quizAnswers}
                      previousQuizResults={previousQuizResults}
                      setQuizAnswers={setQuizAnswers}
                      setQuizResults={setQuizResults}
                    />
                  )

                const answers = quizAnswers.sliderQuestions
                if (answers.length <= index) {
                  answers.push({
                    variableName: quizQuestion.variableName!,
                    value: 4
                  })
                  setQuizAnswers({ ...quizAnswers, sliderQuestions: answers })
                }

                return (
                  <QuizItem
                    key={quizQuestion.variableName}
                    reference={elementsRef?.current[index]}
                    quizQuestion={quizQuestion}
                    quizAnswer={answers[index]}
                    onAnswerChanged={(value) => {
                      updateQuestionValue({
                        index: index,
                        variableName: quizQuestion.variableName!,
                        value: value
                      })
                    }}
                    onAnswerMouseUp={(value) => {
                      updateQuestionValue({
                        index: index,
                        variableName: quizQuestion.variableName!,
                        value: value
                      })

                      setCurrentPosition(currentPosition + 1)
                    }}
                  />
                )
              })}
            </ConversationalForm>
          </div>
        </div>
      </div>
    </section>
  )
}
