import {
  faFacebook,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { appUrl } from 'core/consts'

/**
 * Renders all social media links for the navigation bar, like linking to our
 * Instagram and Facebook profiles.
 */
export function NavbarUserActionsSocials() {
  return (
    <ul className="socials">
      <li>
        <a
          target="blank"
          href="https://www.facebook.com/Postcards-for-Parents-820084901661452"
          aria-label="Postcards for Parents Facebok page"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      </li>
      <li>
        <a
          target="blank"
          href="https://www.instagram.com/postcardsforparents"
          aria-label="Postcards for Parents Instagram profile"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </li>
      <li>
        <a
          target="blank"
          href="https://www.youtube.com/channel/UCm5ySrWmzLmKteNtzHynDXA"
          aria-label="Postcards for Parents Youtube channel"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </li>
      <li>
        <a
          href={`mailto:?subject=Check%20this%20out%20-%20Postcards%20for%20Parents&body=${appUrl}`}
          data-toggle="modal"
          data-target="#inTouch"
          aria-label="Send this page to a friend's email"
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </li>
    </ul>
  )
}
