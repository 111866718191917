import { AssetRenderer, DraggableNotice, PageBanner } from 'components'
import { RecentPostcards } from 'pages/home/components/recent_postcards'
import { useAtomValue } from 'jotai'
import {
  postcardsPageBannerAtom,
  postcardsPageContentAtom
} from '../core/atoms'
import { Link } from 'react-router-dom'
import { ageGroupIds } from '../core/data'

export function PostcardsPage() {
  const pageBannerContent = useAtomValue(postcardsPageBannerAtom)

  const content = useAtomValue(postcardsPageContentAtom)

  return (
    <div className="PostcardsPage">
      <PageBanner pageBanner={pageBannerContent} showImage={false} />

      <section className="container">
        <div className="row row-eq-height">
          {content.map((item, index) => (
            <div
              key={item.sys.id}
              className="col-md-6 col-sm-12 ageGroupItemContainer"
            >
              <Link
                className="ageGroupItem"
                to={`/gradelist/${ageGroupIds[index]}`}
              >
                <AssetRenderer asset={item.image} />
                <h3>{item.name}</h3>
              </Link>
            </div>
          ))}
        </div>
      </section>

      <RecentPostcards />

      <DraggableNotice />
    </div>
  )
}
