import { BasicIndexPostcard, BasicPageBanner, contentfulApiAtom } from 'data'
import { atom } from 'jotai'
import { atomsWithQuery } from 'jotai-tanstack-query'

export const postcardIdAtom = atom<string | undefined>(undefined)

function postcardDetailsKey(postcardId?: string) {
  return ['postcardDetailsAtom', postcardId]
}

export const [postcardDetailsAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const postcardId = get(postcardIdAtom)

  return {
    queryKey: postcardDetailsKey(postcardId),
    queryFn: async () => {
      if (!postcardId) return

      const response = await api.getPostcardDetails({ id: postcardId })

      return response.postcard
    }
  }
})

function postcardLoginBoxKey(postcardLoginBoxId?: string) {
  return ['postcardLoginBoxAtom', postcardLoginBoxId]
}

export const [postcardLoginBoxAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const postcardLoginBoxId =
    process.env.REACT_APP_CONTENTFUL_POSTCARD_LOGIN_BOX!

  return {
    queryKey: postcardLoginBoxKey(postcardLoginBoxId),
    queryFn: async () => {
      const response = await api.getPostcardLoginBox({ id: postcardLoginBoxId })

      return response.postcardPage
    }
  }
})

function postcardsPageBannerKey(postcardsPageBannerId?: string) {
  return ['postcardsPageBannerAtom', postcardsPageBannerId]
}

export const [postcardsPageBannerAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const postcardsPageBannerId =
    process.env.REACT_APP_CONTENTFUL_POSTCARDS_PAGE_BANNER!

  return {
    queryKey: postcardsPageBannerKey(postcardsPageBannerId),
    queryFn: async () => {
      const response = await api.getPostcardsPageBanner({
        id: postcardsPageBannerId
      })

      return response.postcardPage
    }
  }
})

export const postcardsPageBannerIdAtom = atom<string | undefined>(undefined)

function postcardsPageBannerByIdKey(postcardsPageBannerId?: string) {
  return ['postcardsPageBannerByIdAtom', postcardsPageBannerId]
}

export const [postcardsPageBannerByIdAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const postcardsPageBannerId = get(postcardsPageBannerIdAtom)

  return {
    queryKey: postcardsPageBannerByIdKey(postcardsPageBannerId),
    queryFn: async () => {
      if (!postcardsPageBannerId) return null

      const response = await api.getPostcardsPageBannerById({
        id: postcardsPageBannerId
      })

      const items = response.postcardPageCollection?.items

      if (items && items.length > 0) {
        return items[0]
      }

      return null
    }
  }
})

function postcardsPageContentKey(postcardsPageContentIds?: string[]) {
  return ['postcardsPageContentAtom', postcardsPageContentIds]
}

export const [postcardsPageContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const postcardsPageContentIds = [
    process.env.REACT_APP_CONTENTFUL_PAGE_BANNER_PRE_K!,
    process.env.REACT_APP_CONTENTFUL_PAGE_BANNER_ELEMENTARY!,
    process.env.REACT_APP_CONTENTFUL_PAGE_BANNER_ADOLESCENT!,
    process.env.REACT_APP_CONTENTFUL_PAGE_BANNER_PARENT_SELF_CARE!
  ]

  return {
    queryKey: postcardsPageContentKey(postcardsPageContentIds),
    queryFn: async () => {
      const content: BasicPageBanner[] = []

      for (let i = 0; i < postcardsPageContentIds.length; i++) {
        const response = await api.getPostcardsPageBanner({
          id: postcardsPageContentIds[i]
        })

        if (response.postcardPage) {
          content.push(response.postcardPage)
        }
      }

      return content
    }
  }
})

function allIndexPostcardsKey() {
  return ['allIndexPostcardsAtom']
}

export const [allIndexPostcardsAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)

  return {
    queryKey: allIndexPostcardsKey(),
    queryFn: async () => {
      const resultsPerPage = 50
      let skip = 0

      let postcards: Array<BasicIndexPostcard | null> = []

      let finished = false

      do {
        const response = await api.getAllIndexPostcards({
          limit: resultsPerPage,
          skip: skip
        })

        if (!response || response.postcardCollection?.items === undefined) {
          finished = true
        }

        postcards = postcards.concat(response.postcardCollection!.items)

        skip += resultsPerPage

        finished = postcards.length === response.postcardCollection?.total
      } while (!finished)

      return postcards
    }
  }
})

type GradeFilterData = {
  id?: string
  limit: number
  skip: number
}

export const gradeFilterDataAtom = atom<GradeFilterData | undefined>(undefined)

function postcardsByGradeKey(gradeFilterData?: GradeFilterData) {
  return [
    'postcardsByGradeAtom',
    gradeFilterData?.id,
    gradeFilterData?.limit,
    gradeFilterData?.skip
  ]
}

export const [postcardsByGradeAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const gradeFilterData = get(gradeFilterDataAtom)

  return {
    queryKey: postcardsByGradeKey(gradeFilterData),
    queryFn: async () => {
      if (!gradeFilterData || !gradeFilterData.id) return undefined

      const isParentSelfCare =
        gradeFilterData?.id ===
        process.env.REACT_APP_CONTENTFUL_PARENT_SELF_CARE

      if (isParentSelfCare) {
        const response = await api.getPostcardsByCategoryId({
          id: gradeFilterData.id,
          limit: gradeFilterData.limit,
          skip: gradeFilterData.skip
        })

        return response
      }

      const response = await api.getPostcardsByGradeId({
        id: gradeFilterData.id,
        limit: gradeFilterData.limit,
        skip: gradeFilterData.skip
      })

      return response
    }
  }
})

type CategoryFilterData = {
  categoryId?: string
  limit: number
  skip: number
}

export const categoryFilterDataAtom = atom<CategoryFilterData | undefined>(
  undefined
)

function postcardsByCategoryKey(categoryFilterData?: CategoryFilterData) {
  return [
    'postcardsByCategoryAtom',
    categoryFilterData?.categoryId,
    categoryFilterData?.limit,
    categoryFilterData?.skip
  ]
}

export const [postcardsByCategoryAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const categoryFilterData = get(categoryFilterDataAtom)

  return {
    queryKey: postcardsByCategoryKey(categoryFilterData),
    queryFn: async () => {
      if (!categoryFilterData || !categoryFilterData.categoryId)
        return undefined

      const response = await api.getPostcardsByCategoryId({
        id: categoryFilterData.categoryId,
        limit: categoryFilterData.limit,
        skip: categoryFilterData.skip
      })

      return response
    }
  }
})

function postcardSubscribeBoxKey(postcardId?: string) {
  return ['postcardSubscribeBoxAtom', postcardId]
}

export const [postcardSubscribeBoxAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const postcardSubscribeBoxId =
    process.env.REACT_APP_CONTENTFUL_POSTCARD_SUBSCRIBE_BOX!

  return {
    queryKey: postcardSubscribeBoxKey(postcardSubscribeBoxId),
    queryFn: async () => {
      const response = await api.getPostcardsPageBanner({
        id: postcardSubscribeBoxId
      })

      return response.postcardPage
    }
  }
})
