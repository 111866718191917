import { AssetRenderer, RichText } from 'components'
import { BasicFilteredToolItem } from 'data/contentful/api.graphql'
import { Link } from 'react-router-dom'

type Props = {
  tool: BasicFilteredToolItem
}

export function ToolPreview({ tool }: Props) {
  const linkToTool = `/toolkitDetails/${tool.sys.id}`

  return (
    <div className="ToolPreview">
      <Link to={linkToTool}>
        <AssetRenderer asset={tool.toolPreviewImage} />
        <p className="title">{tool.name}</p>
      </Link>

      <RichText content={tool.toolPreviewText?.json} />
    </div>
  )
}
