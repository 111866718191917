import { OverlayTrigger, Tooltip } from 'react-bootstrap'

/**
 * Renders a tooltip when the user hovers this component.
 *
 * @param key unique identifier for this component.
 * @param children content to render inside of this component's container.
 * @param message text to show inside of the tooltip.
 * @param placement location relative to the [children] in which we want to
 * show the tooltip.
 */
export const CustomTooltip = ({ key, children, message, placement }) => {
  return (
    <OverlayTrigger
      key={key}
      placement={placement}
      overlay={<Tooltip id={`tooltip-top`}>{message}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  )
}
