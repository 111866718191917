import { useAtomValue, useSetAtom } from 'jotai'
import {
  customerDetailsAtom,
  refetchUserDashboardDataAtom,
  userDashboardDataAtom
} from 'pages/resource_room/core/atoms'
import { UserSubscriptionView } from './user_subscription_view'
import { Link } from 'react-router-dom'
import { EditAccountInfoModal } from '../edit_account_info_modal'
import { useState } from 'react'
import { currentUserAtom, refetchCurrentUserAtom } from 'pages/auth/core/atoms'
import { ResponsiveGrid } from 'components'
import { getGradeLabel } from 'core/grades'

export function UserDashboard() {
  const currentUser = useAtomValue(currentUserAtom)
  const refetchCurrentUser = useSetAtom(refetchCurrentUserAtom)

  const userDashboardData = useAtomValue(userDashboardDataAtom)
  const refetchUserDashboardData = useSetAtom(refetchUserDashboardDataAtom)

  const customerDetails = useAtomValue(customerDetailsAtom)

  const [showingEditAccountInfoModal, setShowingEditAccountInfoModal] =
    useState(false)

  const handleShowEditAccountInfoModal = () =>
    setShowingEditAccountInfoModal(true)

  async function handleCloseEditAccountInfoModal(reloadData: boolean) {
    setShowingEditAccountInfoModal(false)

    if (reloadData) {
      await refetchCurrentUser()
      await refetchUserDashboardData()
    }
  }

  const sortedGrades = Object.values(userDashboardData?.grades ?? []).sort(
    (a, b) => (a > b ? 1 : -1)
  )

  const totalAmountOfItems = Object.keys(sortedGrades).length

  return (
    <>
      <section className="UserDashboard">
        <div className="container">
          <aside className="col-lg-9 col-md-8 col-12">
            <h2>Account Info</h2>

            <div className="infoBox Grades">
              <h3>Grades info</h3>
              <ResponsiveGrid
                totalAmountOfItems={totalAmountOfItems}
                numberOfColumns={3}
              >
                {userDashboardData?.grades &&
                  sortedGrades.map((grade) => {
                    const gradeIndex = userDashboardData.grades.findIndex(
                      (value) => {
                        return value === grade
                      }
                    )

                    const gradeLabel = getGradeLabel(
                      userDashboardData.grades[gradeIndex]
                    )

                    if (gradeLabel.length === 0) {
                      return <></>
                    }

                    return (
                      <div key={grade}>
                        <p>{gradeLabel}</p>
                      </div>
                    )
                  })}
              </ResponsiveGrid>
            </div>

            <div className="infoBox PersonalInfo">
              <h3>Personal Info</h3>

              {currentUser && (
                <>
                  <p>Name: {currentUser.name}</p>
                  <p>Email: {currentUser.email}</p>
                </>
              )}

              <Link to="" onClick={handleShowEditAccountInfoModal}>
                Edit Information
              </Link>
            </div>

            <div className="infoBox">
              <h3>Subscription Info</h3>

              <UserSubscriptionView details={customerDetails} />
            </div>
          </aside>
        </div>
      </section>

      <EditAccountInfoModal
        isModalShowing={showingEditAccountInfoModal}
        onClose={handleCloseEditAccountInfoModal}
      />
    </>
  )
}
