import { atom } from 'jotai'
import { AuthService, createAuthService } from './auth_service'
import { User } from './types'
import { p4pApiAtom } from 'data'
import { atomsWithQuery } from 'jotai-tanstack-query'

/**
 * Returns an instace of the application's authentication service.
 */
export const authServiceAtom = atom<AuthService>((get) => {
  const api = get(p4pApiAtom)

  return createAuthService(api)
})

/**
 * Returns the currently signed in user, if any.
 */
export const [currentUserAtom] = atomsWithQuery<User | null>((get) => {
  const api = get(p4pApiAtom)

  return {
    queryKey: ['currentUser'],
    queryFn: async () => {
      const response = await api.getUser()

      if (response?.status === 200) {
        return response.data
      }

      return null
    }
  }
})

/**
 * Prompts jotai to refetch {@link currentUserAtom}.
 */
export const refetchCurrentUserAtom = atom(null, (_, set) => {
  return set(currentUserAtom, { type: 'refetch' })
})
