import { contentfulApiAtom } from 'data'
import { atomsWithQuery } from 'jotai-tanstack-query'

function defaultErrorMessageKey() {
  return ['defaultErrorMessageAtom']
}

// Queries default error message content from Contentful.
export const [defaultErrorMessageAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)

  return {
    queryKey: defaultErrorMessageKey(),
    queryFn: async () => {
      const defaultErrorMessage = await api.getDefaultErrorMessage({
        id: process.env.REACT_APP_CONTENTFUL_DEFAULT_ERROR_MESSAGE!
      })

      return defaultErrorMessage.pageModule
    }
  }
})
