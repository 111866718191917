import { ToolkitPageModule } from 'data'
import { ToolkitPageToolkitDescription } from './toolkit_page_toolkit_description'
import { ToolkitPageToolkitItem } from './toolkit_page_toolkit_item'

type Props = {
  toolkit?: ToolkitPageModule | null
}

export function ToolkitPageToolkitDetails({ toolkit }: Props) {
  return (
    <section>
      <div className="row row-eq-height">
        <div className="col-md-4 col-sm-6">
          <ToolkitPageToolkitDescription
            title={toolkit?.title}
            description={toolkit?.moduleText1?.json}
            backgroundColor={toolkit?.backgroundColor?.value}
            titleColor={toolkit?.titleTextColor?.value}
            contentColor={toolkit?.contentTextColor?.value}
          />
        </div>

        {toolkit?.otherContentCollection?.items.map((item) => (
          <div key={item?.sys.id} className="col-md-4 col-sm-6">
            <ToolkitPageToolkitItem toolkitItem={item} />
          </div>
        ))}
      </div>
    </section>
  )
}
