import { LoadingView, RichText } from 'components'
import { useAtomValue } from 'jotai'
import { ParentQuizResults } from 'pages/quiz/core/types'
import {
  empowermentTabAdditionalContentAtom,
  empowermentTabAdditionalContentIdsAtom,
  empowermentTabContentAtom
} from 'pages/resource_room/core/atoms'
import { Suspense } from 'react'
import { OverrideAtom } from 'utils/jotai'
import { WelcomeKitContentChunkRenderer } from './welcome_kit_content_chunk_renderer'

type Props = {
  results: ParentQuizResults
}

export function EmpowermentTab({ results }: Props) {
  return (
    <Suspense fallback={<LoadingView />}>
      <OverrideAtom
        atom={empowermentTabAdditionalContentIdsAtom}
        value={[
          results.setting_limits,
          results.structure_and_routine,
          results.expectation,
          results.autonomy
        ]}
      >
        <EmpowermentTabContent />
      </OverrideAtom>
    </Suspense>
  )
}

function EmpowermentTabContent() {
  const content = useAtomValue(empowermentTabContentAtom)
  const additionalContent = useAtomValue(empowermentTabAdditionalContentAtom)

  return (
    <div className="TabContent">
      <RichText content={content?.pageText?.json} />

      <div className="content">
        {additionalContent.map((contentChunk) => (
          <WelcomeKitContentChunkRenderer
            key={contentChunk.sys.id}
            contentChunk={contentChunk}
          />
        ))}
      </div>
    </div>
  )
}
