import { BasicWelcomeKitPage } from 'data/contentful/api.graphql'
import { ConnectionGraph } from './graphs/connection_graph'
import { EmpowermentGraph } from './graphs/empowerment_graph'
import { LoadingView, RichText } from 'components'
import { ParentPersona } from './parent_persona'
import { Link } from 'react-router-dom'
import { ParentQuizResults } from 'pages/quiz/core/types'
import { Suspense } from 'react'

type Props = {
  results: ParentQuizResults
  pageContent: BasicWelcomeKitPage
  isQuizResults: boolean
}

export function SummaryTab({ results, pageContent, isQuizResults }: Props) {
  return (
    <Suspense fallback={<LoadingView />}>
      <div className="SummaryTab">
        <ConnectionGraph
          data={results.emp_attune}
          additionalResults={results.additional_results}
        />

        <EmpowermentGraph
          data={results.struc_control}
          additionalResults={results.additional_results}
        />

        {!isQuizResults && (
          <Link className="retakeSurvey" to="/quiz">
            Retake survey
          </Link>
        )}

        <h2>Hi {results.user_details?.fname}!</h2>

        <RichText content={pageContent.pageText?.json} />

        <ParentPersona results={results} />
      </div>
    </Suspense>
  )
}
