import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { useAtomValue } from 'jotai'
import { p4pApiAtom } from 'data/p4p_api/atoms'
import { toast } from 'react-toastify'

export function PasswordResetPage() {
  const api = useAtomValue(p4pApiAtom)

  const params = useParams()

  const navigate = useNavigate()

  const initialValues = {
    password: '',
    confirm_password: ''
  }

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    onSubmit: async (values) => {
      const response = await api.resetPassword({
        ...values,
        token: params.token
      })

      if (response.status !== 200) {
        if (response?.data?.error) {
          const errorMessage = Object.keys(response?.data?.error).map((key) =>
            response?.data?.error[key].map((message) => <p>{message}</p>)
          )
          toast.error(<>{errorMessage}</>)
          return
        }

        toast.error('Error while processing request')
        return
      }

      toast.success('Password updated successfully')
      toast.onChange((payload) => {
        if (payload.status === 'removed' && response.status === 200) {
          navigate('/')
        }
      })
    },
    validate: (values) => {
      const error = {}

      if (values.password.length === 0) {
        error['password'] = 'Please provide a password'
      }

      if (
        values.confirm_password.length === 0 ||
        values.confirm_password !== values.password
      ) {
        error['confirm_password'] = 'Passwords do not match'
      }

      return error
    }
  })

  return (
    <div className="PasswordResetPage">
      <div className="container">
        <form className="LoginModal" onSubmit={formik.handleSubmit}>
          <div className="header">
            <h3>Password Reset</h3>
          </div>

          <div className="loginForm">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />

            {formik.errors.password && (
              <span className="errorMessage">{formik.errors.password}</span>
            )}

            <input
              type="password"
              id="confirm_password"
              name="confirm_password"
              placeholder="Password confirmation"
              onChange={formik.handleChange}
              value={formik.values.confirm_password}
            />

            {formik.errors.confirm_password && (
              <span className="errorMessage">
                {formik.errors.confirm_password}
              </span>
            )}

            <input className="submitBtn" type="submit" value="Reset password" />
          </div>
        </form>
      </div>
    </div>
  )
}
