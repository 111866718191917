import { AssetRenderer, LoadingView, RichText } from 'components'
import { ChildStyleResultsSection, contentfulApiAtom } from 'data'
import { useAtomValue } from 'jotai'
import { KidQuizResults } from 'pages/quiz'
import { childStyleResultsHeaderAtom } from 'pages/resource_room/core/atoms'
import { Suspense, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  results: KidQuizResults
}

export function ChildStyleItemTab({ results }: Props) {
  return (
    <Suspense fallback={<LoadingView />}>
      <ChildStyleItemTabContent results={results} />
    </Suspense>
  )
}

export function ChildStyleItemTabContent({ results }: Props) {
  const api = useAtomValue(contentfulApiAtom)

  const resultsHeader = useAtomValue(childStyleResultsHeaderAtom)

  const [content, setContent] = useState<ChildStyleResultsSection[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function loadContent() {
      setIsLoading(true)

      let sections: ChildStyleResultsSection[] = []

      const keys = Object.keys(results.sections)

      for (let i = 0; i < keys.length; i++) {
        const response = await api.getChildStyleResultsSection({
          id: results.sections[keys[i]]
        })

        if (response.welcomeKitPersonalFeedback) {
          sections = [...sections, response.welcomeKitPersonalFeedback]
        }
      }

      setContent(sections)

      setIsLoading(false)
    }

    if (content.length === 0) {
      loadContent()
    }
  }, [results, content, api])

  return (
    <div className="ChildStyleItemTab">
      <RichText content={resultsHeader?.pageText?.json} />

      {isLoading ? (
        <LoadingView />
      ) : (
        content.map((section, index) => (
          <ChildStyleItemTabSection
            key={section.sys.id}
            index={index}
            grade={results.grade}
            section={section}
          />
        ))
      )}
    </div>
  )
}

type ChildStyleItemTabSectionProps = {
  index: number
  grade: string
  section: ChildStyleResultsSection
}

function ChildStyleItemTabSection({
  index,
  grade,
  section
}: ChildStyleItemTabSectionProps) {
  const haveImagesToRender =
    section.imageToGoWithContentCollection?.items &&
    section.imageToGoWithContentCollection?.items.length > 0

  return (
    <div className="section row">
      <div className={haveImagesToRender ? 'col-md-6' : ''}>
        <RichText content={section.parentFeedback?.json} />

        {index === 0 && (
          <Link to={`/kid-quiz?grade=${grade}`}>
            Not a fit? Retake the survey
          </Link>
        )}
      </div>

      {haveImagesToRender && (
        <div className="col-md-6">
          {section.imageToGoWithContentCollection?.items.map((item) => (
            <AssetRenderer key={item?.sys.id} asset={item} />
          ))}
        </div>
      )}
    </div>
  )
}
