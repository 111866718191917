import { useAtomValue } from 'jotai'
import { useState } from 'react'
import Draggable from 'react-draggable'
import { Link } from 'react-router-dom'
import { sideFlagContentAtom } from './atoms'
import { currentUserAtom } from 'pages/auth'
import { AssetRenderer } from 'components/asset_renderer'
import { imgCloseBlackSmaller, imgRightArrowBlack } from 'core/images'

/**
 * Renders a draggable view that is used to show users a call out to perform some action.
 */
export function DraggableNotice() {
  const currentUser = useAtomValue(currentUserAtom)

  const sideFlagContent = useAtomValue(sideFlagContentAtom)

  const [showNotice, setShowNotice] = useState(true)

  // Because this is mostly used to show a call out for users to answer the Parent Quiz,
  // if the user is signed in, it most likely means they already answered it, so we
  // don't want to show anything
  if (!showNotice || currentUser) {
    return <></>
  }

  return (
    <Draggable cancel=".need-interaction">
      <div className="DraggableNotice">
        <div>
          <div
            className="need-interaction closeBtn"
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()

              setShowNotice(false)
            }}
          >
            <img
              src={imgCloseBlackSmaller}
              className="img-fluid"
              alt="Close button"
            />
          </div>

          <h3>{sideFlagContent?.pageModuleName}</h3>

          <div className="image">
            {sideFlagContent?.moduleImagesCollection &&
              sideFlagContent?.moduleImagesCollection?.items.length > 0 && (
                <AssetRenderer
                  asset={sideFlagContent.moduleImagesCollection.items[0]}
                />
              )}
          </div>

          <Link to="/quiz" className="need-interaction findOut">
            Find out{' '}
            <img
              src={imgRightArrowBlack}
              className="img-fluid"
              alt="Right arrow"
            />
          </Link>
        </div>
      </div>
    </Draggable>
  )
}
