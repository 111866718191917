import { CSSProperties, ReactNode } from 'react'
import type { Property } from 'csstype'
import { cn } from 'utils/cn'
import { customColors } from 'styles/colors'

export type CustomTextStyle = 'headerLarge' | 'headerNormal' | 'bodyNormal'

type Props = {
  children: ReactNode
  id?: string
  style?: CSSProperties
  textStyle?: CustomTextStyle
  textColor?: string | null
  textAlign?: Property.TextAlign
  fontWeight?: Property.FontWeight
  fontStyle?: Property.FontStyle
}

/**
 * Renders the default text component for the application.
 *
 * @param children content to render inside of this component's container.
 * Anything should work here, but usually this expects a string.
 * @param id unique identifier for this component.
 * @param style additional styles to set to this component.
 * @param textStyle default style for the text. See {@link CustomTextStyle} to learn more.
 * @param textColor color for the text. Overrides both [style] and [textStyle].
 * @param textAlign Text alignment. See {@link Property.TextAlign} to learn more.
 * Overrides both [style] and [textStyle].
 * @param fontWeight Weight of the text font. See {@link Property.FontWeight} to learn more.
 * Overrides both [style] and [textStyle].
 * @param fontStyle Style for the text font. See {@link Property.FontStyle} to learn more.
 * Overrides both [style] and [textStyle].
 */
export function CustomText({
  children,
  id,
  style,
  textStyle = 'bodyNormal',
  textColor = customColors.blue4,
  textAlign,
  fontWeight,
  fontStyle
}: Props) {
  return (
    <span
      id={id}
      className={cn('CustomText', textStyle)}
      style={{
        ...style,
        ...(textColor && { color: textColor }),
        ...(textAlign && { textAlign: textAlign }),
        ...(fontWeight && { fontWeight: fontWeight }),
        ...(fontStyle && { fontStyle: fontStyle })
      }}
    >
      {children}
    </span>
  )
}
