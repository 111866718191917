import { BasicAsset } from 'data'
import { RichText } from './rich_text'
import { AssetRenderer } from './asset_renderer'
import { Document } from '@contentful/rich-text-types'

type ItemProps = {
  index: number
  content?: Document | null
  image?: BasicAsset | null
  titleTextColor?: string | null
  contentTextColor?: string | null
  reverseOrder?: boolean
}

/**
 * Renders a component to be used inside staggered box. This component
 * is made up by an image, some content and a title.
 *
 * You can view an example of its usage at {@link PageModuleHowItWorks}.
 *
 * @param index index of this item inside of the list it is in.
 * @param content content to be rendered.
 * @param image image to be rendered.
 * @param titleTextColor color of the title.
 * @param contentTextColor color of the [content].
 * @param reverseOrder controls whether [image] and [content] should switch
 * places. By default, the image is at the right of the content.
 */
export function StaggeredBoxItem({
  index,
  content,
  image,
  titleTextColor,
  contentTextColor,
  reverseOrder
}: ItemProps) {
  return (
    <li>
      <div className="row align-items-end">
        <div
          className={`col-md-8 col-sm-8 col-8 ${
            reverseOrder ? 'order-md-2' : ''
          }`}
        >
          <h1 style={{ color: titleTextColor ?? 'black' }}>{index}.</h1>
          <RichText content={content} textColor={contentTextColor} />
        </div>
        <div className="col-md-4 col-sm-4 col-4">
          <AssetRenderer asset={image} />
        </div>
      </div>
    </li>
  )
}
