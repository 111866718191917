import { CustomButton, CustomText } from 'components'
import { StaggeredBoxItem } from 'components/staggered_box_item'
import { BasicPageModule } from 'data'
import { useWindowData } from 'utils/hooks/use_window_data'

type Props = {
  moduleData: BasicPageModule
}

export function PageModuleHowItWorks({ moduleData }: Props) {
  const window = useWindowData()

  const isMobile = window.width < 768

  if (isMobile) {
    return <PageModuleHowItWorksMobile moduleData={moduleData} />
  }

  return <PageModuleHowItWorksDesktop moduleData={moduleData} />
}

function PageModuleHowItWorksDesktop({ moduleData }: Props) {
  return (
    <div className="HowItWorks">
      <CustomText
        textColor={moduleData.titleTextColor?.value}
        textStyle="headerLarge"
      >
        {moduleData.title}
      </CustomText>

      <p />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="col-tab">
              <ul>
                <StaggeredBoxItem
                  index={1}
                  content={moduleData.moduleText1?.json}
                  image={moduleData.moduleImagesCollection?.items[0]}
                  titleTextColor={moduleData.titleTextColor?.value}
                  contentTextColor={moduleData.contentTextColor?.value}
                />
                <StaggeredBoxItem
                  index={3}
                  content={moduleData.moduleText3?.json}
                  image={moduleData.moduleImagesCollection?.items[2]}
                  titleTextColor={moduleData.titleTextColor?.value}
                  contentTextColor={moduleData.contentTextColor?.value}
                  reverseOrder={true}
                />
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="col-tab">
              <ul>
                <StaggeredBoxItem
                  index={2}
                  content={moduleData.moduleText2?.json}
                  image={moduleData.moduleImagesCollection?.items[1]}
                  titleTextColor={moduleData.titleTextColor?.value}
                  contentTextColor={moduleData.contentTextColor?.value}
                />
                <StaggeredBoxItem
                  index={4}
                  content={moduleData.moduleText4?.json}
                  image={moduleData.moduleImagesCollection?.items[3]}
                  titleTextColor={moduleData.titleTextColor?.value}
                  contentTextColor={moduleData.contentTextColor?.value}
                  reverseOrder={true}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>

      <CustomButton
        type="white"
        onClick={() => window.location.replace(`/#learnMore`)}
      >
        Sign up!
      </CustomButton>
    </div>
  )
}

function PageModuleHowItWorksMobile({ moduleData }: Props) {
  return (
    <div className="HowItWorks">
      <CustomText
        textColor={moduleData.titleTextColor?.value}
        textStyle="headerLarge"
      >
        {moduleData.title}
      </CustomText>

      <p />

      <div className="container-fluid">
        <div className="column">
          <div className="col-sm-12 col-md-6">
            <div className="col-tab">
              <ul>
                <StaggeredBoxItem
                  index={1}
                  content={moduleData.moduleText1?.json}
                  image={moduleData.moduleImagesCollection?.items[0]}
                  titleTextColor={moduleData.titleTextColor?.value}
                  contentTextColor={moduleData.contentTextColor?.value}
                />
                <StaggeredBoxItem
                  index={2}
                  content={moduleData.moduleText2?.json}
                  image={moduleData.moduleImagesCollection?.items[1]}
                  titleTextColor={moduleData.titleTextColor?.value}
                  contentTextColor={moduleData.contentTextColor?.value}
                />
                <StaggeredBoxItem
                  index={3}
                  content={moduleData.moduleText3?.json}
                  image={moduleData.moduleImagesCollection?.items[2]}
                  titleTextColor={moduleData.titleTextColor?.value}
                  contentTextColor={moduleData.contentTextColor?.value}
                />
                <StaggeredBoxItem
                  index={4}
                  content={moduleData.moduleText4?.json}
                  image={moduleData.moduleImagesCollection?.items[3]}
                  titleTextColor={moduleData.titleTextColor?.value}
                  contentTextColor={moduleData.contentTextColor?.value}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>

      <CustomButton
        type="white"
        onClick={() => window.location.replace(`/#learnMore`)}
      >
        Sign up!
      </CustomButton>
    </div>
  )
}
