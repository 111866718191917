const COLOR_BLUE_1 = 'rgb(var(--color-blue-1))'
const COLOR_BLUE_2 = 'rgb(var(--color-blue-2))'
const COLOR_BLUE_3 = 'rgb(var(--color-blue-3))'
const COLOR_BLUE_4 = 'rgb(var(--color-blue-4))'
const COLOR_CREAM = 'rgb(var(--color-cream))'
const COLOR_GREEN = 'rgb(var(--color-green))'
const COLOR_GREY = 'rgb(var(--color-grey))'
const COLOR_LIGHT_PINK = 'rgb(var(--color-light-pink))'
const COLOR_ORANGE = 'rgb(var(--color-orange))'
const COLOR_ORANGE_HALF_OPACITY = 'rgb(var(--color-orange-half-opacity))'
const COLOR_RED = 'rgb(var(--color-red))'
const COLOR_PINK = 'rgb(var(--color-pink))'
const COLOR_YELLOW = 'rgb(var(--color-yellow))'

export const customColors = {
  blue1: COLOR_BLUE_1,
  blue2: COLOR_BLUE_2,
  blue3: COLOR_BLUE_3,
  blue4: COLOR_BLUE_4,
  cream: COLOR_CREAM,
  green: COLOR_GREEN,
  grey: COLOR_GREY,
  lightPink: COLOR_LIGHT_PINK,
  orange: COLOR_ORANGE,
  orangeHalfOpacity: COLOR_ORANGE_HALF_OPACITY,
  colorRed: COLOR_RED,
  colorPink: COLOR_PINK,
  colorYellow: COLOR_YELLOW
}
