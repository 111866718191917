import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileLines,
  faInfo,
  faSignOut,
  faUser,
  faCircle
} from '@fortawesome/free-solid-svg-icons'
import { ResourceRoomTab, ResourceRoomTabKey } from '../core/types'
import { useEffect, useMemo } from 'react'
import { useAtomValue } from 'jotai'
import { authServiceAtom, currentUserAtom } from 'pages/auth/core/atoms'

type Props = {
  currentTab: ResourceRoomTabKey
}

export function MenuSideBar({ currentTab }: Props) {
  const currentUser = useAtomValue(currentUserAtom)
  const authService = useAtomValue(authServiceAtom)

  const navigate = useNavigate()

  const items = useMemo<ResourceRoomTab[]>(
    () => [
      {
        key: 'welcome_kit',
        label: 'Welcome Kit',
        url: '/welcomeKit',
        icon: faUser
      },
      {
        key: 'child_style',
        label: 'Child Style',
        url: '/child-style',
        icon: faCircle
      },
      {
        key: 'your_postcards',
        label: 'Your Postcards',
        url: '/postcards-office-hours',
        icon: faFileLines
      },
      {
        key: 'account_info',
        label: 'Account Info',
        url: '/userDashboard',
        icon: faInfo
      },
      {
        key: 'log_out',
        label: 'Logout',
        url: '/',
        icon: faSignOut
      }
    ],
    []
  )

  useEffect(() => {
    if (currentUser === null) {
      navigate('/')
    }
  }, [currentUser, navigate])

  async function logout() {
    await authService.logout()
  }

  return (
    <ul className="MenuSideBar">
      {items.map((item) => {
        const className = currentTab === item.key ? 'active' : ''

        if (item.key === 'log_out') {
          return (
            <li key={item.key} className={className}>
              <Link to={item.url} onClick={logout}>
                <FontAwesomeIcon icon={item.icon} /> &nbsp; {item.label}
              </Link>
            </li>
          )
        }

        return (
          <li key={item.key} className={className}>
            <Link to={item.url}>
              <FontAwesomeIcon icon={item.icon} /> &nbsp; {item.label}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
