import { RichText } from 'components'
import { BasicCitation } from 'data/contentful/api.graphql'

type Props = {
  citations?: Array<BasicCitation | null>
}

export function PostcardDetailsSources({ citations }: Props) {
  if (!citations || citations.length === 0) return <></>

  return (
    <div className="PostcardDetailsSources">
      <h3>SOURCES</h3>
      {citations.map((citation) => (
        <RichText
          key={citation?.sys.id}
          content={citation?.studyBibliography?.json}
        />
      ))}
    </div>
  )
}
