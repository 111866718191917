import { contentfulApiAtom } from 'data'
import { atomsWithQuery } from 'jotai-tanstack-query'

function recentPostcardsKey() {
  return ['recentPostcardsAtom']
}

/**
 * Queries a list of postcards organized by the published date,
 * starting with the most recent one.
 */
export const [recentPostcardsAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)

  return {
    queryKey: recentPostcardsKey(),
    queryFn: async () => {
      const response = await api.getRecentPostcards()

      return response.postcardCollection?.items
    }
  }
})

function productOfferDataKey(productOfferId: string) {
  return ['productOfferDataAtom', productOfferId]
}

/**
 * Queries the promocional content we want to show users.
 * The result of this query contains all subscription options available.
 */
export const [productOfferDataAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)

  const productOfferId = process.env.REACT_APP_CONTENTFUL_REGULAR_OFFER!

  return {
    queryKey: productOfferDataKey(productOfferId),
    queryFn: async () => {
      const productOffer = await api.getProductOffer({ id: productOfferId })

      return productOffer.productOffer
    }
  }
})
