import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useScrollPosition } from 'utils/hooks/use_scroll_position'
import { LoginModal } from 'pages/auth'
import { NavbarDesktop } from './navbar_desktop'
import { useWindowData } from 'utils/hooks/use_window_data'
import { NavbarMobile } from './navbar_mobile'

/**
 * Renders the navigation bar for the application.
 *
 * This component reacts to how far the user scrolled in the current page
 * and the width of the window rendering it. Depending on those, it will rebuild
 * so users have a better experience.
 */
export function Navbar() {
  const [scrolledPastThreshold, setScrolledPastThreshold] = useState(false)

  const [showingLoginModal, setShowingLoginModal] = useState(false)

  const windowData = useWindowData()

  const navigate = useNavigate()

  const scrollPosition = useScrollPosition()

  useEffect(() => {
    if (scrollPosition.scrollY > 150) {
      setScrolledPastThreshold(true)
    } else if (scrollPosition.scrollY < 50) {
      setScrolledPastThreshold(false)
    }
  }, [scrollPosition])

  function handleClickMenuItem(id?: string) {
    navigate(`/gradelist/${id}`)
  }

  const handleShowLoginModal = () => setShowingLoginModal(true)
  const handleCloseLoginModal = () => setShowingLoginModal(false)

  return (
    <>
      {windowData.isMobile ? (
        <NavbarMobile
          scrolledPastThreshold={scrolledPastThreshold}
          onClickShowLoginModal={handleShowLoginModal}
        />
      ) : (
        <NavbarDesktop
          scrolledPastThreshold={scrolledPastThreshold}
          onClickMenuItem={handleClickMenuItem}
          onClickShowLoginModal={handleShowLoginModal}
        />
      )}

      <LoginModal
        isModalShowing={showingLoginModal}
        onClose={handleCloseLoginModal}
      />
    </>
  )
}
