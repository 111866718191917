import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import { NavbarUserActions } from './navbar_user_actions'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { cn } from 'utils/cn'
import { imgLogo } from 'core/images'

type Props = {
  scrolledPastThreshold: boolean
  onClickMenuItem?: (id?: string, isGradeFilter?: boolean) => void
  onClickShowLoginModal?: () => void
}

/**
 * Renders the mobile version of the navigation bar.
 *
 * By "mobile" we mean the width of the screen rendering this component should be
 * under a certain threshold. See {@link useWindowData} to learn more.
 *
 * @param scrolledPastThreshold determines whether the user scrolled a certain threshold
 * and the component should react to it.
 * @param onClickMenuItem callback function triggered whenver user taps an item from
 * the navigation bar.
 * @param onClickShowLoginModal callback function triggered whenever the user taps the log in button.
 */
export function NavbarMobile({
  scrolledPastThreshold,
  onClickMenuItem,
  onClickShowLoginModal
}: Props) {
  const [isDropdownShowing, setIsDropdownShowing] = useState(false)

  const toggleDropdownShowing = () => setIsDropdownShowing(!isDropdownShowing)

  return (
    <Navbar expand="lg" className="bg-body-tertiary Navbar mobile">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={imgLogo}
            className="img-fluid"
            alt="Postcards for parents logo"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <div className="toggleMenu">
            <span />
            <span />
            <span />
          </div>
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown
              className="postcardsDropdown mobile"
              title={
                <div
                  className={cn(
                    'postcardsMenuItem',
                    isDropdownShowing ? 'arrow-up' : 'arrow-down'
                  )}
                  onClick={toggleDropdownShowing}
                >
                  <p>Postcards </p>
                  <FontAwesomeIcon icon={faAngleUp} />
                </div>
              }
              id="basic-nav-dropdown"
              show={isDropdownShowing}
            >
              <NavDropdown.Item
                onClick={() =>
                  onClickMenuItem?.(
                    process.env.REACT_APP_CONTENTFUL_GRADE_PRE_K_ID
                  )
                }
              >
                Pre K
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() =>
                  onClickMenuItem?.(
                    process.env.REACT_APP_CONTENTFUL_GRADE_ELEMENTARY_ID
                  )
                }
              >
                Elementary
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() =>
                  onClickMenuItem?.(
                    process.env.REACT_APP_CONTENTFUL_GRADE_ADOLESCENT_ID
                  )
                }
              >
                Adolescent
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() =>
                  onClickMenuItem?.(
                    process.env.REACT_APP_CONTENTFUL_PARENT_SELF_CARE
                  )
                }
              >
                Parent Self-care
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>

      <NavbarUserActions
        scrolledPastThreshold={scrolledPastThreshold}
        onClickLogin={onClickShowLoginModal}
      />
    </Navbar>
  )
}
