import { CustomerDetails } from 'data/p4p_api/types'
import { Link } from 'react-router-dom'
import { capitalizeFirstLetter } from 'utils/string'

type Props = {
  details: CustomerDetails
}

export function UserSubscriptionHistory({ details }: Props) {
  const subscription = details.subscription!

  const renewDate = new Date(
    subscription.current_period_end
  ).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })

  const status = capitalizeFirstLetter(subscription.status.replace('_', ' '))

  return (
    <div className="UserSubscriptionHistory">
      <h3>Current plan</h3>
      <p>{subscription.plan_name}</p>
      <p>
        ${subscription.plan_amount / 100} per {subscription.plan_interval}
      </p>
      <p className="status">
        Status: <span>{status}</span>
      </p>
      {subscription.status === 'active' && (
        <p>Your plan renews on {renewDate}</p>
      )}
      <Link to={details.customer_portal_link} target="_blank">
        Manage subscription
      </Link>
    </div>
  )
}
