import { cn } from 'utils/cn'
import { Coupon } from '../../core/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { useAtomValue } from 'jotai'
import { p4pApiAtom } from 'data'

type Props = {
  product_id: string
  coupon?: Coupon
  isLoading: boolean
  setCoupon: React.Dispatch<React.SetStateAction<Coupon | undefined>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export function CouponElement({
  product_id,
  coupon,
  isLoading,
  setCoupon,
  setIsLoading
}: Props) {
  const api = useAtomValue(p4pApiAtom)

  const [couponCode, setCouponCode] = useState(coupon?.coupon)

  useEffect(() => {
    if (couponCode && couponCode.length > 0) {
      validateCouponCode()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function validateCouponCode() {
    setIsLoading(true)

    const response = await api.validateCouponCode({
      coupon: couponCode,
      product_id: product_id
    })

    if (response.status === 200) {
      setCoupon(response.data)
    } else {
      setCoupon({
        valid: false,
        applies_to_product: true,
        requires_payment_info: true
      })
    }

    setIsLoading(false)
  }

  return (
    <div className="CouponElement">
      <p className="label">Coupon</p>

      <div
        className={cn(
          'inputContainer',
          coupon && (!coupon.valid || !coupon.applies_to_product)
            ? 'errorCoupon'
            : ''
        )}
      >
        <input
          value={couponCode}
          disabled={isLoading}
          onChange={(event) => {
            setCouponCode(event.target.value)
            setCoupon(undefined)
          }}
        />

        {couponCode && (
          <FontAwesomeIcon
            icon={faClose}
            opacity={isLoading ? 0.4 : 1}
            onClick={() => {
              if (isLoading) return

              setCouponCode('')
              setCoupon(undefined)
            }}
          />
        )}

        <button
          onClick={validateCouponCode}
          disabled={isLoading || !couponCode || couponCode.length === 0}
        >
          Check
        </button>
      </div>

      {coupon?.valid && coupon?.applies_to_product && coupon?.name && (
        <>
          <p className="label">This coupon gives you: {coupon?.name}</p>
          {!coupon.requires_payment_info && (
            <p className="coupon_info">
              You don't need to provide any payment information to get a
              subscription using this coupon
            </p>
          )}
        </>
      )}

      {coupon &&
        (!coupon?.applies_to_product ? (
          <p className="error">Coupon not valid for selected subscription</p>
        ) : !coupon.valid ? (
          <p className="error">Coupon not valid</p>
        ) : (
          <></>
        ))}
    </div>
  )
}
