import { AssetRenderer, RichText } from 'components'
import { ToolkitPageModuleContentItem } from 'data'
import { Link } from 'react-router-dom'

type Props = {
  toolkitItem?: ToolkitPageModuleContentItem | null
}

export function ToolkitPageToolkitItem({ toolkitItem }: Props) {
  if (toolkitItem?.__typename !== 'Tool') return <></>

  const toolkitDetailsURL = `/toolkitDetails/${toolkitItem.sys.id}`

  const toolkitCategory = toolkitItem.toolCategoryCollection?.items?.[0]
  const toolkitCategoryURL = `/catlist2/tool/${toolkitCategory?.sys.id}`

  return (
    <div className="ToolkitPageToolkitItem">
      <div>
        <Link to={toolkitDetailsURL}>
          <AssetRenderer asset={toolkitItem.toolPreviewImage} />
        </Link>
      </div>

      <Link className="title" to={toolkitDetailsURL}>
        <h3>{toolkitItem.name}</h3>
      </Link>

      <div className="category">
        <Link to={toolkitCategoryURL}>{toolkitCategory?.toolCategoryName}</Link>
      </div>

      <RichText content={toolkitItem.toolPreviewText?.json} />
    </div>
  )
}
