import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AssetRenderer, RichText } from 'components'
import { BasicPostcard } from 'data/contentful/api.graphql'
import { useNavigate } from 'react-router-dom'

type Props = {
  postcard: BasicPostcard
  showImage?: boolean
  showReadMore?: boolean
  showGrade?: boolean
  delayPriorToNavigating?: number
  onClick?: () => void
}

/**
 * Renders a component used to show users a preview of the contents of
 * a postcard.
 *
 * @param postcard content of the preview. See {@link BasicPostcard} to learn more.
 * @param showImage controls whether an image should be shown on the top of the preview.
 * @param showReadMore controls whether a "Read more" button should be rendered which
 * takes users to the postcard's details page.
 * @param showGrade controls whether the postcard's grade should be rendered.
 * @param delayPriorToNavigating when using this component in a modal, it's important to
 * add a delay before navigating to another page because we have to wait until the modal
 * is fully dismissed. After a few tests, 200ms seems like a good delay, but it might need
 * tweaking depending on the use case.
 * @param onClick callback function triggered whenever users tap on this component's container.
 * You don't need to add navigating logic here because it is handled by the component itself.
 */
export function PostcardPreview({
  postcard,
  showImage = true,
  showReadMore = true,
  showGrade = true,
  delayPriorToNavigating = 0,
  onClick
}: Props) {
  const navigate = useNavigate()

  const linkToPostcard = `/details/${postcard.sys.id}`

  function handleClick(url: string) {
    onClick?.()
    setTimeout(() => {
      navigate(url)
    }, delayPriorToNavigating)
  }

  return (
    <div className="PostcardPreview">
      <div onClick={() => handleClick(linkToPostcard)} className="header">
        {showImage && <AssetRenderer asset={postcard.introImage} />}
        <p className="title">{postcard.title}</p>
      </div>

      <RichText content={postcard.introText?.json} />

      {showReadMore && (
        <div className="readMore" onClick={() => handleClick(linkToPostcard)}>
          <p>Read more</p>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      )}

      <div className="tags">
        {showGrade && (
          <div
            className="tag"
            onClick={() =>
              handleClick(`/gradelist/${postcard.gradeLevel?.sys.id}`)
            }
          >
            {postcard.gradeLevel?.gradeTitle}
          </div>
        )}
        {showGrade && <span className="divider" />}
        <div
          className="tag"
          onClick={() =>
            handleClick(`/catList/${postcard.contentType?.sys.id}`)
          }
        >
          {postcard.contentType?.contentType}
        </div>
      </div>
    </div>
  )
}
