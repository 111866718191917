import { contentfulApiAtom } from 'data'
import { atomsWithQuery } from 'jotai-tanstack-query'

function sideFlagContentKey() {
  return ['sideFlagContentAtom']
}

// Queries side flag content from Contentful.
// Side flag is the floating notice users see in the home page.
export const [sideFlagContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)

  return {
    queryKey: sideFlagContentKey(),
    queryFn: async () => {
      const sideFlagContent = await api.getSideFlagContent({
        id: process.env.REACT_APP_CONTENTFUL_SIDE_FLAG!
      })

      return sideFlagContent.pageModule
    }
  }
})
