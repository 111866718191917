import { FilteredDataView, LoadingView } from 'components'
import { Suspense, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { OverrideAtom } from 'utils/jotai'
import { toolCategoryFilterDataAtom, toolsByCategoryAtom } from '../core/atoms'
import { useAtom, useAtomValue } from 'jotai'
import { ToolPreview } from '../components/tool_preview'

const recordsPerPage = 12

export function ToolsByCategoryPage() {
  const params = useParams()

  return (
    <Suspense fallback={<LoadingView />}>
      <OverrideAtom
        atom={toolCategoryFilterDataAtom}
        value={{ categoryId: params.id, limit: recordsPerPage, skip: 0 }}
      >
        <ToolsByCategoryPageContent />
      </OverrideAtom>
    </Suspense>
  )
}

function ToolsByCategoryPageContent() {
  const [toolCategoryFilterData, setToolCategoryFilterData] = useAtom(
    toolCategoryFilterDataAtom
  )
  const tools = useAtomValue(toolsByCategoryAtom)

  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setToolCategoryFilterData({
      ...toolCategoryFilterData!,
      skip: (currentPage - 1) * recordsPerPage
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const numberOfPages = Math.ceil((tools?.total ?? 0) / recordsPerPage)

  return (
    <FilteredDataView
      data={tools}
      title={
        tools?.items?.[0]?.toolCategoryCollection?.items?.[0]?.toolCategoryName
      }
      numberOfPages={numberOfPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      buildItem={(data) => <ToolPreview tool={data} />}
    />
  )
}
