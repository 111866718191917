import { Outlet } from 'react-router-dom'
import { Navbar } from './navbar/navbar'
import { Footer } from './footer/footer'
import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
}

/**
 * Helper component to render both the {@link Navbar} and the {@link Footer}
 * for the application.
 *
 * [Outlet] is a component from react-router-dom that renders the component
 * associated to the current route.
 */
export function AppWrapper({ children }: Props) {
  return (
    <>
      <Navbar />
      {children ?? <Outlet />}
      <Footer />
    </>
  )
}
