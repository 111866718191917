import { useAtomValue } from 'jotai'
import { toolkitAtom } from '../core/atoms'
import { PageBanner } from 'components/page_banner'
import { ToolkitPageToolkitDetails } from '../components/toolkit_page_toolkit_details'
import { DraggableNotice } from 'components'

export function ToolkitPage() {
  const content = useAtomValue(toolkitAtom)

  return (
    <div className="ToolkitPage container">
      <PageBanner pageBanner={content?.pageBanner} />

      <div className="toolkitCollection">
        {content?.pageModulesCollection?.items.map((toolkit) => (
          <ToolkitPageToolkitDetails key={toolkit?.sys.id} toolkit={toolkit} />
        ))}
      </div>

      <DraggableNotice />
    </div>
  )
}
