import { SearchModal } from 'components/search_modal/search_modal'
import { BasicTag } from 'data/contentful/api.graphql'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'

type Props = {
  tags?: Array<BasicTag | null>
}

export function PostcardDetailsTags({ tags }: Props) {
  const [showingSearchModal, setShowingSearchModal] = useState(false)
  const [searchInput, setSearchInput] = useState('')

  if (!tags || tags.length === 0) return <></>

  return (
    <>
      <div className="PostcardDetailsTags">
        <Row>
          {tags.map((tag) => {
            if (!tag) return <></>

            return (
              <Col
                key={tag.sys.id}
                onClick={() => {
                  setSearchInput(tag.tag ?? '')
                  setShowingSearchModal(true)
                }}
              >
                {tag.tag}
              </Col>
            )
          })}
        </Row>
      </div>

      <SearchModal
        isModalShowing={showingSearchModal}
        searchInput={searchInput}
        onClose={() => setShowingSearchModal(false)}
      />
    </>
  )
}
