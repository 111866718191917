import { BasicQuizQuestion } from 'data/contentful/api.graphql'
import { ParentQuiz, ParentQuizResults } from '../core/types'
import { useFormik } from 'formik'
import { useAtomValue, useSetAtom } from 'jotai'
import { p4pApiAtom } from 'data'
import { toast } from 'react-toastify'
import { grades } from 'core/grades'
import { currentUserAtom, refetchCurrentUserAtom } from 'pages/auth/core/atoms'
import { useEffect } from 'react'

type Props = {
  reference?: React.RefObject<HTMLDivElement>
  quizQuestion: BasicQuizQuestion
  quizAnswers: ParentQuiz
  previousQuizResults?: ParentQuizResults | null
  setQuizAnswers: React.Dispatch<React.SetStateAction<ParentQuiz>>
  setQuizResults: React.Dispatch<React.SetStateAction<ParentQuizResults>>
}

type PayloadProps = {
  name: string
  value: string | number
}

export function ParentQuizGetResults({
  reference,
  quizQuestion,
  quizAnswers,
  previousQuizResults,
  setQuizAnswers,
  setQuizResults
}: Props) {
  const api = useAtomValue(p4pApiAtom)

  const currentUser = useAtomValue(currentUserAtom)

  const refetchCurrentUser = useSetAtom(refetchCurrentUserAtom)

  const formik = useFormik({
    initialValues: quizAnswers,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      await formik.validateForm(values)

      const sliderQuestions = quizAnswers.sliderQuestions.map((answer) => ({
        name: answer.variableName,
        value: answer.value
      }))

      let payload: PayloadProps[] = []

      for (const iterator in sliderQuestions) {
        if (
          !sliderQuestions[iterator].name ||
          !sliderQuestions[iterator].value
        ) {
          continue
        }

        payload.push({
          name: 'scalequestion[]',
          value: sliderQuestions[iterator].name
        })

        payload.push({
          name: `scaleOption${+iterator + 1}`,
          value: sliderQuestions[iterator].value
        })
      }

      for (const iterator in quizAnswers) {
        if (iterator === 'grades') {
          quizAnswers[iterator].forEach((grade) => {
            payload.push({ name: 'grade[]', value: grade })
          })
        } else if (
          iterator !== 'sliderQuestions' &&
          !iterator.includes('grade')
        ) {
          payload.push({ name: iterator, value: quizAnswers[iterator] })
        }
      }

      try {
        const response = await api.calculateParentQuizResults({
          resultdata: payload
        })

        if (response.status !== 200) {
          toast.error(
            response.data.error.message ?? 'Error while processing request'
          )
          return
        }

        await refetchCurrentUser()

        setQuizResults(response.data)
      } catch (e) {
        toast.error('Error while processing request')
        console.error(e)
      }
    },
    validate: (values) => {
      const error = {}

      if (!values.fname || values.fname.length === 0) {
        error['fname'] = 'Please provide a first name'
      }

      if (!values.lname || values.lname.length === 0) {
        error['lname'] = 'Please provide a last name'
      }

      if (!values.email || values.email.length === 0) {
        error['email'] = 'Please provide an email'
      }

      if (values.grades.length === 0) {
        error['grades'] = 'Please select at least one grade'
      }

      return error
    }
  })

  useEffect(() => {
    if (previousQuizResults?.user_details) {
      const newQuizAnswers = {
        ...quizAnswers,
        email: previousQuizResults?.user_details?.email,
        fname: previousQuizResults?.user_details?.fname,
        lname: previousQuizResults?.user_details?.lname,
        grades: previousQuizResults?.user_details?.grades ?? []
      }

      formik.setValues(newQuizAnswers)
      setQuizAnswers(newQuizAnswers)
    } else if (currentUser) {
      const name = currentUser.name.split(' ')

      const newQuizAnswers = {
        ...quizAnswers,
        email: currentUser.email,
        fname: name[0],
        lname: name.length > 1 ? name[1] : ''
      }

      formik.setValues(newQuizAnswers)
      setQuizAnswers(newQuizAnswers)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleFormDataChanged(event) {
    let newValues = formik.values

    switch (event.target.name) {
      case 'fname':
        newValues = { ...newValues, fname: event.target.value }
        break

      case 'lname':
        newValues = { ...newValues, lname: event.target.value }
        break

      case 'email':
        newValues = { ...newValues, email: event.target.value }
        break

      default:
        if (event.target.name.toString().includes('=')) {
          const grade = parseInt(event.target.name.split('=')[1])

          const grades = newValues.grades

          const gradesIndex = grades.findIndex((item) => item === grade)

          if (gradesIndex >= 0) {
            grades.splice(gradesIndex, 1)
          } else {
            grades.push(grade)
          }

          grades.sort((a, b) => (a > b ? 1 : -1))

          newValues = { ...newValues, grades: grades }
        }
    }

    setQuizAnswers(newValues)

    formik.handleChange(event)
  }

  return (
    <div className="ParentQuizGetResults" ref={reference}>
      <h3>{quizQuestion.quizQuestion}</h3>

      <form onSubmit={formik.handleSubmit}>
        <div className="userDataContainer">
          <input
            className={`userData ${formik.errors.fname ? 'error' : ''}`}
            type="text"
            name="fname"
            placeholder="First Name"
            value={formik.values.fname}
            onChange={handleFormDataChanged}
          />
          {formik.errors.fname && (
            <span className="errorMessage">{formik.errors.fname}</span>
          )}

          <input
            className={`userData ${formik.errors.lname ? 'error' : ''}`}
            type="text"
            name="lname"
            placeholder="Last Name"
            value={formik.values.lname}
            onChange={handleFormDataChanged}
          />
          {formik.errors.lname && (
            <span className="errorMessage">{formik.errors.lname}</span>
          )}

          <input
            className={`userData ${formik.errors.email ? 'error' : ''}`}
            type="email"
            name="email"
            placeholder="Email Address"
            value={formik.values.email}
            onChange={handleFormDataChanged}
          />
          {formik.errors.email && (
            <span className="errorMessage">{formik.errors.email}</span>
          )}
        </div>

        <p className="gradeListHeader">Your child's grade(s)</p>

        <div>
          {grades.map((grade) => (
            <div key={grade.key} className="gradeListContainer">
              <h4>{grade.gradeLabel}</h4>
              <div
                className={`gradeList ${formik.errors.grades ? 'error' : ''}`}
              >
                {grade.items.map((gradeItem) => {
                  return (
                    <label key={gradeItem.key}>
                      <input
                        type="checkbox"
                        name={`grade=${gradeItem.key}`}
                        onChange={handleFormDataChanged}
                        checked={formik.values.grades.includes(gradeItem.key)}
                      />
                      <span className="checkmark"></span>
                      <span className="grade">{gradeItem.label}</span>
                    </label>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
        {formik.errors.grades && (
          <span className="errorMessage">{formik.errors.grades}</span>
        )}

        <button className="submit" type="submit">
          Get results!
        </button>
      </form>
    </div>
  )
}
