import React from 'react'

type Props = {
  numberOfPages: number
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

/**
 * Renders the default pagination component for the application.
 *
 * @param numberOfPages total number of pages this components will control.
 * @param currentPage current page index selected. Starts at 1.
 * @param setCurrentPage dispatch function that sets the value of [currentPage].
 * This comes from a useState hook.
 */
export function Pagination({
  numberOfPages,
  currentPage,
  setCurrentPage
}: Props) {
  // Builds the page numbers array we want this component to have
  const pageNumbers = Array.from(Array(numberOfPages + 1).keys())

  // Because our page numbers have to start at 1, this function
  // removes the first position in the array, meaning it removes
  // the number 0 from it.
  pageNumbers.shift()

  const nextPage = () => {
    if (currentPage !== numberOfPages) {
      setCurrentPage(currentPage + 1)
      window.scrollTo(0, 0)
    }
  }

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
      window.scrollTo(0, 0)
    }
  }

  return (
    <nav className="Pagination">
      <ul className="pagination justify-content-center flex-wrap">
        <li className="page-item">
          <button className="page-link" onClick={prevPage}>
            Previous
          </button>
        </li>
        {pageNumbers.map((pagenumber) => (
          <li
            key={pagenumber}
            className={`page-item ${
              currentPage === pagenumber ? 'active' : ''
            } `}
          >
            <button
              onClick={() => setCurrentPage(pagenumber)}
              className="page-link"
            >
              {pagenumber}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button className="page-link" onClick={nextPage}>
            Next
          </button>
        </li>
      </ul>
    </nav>
  )
}
