import { contentfulApiAtom } from 'data'
import { atom } from 'jotai'
import { atomsWithQuery } from 'jotai-tanstack-query'

type EmailWrapperPaginationData = {
  limit: number
  skip: number
}

export const emailWrapperPaginationDataAtom = atom<
  EmailWrapperPaginationData | undefined
>(undefined)

function getAllEmailWrappersKey(paginationData?: EmailWrapperPaginationData) {
  return [
    'getAllEmailWrappersAtom',
    paginationData?.limit,
    paginationData?.skip
  ]
}

export const [getAllEmailWrappersAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const paginationData = get(emailWrapperPaginationDataAtom)

  return {
    queryKey: getAllEmailWrappersKey(paginationData),
    queryFn: async () => {
      if (!paginationData || !paginationData.limit) return null

      const response = await api.getAllEmailWrappers({
        limit: paginationData.limit,
        skip: paginationData.skip
      })

      return response
    }
  }
})
