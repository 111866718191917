import { AssetRenderer, CustomText } from 'components'
import { RichText } from 'components/rich_text'
import { BasicPageBanner } from 'data/contentful/api.graphql'
import { ReactNode } from 'react'

type Props = {
  pageBanner?: BasicPageBanner | null
  showImage?: boolean
  additionalComponent?: ReactNode
}

/**
 * Renders a banner containing an image and some text.
 *
 * You have the option to add more components below the text
 * via [additionalComponent].
 *
 * @param pageBanner content to be used as a reference to build
 * this component. See {@link BasicPageBanner} to learn more.
 * @param showImage controls whether an image should be drawn alongside
 * the other content.
 * @param additionalComponent extra content to be rendered below this
 * component's text.
 */
export function PageBanner({
  pageBanner,
  showImage = true,
  additionalComponent
}: Props) {
  if (!showImage) {
    return (
      <section className="PageBanner">
        <div className="container">
          <div className="row">
            <aside className="col-md-6">
              <CustomText
                textStyle="headerLarge"
                textColor={pageBanner?.titleTextColor?.value}
              >
                {pageBanner?.name}
              </CustomText>
            </aside>

            <aside className="col-md-6 mt-md-0 mt-4">
              <RichText
                content={pageBanner?.description?.json}
                textColor={pageBanner?.contentTextColor?.value}
              />
              {additionalComponent}
            </aside>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className="PageBanner">
      <div className="container">
        <div className="row">
          <aside className="col-md-6">
            <CustomText
              textStyle="headerLarge"
              textColor={pageBanner?.titleTextColor?.value}
            >
              {pageBanner?.name}
            </CustomText>
            <p />
            <RichText
              content={pageBanner?.description?.json}
              textColor={pageBanner?.contentTextColor?.value}
            />
            {additionalComponent}
          </aside>

          <aside className="col-md-6 my-auto d-none d-md-block">
            <AssetRenderer asset={pageBanner?.image} />
          </aside>
        </div>
      </div>
    </section>
  )
}
