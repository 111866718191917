import { Quotes } from '../quotes'
import { YourPostcardsContent } from './your_postcards_content'

type Props = {
  userFirstName: string
}

export function YourPostcards({ userFirstName }: Props) {
  return (
    <section className="YourPostcards">
      <div className="container">
        <h2>{userFirstName}’s Room</h2>

        <Quotes />

        <YourPostcardsContent />
      </div>
    </section>
  )
}
