import { LoadingView, PageModuleSale, RichText } from 'components'
import { useAtomValue } from 'jotai'
import { currentUserAtom } from 'pages/auth'
import { childStyleKidQuizNotAnsweredAtom } from 'pages/resource_room/core/atoms'
import { Suspense } from 'react'
import { Link } from 'react-router-dom'

export function ChildStyleKidQuizNotAnswered() {
  return (
    <Suspense fallback={<LoadingView />}>
      <ChildStyleKidQuizNotAnsweredContent />
    </Suspense>
  )
}

export function ChildStyleKidQuizNotAnsweredContent() {
  const currentUser = useAtomValue(currentUserAtom)

  const content = useAtomValue(childStyleKidQuizNotAnsweredAtom)

  return (
    <div className="ChildStyleKidQuizNotAnswered">
      <h1 style={{ color: content?.titleTextColor?.value ?? '' }}>
        {content?.name}
      </h1>

      <RichText content={content?.description?.json} />

      {currentUser?.has_active_subscription ? (
        <Link to="/kid-quiz">Go to Kid Quiz</Link>
      ) : (
        <PageModuleSale />
      )}
    </div>
  )
}
