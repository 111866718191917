import { CustomText, RichText } from 'components'
import { PageModuleText } from './page_module_text'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper/modules'
import { Document } from '@contentful/rich-text-types'
import { BasicPageModule } from 'data'
import { appUrl } from 'core/consts'

type Props = {
  moduleData: BasicPageModule
}

export function PageModuleTestimonials({ moduleData }: Props) {
  const items = moduleData.otherContentCollection?.items

  let testimonialIndex = -1

  return (
    <div className="PageModuleTestimonials row align-items-center">
      <div className="col-md-6">
        <PageModuleText moduleData={moduleData} />
      </div>
      <div className="col-md-6">
        <Swiper
          slidesPerView={1}
          spaceBetween={32}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            waitForTransition: true
          }}
          speed={1000}
          navigation={true}
          modules={[Autoplay, Navigation]}
        >
          {items?.map((item) => {
            // Because this collection support any type, we have to filter
            // out only the ones that makes sense for this use case.
            if (item?.__typename === 'QuotesParentTestimonial') {
              // We have 3 differently colored images, anytime the index
              // below goes beyond 2, we have to reset it to its starting
              // value.
              if (testimonialIndex > 2) {
                testimonialIndex = -1
              }

              testimonialIndex++

              return (
                <SwiperSlide key={item.sys.id}>
                  <div className={`slider-image`}>
                    <Testimonial
                      index={testimonialIndex}
                      testimonial={item.testimonial?.json}
                      name={item.parentName}
                    />
                  </div>
                </SwiperSlide>
              )
            }

            return <></>
          })}
        </Swiper>
      </div>
    </div>
  )
}

type TestimonialProps = {
  index: number
  name?: string | null
  testimonial?: Document | null
}

function Testimonial({ index, name, testimonial }: TestimonialProps) {
  return (
    <div className="item">
      <div
        className="chat-box"
        style={{
          backgroundImage: `url(${appUrl}images/chat_box${index}.webp)`
        }}
      >
        <RichText content={testimonial} />
        <CustomText>– {name}</CustomText>
      </div>
    </div>
  )
}
