import { toast } from 'react-toastify'
import { AuthData, ForgotPasswordData } from './types'
import { P4PApi } from 'data'
import { initializeApp } from 'firebase/app'
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup
} from 'firebase/auth'

export type AuthService = ReturnType<typeof createAuthService>

/**
 * Builds the application's authentication service.
 */
export function createAuthService(api: P4PApi) {
  async function login({
    authData,
    onSuccess
  }: {
    authData: AuthData
    onSuccess?: () => void
  }) {
    try {
      const response = await api.login(authData)

      if (response.status !== 200) {
        toast.error('Invalid login credentials')
        return
      }

      localStorage.setItem('token', response.data.token)

      toast.success('Login successful')

      onSuccess?.()
    } catch (e) {
      toast.error('Error while logging in')
      console.error(e)
    }
  }

  async function socialLogin({
    provider = 'google',
    onSuccess
  }: {
    provider?: 'google' | 'facebook'
    onSuccess?: () => void
  }) {
    const authProvider =
      provider === 'google'
        ? new GoogleAuthProvider()
        : new FacebookAuthProvider()

    const auth = getFirebaseAuth()

    await signInWithPopup(auth, authProvider)
      .then(async (result) => {
        const token = await result.user.getIdToken()

        const response = await api.socialLogin({ token })

        if (response.status !== 200) {
          toast.error('Invalid login credentials')
          return
        }

        localStorage.setItem('token', response.data.token)

        toast.success('Login successful')

        onSuccess?.()
      })
      .catch((e) => {
        toast.error('Error while logging in')
        console.error(e)
      })
  }

  async function logout(onSuccess?: () => void) {
    try {
      await api.logout()
    } catch (e) {
      console.error(e)
    }

    localStorage.removeItem('token')

    window.location.reload()

    onSuccess?.()
  }

  async function forgotPassword({
    forgotPasswordData,
    onSuccess
  }: {
    forgotPasswordData: ForgotPasswordData
    onSuccess?: () => void
  }) {
    try {
      const response = await api.forgotPassword(forgotPasswordData)

      if (response.status !== 200) {
        toast.error('Invalid login credentials')
        return
      }

      toast.success('Password reset email sent successfully')

      onSuccess?.()
    } catch (e) {
      toast.error('Error while processing request')
      console.error(e)
    }
  }

  return { login, socialLogin, logout, forgotPassword }
}

function getFirebaseAuth() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_AUTH_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_AUTH_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_AUTH_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_AUTH_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_AUTH_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_AUTH_MEASUREMENT_ID
  }

  const app = initializeApp(firebaseConfig)

  return getAuth(app)
}
