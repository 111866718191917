import { AssetRenderer } from 'components'
import { recentPostcardsAtom } from 'core/atoms'
import { useAtomValue } from 'jotai'
import { Link } from 'react-router-dom'

/**
 * Renders a list postcards ordered by the date they were published,
 * starting from the most recent one.
 *
 * If any errors occur while querying the list of postcards or nothing
 * returns in the query, nothing is rendered.
 */
export function RecentPostcardsSection() {
  const recentPostcards = useAtomValue(recentPostcardsAtom)

  if (!recentPostcards || recentPostcards.length === 0) return <></>

  return (
    <div className="RecentPostcardsSection">
      <h3>RECENT POSTCARDS</h3>
      {recentPostcards.slice(0, 10).map((postcard) => (
        <div key={postcard?.sys.id}>
          <Link className="postcard" to={`/details/${postcard?.sys.id}`}>
            <AssetRenderer asset={postcard?.introImage} />
            <p>{postcard?.title}</p>
          </Link>
        </div>
      ))}
    </div>
  )
}
