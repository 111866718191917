import { CustomButton, RichText } from 'components'
import { useAtomValue } from 'jotai'
import { currentUserAtom } from 'pages/auth'
import { childStyleIntroContentAtom } from 'pages/resource_room/core/atoms'
import { useNavigate } from 'react-router-dom'

export function AboutTab() {
  const currentUser = useAtomValue(currentUserAtom)

  const content = useAtomValue(childStyleIntroContentAtom)

  const navigate = useNavigate()

  return (
    <div className="AboutTab">
      <p className="title">{content?.pageTitle}</p>

      <RichText content={content?.pageText?.json} title={content?.pageTitle} />

      {currentUser?.has_active_subscription && (
        <CustomButton
          onClick={() => {
            navigate('/kid-quiz')
          }}
        >
          Find out
        </CustomButton>
      )}
    </div>
  )
}
