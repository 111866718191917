import { Document } from '@contentful/rich-text-types'
import { RichText } from 'components'

type Props = {
  title?: string | null
  description?: Document
  backgroundColor?: string | null
  titleColor?: string | null
  contentColor?: string | null
}

export function ToolkitPageToolkitDescription({
  title,
  description,
  backgroundColor,
  titleColor,
  contentColor
}: Props) {
  return (
    <div
      className="ToolkitPageToolkitDescription"
      style={{ backgroundColor: backgroundColor ?? '' }}
    >
      <h3 style={{ color: titleColor ?? '' }}>{title}</h3>

      <RichText content={description} textColor={contentColor} />
    </div>
  )
}
