import { useAtomValue } from 'jotai'
import { aboutUsAtom } from '../core/atoms'
import { AboutPageBanner } from '../components/about_page_banner'
import { AboutPageContent } from '../components/about_page_content'
import { DraggableNotice, GetInTouch } from 'components'

export function AboutPage() {
  const content = useAtomValue(aboutUsAtom)

  return (
    <div className="AboutPage">
      <AboutPageBanner content={content?.pageBanner} />

      {content?.pageModulesCollection?.items.map((module) => (
        <AboutPageContent key={module?.sys.id} module={module} />
      ))}

      <GetInTouch />

      <DraggableNotice />
    </div>
  )
}
