import { useAtomValue } from 'jotai'
import { LoadingView, RichText } from 'components'
import { Suspense } from 'react'
import { kidQuizNoActiveSubscriptionAtom } from '../core/atoms'
import { PageModuleSale } from 'components/page_module_sale/page_module_sale'

export function KidQuizNoActiveSubscription() {
  return (
    <Suspense fallback={<LoadingView />}>
      <KidQuizNoActiveSubscriptionContent />
    </Suspense>
  )
}

export function KidQuizNoActiveSubscriptionContent() {
  const content = useAtomValue(kidQuizNoActiveSubscriptionAtom)

  if (!content) return <></>

  return (
    <div className="KidQuizNoActiveSubscription">
      <div className="container">
        <h3 style={{ color: content.titleTextColor?.value ?? '' }}>
          {content?.name}
        </h3>

        <RichText content={content.description?.json} />

        <PageModuleSale />
      </div>
    </div>
  )
}
