import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { User } from 'pages/auth'
import { SearchModal } from 'components/search_modal/search_modal'
import { useState } from 'react'
import { NavbarUserActionsSocials } from './navbar_user_actions_socials'
import { NavbarAuthSection } from './navbar_auth_section'

type Props = {
  currentUser?: User | null
  onClickLogin?: (event) => void
  onClickLogout?: (event) => void
}

/**
 * Renders what we call the "complete" version of the navigation bar actions view.
 *
 * The biggest difference for this version is how items are laid out and we render an
 * input so users search whatever they want.
 *
 * @param currentUser currently signed in user.
 * @param onClickLogin callback function triggered whenever the user taps the log in button.
 * @param onClickLogout callback function triggered whenever the user taps the log out button.
 */
export function NavbarUserActionsCompleteView({
  currentUser,
  onClickLogin,
  onClickLogout
}: Props) {
  const [showingSearchModal, setShowingSearchModal] = useState(false)
  const [searchInput, setSearchInput] = useState('')

  return (
    <div className="NavbarUserActions col-md-3 col-sm-6 order-md-3">
      <div>
        <NavbarAuthSection
          currentUser={currentUser}
          onClickLogin={onClickLogin}
          onClickLogout={onClickLogout}
        />

        <div className="separator" />

        <div className="searchField">
          <input
            type="text"
            name="inputSearchPostcards"
            placeholder="Search Postcards"
            onChange={(event) => {
              const input = event.target.value
              setSearchInput(input)
            }}
            value={searchInput}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setShowingSearchModal(true)
              }
            }}
          />

          <button
            type="submit"
            data-toggle="modal"
            data-target="#searchModal"
            aria-label="Search"
            onClick={() => {
              setShowingSearchModal(true)
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>

        <div className="separator" />

        <NavbarUserActionsSocials />
      </div>

      <SearchModal
        isModalShowing={showingSearchModal}
        searchInput={searchInput}
        onClose={() => {
          setShowingSearchModal(false)
        }}
      />
    </div>
  )
}
