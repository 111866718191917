import { useParams } from 'react-router-dom'
import { ToolkitDetailsContent } from '../components/toolkit_details_content'
import { toolDetailsAtom, toolIdAtom } from '../core/atoms'
import { OverrideAtom } from 'utils/jotai'
import { RecentPostcardsSection } from 'components'
import { useAtomValue } from 'jotai'

export function ToolkitDetailsPage() {
  const params = useParams()

  return (
    <OverrideAtom atom={toolIdAtom} value={params.id}>
      <ToolkitDetailsPageContent />
    </OverrideAtom>
  )
}

export function ToolkitDetailsPageContent() {
  const toolDetails = useAtomValue(toolDetailsAtom)

  if (!toolDetails) return <></>

  return (
    <div className="ToolkitDetailsPageContent container">
      <div className="row">
        <aside className="col-md-9">
          <ToolkitDetailsContent tool={toolDetails} />
        </aside>
        <aside className="col-md-3">
          <RecentPostcardsSection />
        </aside>
      </div>
    </div>
  )
}
