import { useAtomValue } from 'jotai'
import { kidQuizUserSignedOutAtom } from '../core/atoms'
import { LoadingView, RichText } from 'components'
import { Suspense } from 'react'

export function KidQuizUserSignedOut() {
  return (
    <Suspense fallback={<LoadingView />}>
      <KidQuizUserSignedOutContent />
    </Suspense>
  )
}

export function KidQuizUserSignedOutContent() {
  const content = useAtomValue(kidQuizUserSignedOutAtom)

  if (!content) return <></>

  return (
    <div className="KidQuizUserSignedOut">
      <div className="container">
        <h3 style={{ color: content.titleTextColor?.value ?? '' }}>
          {content?.name}
        </h3>

        <RichText content={content.description?.json} />
      </div>
    </div>
  )
}
