import { Suspense, useEffect, useState } from 'react'
import { LoadingView, Pagination } from 'components'
import { PostcardPreview } from '../../../../components/postcard_preview'
import { Container, Row, Col } from 'react-bootstrap'
import { OverrideAtom } from 'utils/jotai'
import {
  userPostcardsFilterOptionsAtom,
  userPostcardsSearchAtom,
  userPostcardsSearchDataAtom
} from 'pages/resource_room/core/atoms'
import { useAtom, useAtomValue } from 'jotai'
import { currentUserAtom } from 'pages/auth'
import { useDebouncedCallback } from 'use-debounce'
import { imgCloseBlackSmaller } from 'core/images'

const recordsPerPage = 10

export function YourPostcardsContent() {
  return (
    <OverrideAtom
      atom={userPostcardsSearchDataAtom}
      value={{ grades: [], limit: recordsPerPage, skip: 0 }}
    >
      <Suspense fallback={<LoadingView />}>
        <YourPostcardsFilteredContent />
      </Suspense>
    </OverrideAtom>
  )
}

export function YourPostcardsFilteredContent() {
  const currentUser = useAtomValue(currentUserAtom)

  const filterOptions = useAtomValue(userPostcardsFilterOptionsAtom)

  const [userPostcardsSearchData, setUserPostcardsSearchDataAtom] = useAtom(
    userPostcardsSearchDataAtom
  )

  const postcards = useAtomValue(userPostcardsSearchAtom)

  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')

  const grades = currentUser?.grades ?? []

  const debounceChange = useDebouncedCallback((searchQuery: string) => {
    if (grades.length === 0) return

    setUserPostcardsSearchDataAtom({
      ...userPostcardsSearchData!,
      searchQuery: searchQuery,
      skip: 0
    })
  }, 500)

  useEffect(() => {
    setUserPostcardsSearchDataAtom({
      ...userPostcardsSearchData!,
      grades: grades,
      skip: (currentPage - 1) * recordsPerPage
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, grades])

  const numberOfPages = Math.ceil((postcards?.total ?? 0) / recordsPerPage)

  const havePostcardsToRender = postcards && postcards.items.length > 0

  return (
    <div className="YourPostcardsContent">
      <div className="searchContainer">
        <p>Search for a postcard:</p>

        <div className="searchInput">
          <input
            type="text"
            placeholder="Title"
            value={searchQuery}
            onChange={(event) => {
              event.stopPropagation()

              const searchQuery = event.target.value

              debounceChange(searchQuery)
              setSearchQuery(searchQuery)
            }}
          />

          {searchQuery && searchQuery.length > 0 && (
            <button
              onClick={() => {
                debounceChange('')
                setSearchQuery('')
              }}
            >
              <img
                src={imgCloseBlackSmaller}
                className="img-fluid"
                alt="Close button"
              />
            </button>
          )}
        </div>

        <Container>
          <Row sm={1} md={2} lg={5} xs={1}>
            {filterOptions &&
              filterOptions.map((item) => {
                const option = item?.contentType ?? 'Most recent'
                const isRadioChecked =
                  userPostcardsSearchData?.contentType === item?.sys.id

                function handleChangeFilterOption() {
                  setUserPostcardsSearchDataAtom({
                    ...userPostcardsSearchData!,
                    contentType: item?.sys.id,
                    skip: 0
                  })

                  setCurrentPage(1)
                }

                return (
                  <Col key={option} className="radio">
                    <input
                      type="radio"
                      value={option}
                      checked={isRadioChecked}
                      onChange={handleChangeFilterOption}
                    />
                    <label onClick={handleChangeFilterOption}>{option}</label>
                  </Col>
                )
              })}
          </Row>
        </Container>
      </div>

      {havePostcardsToRender ? (
        <>
          <Container>
            <Row md={1} lg={2}>
              {postcards?.items.map((postcard) => {
                if (!postcard) return <></>

                return (
                  <Col key={postcard.sys.id}>
                    <PostcardPreview postcard={postcard} showImage={false} />
                  </Col>
                )
              })}
            </Row>
          </Container>

          <Pagination
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      ) : (
        <div className="emptyResults">
          <h3>No results for the selected filter</h3>
        </div>
      )}
    </div>
  )
}
