import { contentfulApiAtom } from 'data'
import { atomsWithQuery } from 'jotai-tanstack-query'

function homePageDataKey() {
  return ['homePageDataAtom']
}

/**
 * Queries all content to be rendered in the home page.
 */
export const [homePageDataAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)

  return {
    queryKey: homePageDataKey(),
    queryFn: async () => {
      const homePageId = process.env.REACT_APP_CONTENTFUL_HOMEPAGE!

      const data = await api.getHomePageData({
        pageId: homePageId
      })

      return data.newPage
    }
  }
})
