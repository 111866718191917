import { RichText } from 'components'
import { StaggeredBoxItem } from 'components/staggered_box_item'
import { AboutPageModule } from 'data'

type Props = {
  module?: AboutPageModule | null
}

export function AboutPageContentWhySection({ module }: Props) {
  return (
    <div className="AboutPageContentWhySection">
      <RichText
        textClassName="title"
        content={module?.moduleText1?.json}
        textColor={module?.contentTextColor?.value}
      />

      <div className="row">
        <div className="col-md-6 mb-4">
          <StaggeredBoxItem
            index={1}
            content={module?.moduleText2?.json}
            image={module?.moduleImagesCollection?.items[0]}
            titleTextColor={module?.titleTextColor?.value}
            contentTextColor={module?.contentTextColor?.value}
            reverseOrder={true}
          />
        </div>
        <div className="col-md-6 mb-4">
          <StaggeredBoxItem
            index={2}
            content={module?.moduleText2?.json}
            image={module?.moduleImagesCollection?.items[1]}
            titleTextColor={module?.titleTextColor?.value}
            contentTextColor={module?.contentTextColor?.value}
            reverseOrder={true}
          />
        </div>
        <div className="col-md-6 mb-md-0 mb-4">
          <StaggeredBoxItem
            index={3}
            content={module?.moduleText3?.json}
            image={module?.moduleImagesCollection?.items[2]}
            titleTextColor={module?.titleTextColor?.value}
            contentTextColor={module?.contentTextColor?.value}
          />
        </div>
        <div className="col-md-6 mb-md-0 mb-4">
          <StaggeredBoxItem
            index={4}
            content={module?.moduleText4?.json}
            image={module?.moduleImagesCollection?.items[3]}
            titleTextColor={module?.titleTextColor?.value}
            contentTextColor={module?.contentTextColor?.value}
          />
        </div>
      </div>
    </div>
  )
}
