import { Elements } from '@stripe/react-stripe-js'
import { useAtomValue } from 'jotai'
import { stripeAtom } from '../../core/atoms'
import { CheckoutFormContent } from './checkout_form_content'
import { Coupon, Subscription } from '../../core/types'

type CheckoutFormProps = {
  name: string
  email: string
  subscription: Subscription
  coupon?: Coupon
  setCoupon: React.Dispatch<React.SetStateAction<Coupon | undefined>>
}

export function CheckoutForm({
  name,
  email,
  subscription,
  coupon,
  setCoupon
}: CheckoutFormProps) {
  const stripePromise = useAtomValue(stripeAtom)

  return (
    <div>
      <Elements
        key={subscription.price_id}
        stripe={stripePromise}
        options={{
          mode: 'subscription',
          amount: subscription.unit_amount,
          currency: subscription.currency,
          fonts: [
            {
              cssSrc:
                'https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'
            }
          ],
          appearance: {
            theme: 'flat',
            variables: {
              colorBackground: '#FFF',
              colorIconTabSelected: '#000',
              borderRadius: '0',
              fontFamily: '"Roboto Mono", monospace'
            },
            rules: {
              '.Tab--selected': {
                backgroundColor: '#F9D36F'
              },
              '.TabLabel--selected': {
                color: '#000'
              },
              '.Tab': {
                border: '1px solid #000'
              },
              '.Input': {
                border: '1px solid #000'
              }
            }
          }
        }}
      >
        <CheckoutFormContent
          name={name}
          email={email}
          subscription={subscription}
          coupon={coupon}
          setCoupon={setCoupon}
        />
      </Elements>
    </div>
  )
}
