import ReactGA from 'react-ga4'

type Category = 'idea' | 'subscription'

/**
 * Helper function to track custom events in Google Analytics.
 *
 * This function will only successfully track an event if the URL contains
 * a search parameter called "m", which usually contains the email that got
 * the original message which contained the URL.
 *
 * Once the event is tracked, the search parameter is removed from the URL,
 * so be careful to only call this function after storing that information,
 * if it is required.
 *
 * @param category in Google Analytics it will be stored under event_category
 * and contains the source of this URL.
 * @param label in Google Analytics it will be stored under event_label and
 * contains any additional information you wish to track.
 */
export function registerGoogleAnalyticsCustomEvent(
  category: Category,
  label: string
) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!)

  const href = window.location.href

  if (!href.includes('m=')) return

  const searchEmailStart = href.indexOf('m=') + 2

  const indexConcat = href.indexOf('&')
  const searchEmailEnd = indexConcat === -1 ? href.length : indexConcat

  const searchEmail = href.substring(searchEmailStart, searchEmailEnd)

  if (searchEmail) {
    ReactGA.event({
      action: 'page_view_email',
      category: category,
      label: label
    })

    removeEmailFromUrl(searchEmail, indexConcat)
  }
}

/**
 * This function removes the "m" search parameter from the URL to ensure
 * we don't track each page view event more than once.
 */
function removeEmailFromUrl(searchEmail: string, indexConcat: number) {
  let newHref = ''

  if (indexConcat === -1) {
    newHref = window.location.href.replace(`?m=${searchEmail}`, '')
  } else {
    newHref = window.location.href.replace(`m=${searchEmail}&`, '')
  }

  window.history.pushState(null, '', newHref)
}
