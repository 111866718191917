import { ReactNode } from 'react'
import { cn } from 'utils/cn'

export type CustomButtonType = 'green' | 'white'

type Props = {
  children: ReactNode
  className?: string
  type?: CustomButtonType
  enabled?: boolean
  onClick?: () => void
}

/**
 * Renders the default rounded button for the application.
 *
 * @param children component to render inside of the button. Typically
 * this can be just a text.
 * @param className additional className to add to the button container.
 * @param type controls which type of button to render.
 * @param enabled controls whether this button is enabled or not.
 * @param onClick callback function triggered whenever user taps this button.
 */
export function CustomButton({
  children,
  className,
  type = 'green',
  enabled = true,
  onClick
}: Props) {
  return (
    <button
      disabled={!enabled}
      className={cn(
        'CustomButton',
        type,
        !enabled ? 'disabled' : '',
        className
      )}
      onClick={() => {
        if (!enabled) {
          return
        }

        onClick?.()
      }}
    >
      {children}
    </button>
  )
}
