import { LoadingView, RichText } from 'components'
import { useAtomValue } from 'jotai'
import { ParentQuizResults } from 'pages/quiz/core/types'
import {
  connectionTabAdditionalContentAtom,
  connectionTabAdditionalContentIdsAtom,
  connectionTabContentAtom
} from 'pages/resource_room/core/atoms'
import { Suspense } from 'react'
import { OverrideAtom } from 'utils/jotai'
import { WelcomeKitContentChunkRenderer } from './welcome_kit_content_chunk_renderer'

type Props = {
  results: ParentQuizResults
}

export function ConnectionTab({ results }: Props) {
  return (
    <Suspense fallback={<LoadingView />}>
      <OverrideAtom
        atom={connectionTabAdditionalContentIdsAtom}
        value={[
          results.perspective_taking,
          results.play_and_time,
          results.positive_communication,
          results.democratic
        ]}
      >
        <ConnectionTabContent />
      </OverrideAtom>
    </Suspense>
  )
}

function ConnectionTabContent() {
  const content = useAtomValue(connectionTabContentAtom)
  const additionalContent = useAtomValue(connectionTabAdditionalContentAtom)

  return (
    <div className="TabContent">
      <RichText content={content?.pageText?.json} />

      <div className="content">
        {additionalContent.map((contentChunk) => (
          <WelcomeKitContentChunkRenderer
            key={contentChunk.sys.id}
            contentChunk={contentChunk}
          />
        ))}
      </div>
    </div>
  )
}
