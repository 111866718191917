import { atom, useSetAtom, WritableAtom } from 'jotai'
import { ReactNode, useMemo } from 'react'

type Props<T, R> = {
  children?: ReactNode
  atom: WritableAtom<T, T[], R>
  value: T
}

/**
 * Wrapper to help override an atom's value. Usually helpful whenever an atom
 * holds an ID and updating it is required.
 *
 * @param atom atom that will have its value overwritten.
 * @param value new value for the atom.
 */
export function OverrideAtom<T, R>({ atom, value, children }: Props<T, R>) {
  useOverrideAtom(atom, value)

  return <>{children}</>
}

function useOverrideAtom<T, R>(
  targetAtom: WritableAtom<T, T[], R>,
  targetValue: T
) {
  const syncedAtom = useMemo(() => {
    return atomWithOverride(targetAtom)
  }, [targetAtom])

  const set = useSetAtom(syncedAtom)

  set(targetValue)
}

function atomWithOverride<T, R>(
  atomToSync: WritableAtom<T, T[], R>
): WritableAtom<void, T[], unknown> {
  return atom(null, (get, set, value: T) => {
    const curValue = get(atomToSync)
    if (curValue === value) return

    set(atomToSync, value)
  })
}
