import { useAtomValue } from 'jotai'
import { parentTestimonialCollectionAtom } from 'pages/resource_room/core/atoms'

export function Quotes() {
  const testimonials = useAtomValue(parentTestimonialCollectionAtom)

  if (!testimonials || testimonials.length === 0) return <></>

  const quote = testimonials[generateRandomIndex(testimonials.length)]

  if (!quote) return <></>

  return (
    <div className="Quotes">
      <p>“{quote.summaryOfTestimonial}”</p>
      <span>- {quote.authorName}</span>
    </div>
  )
}

function generateRandomIndex(max: number) {
  return Math.floor(Math.random() * (max + 1))
}
