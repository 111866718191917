import { CustomerDetails } from 'data/p4p_api/types'
import { UserSubscriptionNoActiveSubscription } from './user_subscription_no_active_subscription'
import { UserSubscriptionHistory } from './user_subscription_history'

type Props = {
  details?: CustomerDetails | null
}

export function UserSubscriptionView({ details }: Props) {
  if (!details || !details.subscription) {
    return <UserSubscriptionNoActiveSubscription />
  }

  return <UserSubscriptionHistory details={details} />
}
