import { RichText, CustomButton, AssetRenderer } from 'components'
import { BasicPageBanner } from 'data'
import { useNavigate } from 'react-router-dom'

type Props = {
  content?: BasicPageBanner | null
}

export function AboutPageBanner({ content }: Props) {
  const navigate = useNavigate()

  return (
    <section className="AboutPageBanner container">
      <div className="row">
        <div className="col-md-5">
          <h1>{content?.name}</h1>

          <RichText content={content?.description?.json} />

          <CustomButton
            onClick={() => {
              navigate('/register')
            }}
          >
            {content?.button?.buttonType}
          </CustomButton>
        </div>

        <div className="col-md-7">
          <AssetRenderer asset={content?.image} />
        </div>
      </div>
    </section>
  )
}
