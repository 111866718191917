import { ForgotPasswordData } from '../core/types'
import { authServiceAtom } from '../core/atoms'
import { useFormik } from 'formik'
import { useAtomValue } from 'jotai'
import Modal from 'react-bootstrap/Modal'
import { imgCloseWhite } from 'core/images'

type Props = {
  isModalShowing: boolean
  onClose?: () => void
}

export function ForgotPasswordModal({ isModalShowing, onClose }: Props) {
  return (
    <Modal size="xl" show={isModalShowing} onHide={onClose} centered>
      <ForgotPasswordModalContent onClose={onClose} />
    </Modal>
  )
}

type ForgotPasswordModalContentProps = {
  onClose?: () => void
}

function ForgotPasswordModalContent({
  onClose
}: ForgotPasswordModalContentProps) {
  const authService = useAtomValue(authServiceAtom)

  const initialValues: ForgotPasswordData = {
    email: ''
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      await authService.forgotPassword({
        forgotPasswordData: values,
        onSuccess: () => {
          onClose?.()
        }
      })
    },
    validate: (values) => {
      const error = {}

      if (values.email.length === 0) {
        error['email'] = 'Please provide an email'
      }

      return error
    }
  })

  return (
    <form className="ForgotPassword" onSubmit={formik.handleSubmit}>
      <div className="header">
        <h3>Forgot your password?</h3>
        {onClose && (
          <button
            type="button"
            className="closeLoginModal"
            data-dismiss="modal"
            onClick={onClose}
          >
            <img src={imgCloseWhite} alt="Close login modal" />
          </button>
        )}
      </div>

      <div className="forgotPasswordForm">
        <input
          type="email"
          id="user_email_detail"
          name="email"
          placeholder="Email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />

        {formik.errors.email && (
          <span className="errorMessage">{formik.errors.email}</span>
        )}

        <input
          className="submitBtn"
          id="loginDetail"
          type="submit"
          name="loginDetail"
          value="Submit!"
        />
      </div>
    </form>
  )
}
