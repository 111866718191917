import { LoadingView, RichText } from 'components'
import { useAtomValue } from 'jotai'
import { scienceTabContentAtom } from 'pages/resource_room/core/atoms'
import { Suspense } from 'react'

export function ScienceTab() {
  return (
    <Suspense fallback={<LoadingView />}>
      <ScienceTabContent />
    </Suspense>
  )
}

function ScienceTabContent() {
  const content = useAtomValue(scienceTabContentAtom)

  return (
    <div>
      <RichText content={content?.pageText?.json} />
    </div>
  )
}
