import { Pagination } from 'components'
import {
  BasicFilteredPostcardCollection,
  BasicFilteredToolCollection
} from 'data'
import { ReactNode } from 'react'

type Props = {
  data?: BasicFilteredPostcardCollection | BasicFilteredToolCollection | null
  title?: string | null
  numberOfPages: number
  currentPage: number
  showHeader?: boolean
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  buildItem: (data: any) => ReactNode
}

/**
 * Renders a component intended to show a list of postcards with built in
 * pagination.
 *
 * @param data list of item to show in the list.
 * @param title text to show above the list . If [showHeader] is false, this
 * is ignored.
 * @param numberOfPages total number of pages this component has.
 * @param currentPage index of the currently visible page. Starts at 1.
 * @param showHeader controls whether to show a header above the list of
 * postcards.
 * @param setCurrentPage dispatch function that sets the value of [currentPage].
 * This comes from a useState hook.
 * @param buildItem function that builds the content of each position in the list.
 */
export function FilteredDataView({
  data,
  title,
  numberOfPages,
  currentPage,
  showHeader = true,
  setCurrentPage,
  buildItem
}: Props) {
  let totalOfItems = data?.total ?? 0

  return (
    <div className="FilteredDataView container">
      {showHeader && (
        <section>
          <h2 className="totalResults">
            Total {totalOfItems} results for... {title}
          </h2>
        </section>
      )}

      <section className="row row-eq-height">
        {totalOfItems === 0 ? (
          <div className="row">
            <h4 className="noResults">No results found</h4>
          </div>
        ) : (
          data?.items.map((data) => {
            if (!data) return <></>

            return (
              <div key={data.sys.id} className="col-md-4 col-sm-6">
                {buildItem(data)}
              </div>
            )
          })
        )}
      </section>

      {totalOfItems > 0 && (
        <section>
          <Pagination
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </section>
      )}
    </div>
  )
}
