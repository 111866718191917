import { loadStripe } from '@stripe/stripe-js'
import { p4pApiAtom, contentfulApiAtom } from 'data'
import { atom } from 'jotai'
import { atomsWithQuery } from 'jotai-tanstack-query'
import { Subscription } from './types'

export const stripeAtom = atom(() => {
  return loadStripe(process.env.REACT_APP_STRIPE_KEY!).then((stripe) => {
    if (!stripe) throw Error('Failed to load stripe')
    return stripe
  })
})

function subscriptionBannerKey(subscriptionBannerId: string) {
  return ['subscriptionBannerAtom', subscriptionBannerId]
}

export const [subscriptionBannerAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const subscriptionBannerId =
    process.env.REACT_APP_CONTENTFUL_SUBSCRIPTION_BANNER!

  return {
    queryKey: subscriptionBannerKey(subscriptionBannerId),
    queryFn: async () => {
      const response = await api.getSubscriptionBanner({
        id: subscriptionBannerId
      })

      return response.pageModule
    }
  }
})

function subscriptionOptionsKey() {
  return ['subscriptionBannerAtom']
}

export const [subscriptionOptionsAtom] = atomsWithQuery<Subscription[]>(
  (get) => {
    const api = get(p4pApiAtom)

    return {
      queryKey: subscriptionOptionsKey(),
      queryFn: async () => {
        const response = await api.getAllSubscriptionOptions()

        if (response.status === 200 && response.data['subscriptions']) {
          return response.data['subscriptions']
        }

        return []
      }
    }
  }
)

function subscriptionThankYouKey(subscriptionThankYou: string) {
  return ['subscriptionThankYouAtom', subscriptionThankYou]
}

export const [subscriptionThankYouAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const subscriptionThankYou =
    process.env.REACT_APP_CONTENTFUL_SUBSCRIPTION_THANK_YOU!

  return {
    queryKey: subscriptionThankYouKey(subscriptionThankYou),
    queryFn: async () => {
      const response = await api.getSubscriptionThankYou({
        id: subscriptionThankYou
      })

      return response.postcardPage
    }
  }
})
