import { AssetRenderer, RichText } from 'components'
import { AboutPageModule } from 'data'

type Props = {
  module?: AboutPageModule | null
}

export function AboutPageContentHowSection({ module }: Props) {
  return (
    <div className="row">
      <div className="col-md-6">
        <RichText
          textClassName="title"
          content={module?.moduleText1?.json}
          textColor={module?.contentTextColor?.value}
        />
      </div>
      <div className="col-md-6">
        {module?.moduleImagesCollection?.items.map((image) => (
          <AssetRenderer key={image?.sys.id} asset={image} />
        ))}
      </div>
    </div>
  )
}
