import { useAtomValue } from 'jotai'
import { LoadingView, RichText } from 'components'
import { legalDocumentAtom, legalDocumentIdAtom } from '../core/atoms'
import { Suspense } from 'react'
import { OverrideAtom } from 'utils/jotai'

type Props = {
  id: string
}

export function LegalDocumentPage({ id }: Props) {
  return (
    <Suspense fallback={<LoadingView />}>
      <OverrideAtom atom={legalDocumentIdAtom} value={id}>
        <LegalDocumentPageContent />
      </OverrideAtom>
    </Suspense>
  )
}

function LegalDocumentPageContent() {
  const content = useAtomValue(legalDocumentAtom)

  return (
    <div
      className="LegalDocumentPage"
      style={{ backgroundColor: content?.backgroundColor?.value ?? '' }}
    >
      <div className="container">
        <h1
          className="title"
          style={{ color: content?.titleTextColor?.value ?? '' }}
        >
          {content?.title}
        </h1>

        <RichText
          content={content?.moduleText1?.json}
          textColor={content?.contentTextColor?.value ?? ''}
        />
      </div>
    </div>
  )
}
