import { User } from 'pages/auth'
import { RichText } from 'components'
import { getChildLabel } from 'core/grades'
import { BasicAtThisAge, BasicIdea } from 'data'
import { useMemo } from 'react'
import { Accordion } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { parseChildIdeaId } from '../core/utils'
import { useLocationHash } from 'utils/hooks/use_location_hash'

type Props = {
  currentUser: User
  atThisAge?: Array<BasicAtThisAge | null>
  ideas?: Array<BasicIdea | null>
}

export function PostcardDetailsSupportYourChild({
  currentUser,
  atThisAge,
  ideas
}: Props) {
  const body = useMemo(() => {
    const sortedGrades = Object.values(currentUser.grades ?? []).sort((a, b) =>
      a > b ? 1 : -1
    )

    return sortedGrades.map((grade) => (
      <SupportYourChildItem
        key={grade}
        grade={grade}
        atThisAge={atThisAge}
        ideas={ideas}
      />
    ))
  }, [atThisAge, currentUser.grades, ideas])

  return (
    <section className="PostcardDetailsSupportYourChildSection">{body}</section>
  )
}

type SupportYourChildItemProps = {
  grade: number
  atThisAge?: Array<BasicAtThisAge | null>
  ideas?: Array<BasicIdea | null>
}

function SupportYourChildItem({
  grade,
  atThisAge,
  ideas
}: SupportYourChildItemProps) {
  const hash = useLocationHash()

  const defaultActiveKey = useMemo<string | undefined>(() => {
    let key

    if (hash) {
      ideas?.forEach((idea) => {
        if (idea) {
          const ideaId = parseChildIdeaId(grade, idea)

          if (ideaId === hash) {
            key = idea.sys.id
            return
          }
        }
      })
    }

    return key
  }, [grade, hash, ideas])

  const content = useMemo(() => {
    const atThisAgeContent = atThisAge?.find((item) =>
      item?.gradeCollection?.items.find(
        (itemGrade) => itemGrade?.grade === grade
      )
    )

    const ideasContent = ideas?.filter((item) => {
      const filtered = item?.ideaForGradesCollection?.items.filter(
        (itemGrade) => itemGrade?.grade === grade
      )

      if (!filtered || filtered.length === 0) return undefined

      return filtered
    })

    if (!atThisAgeContent && (!ideasContent || ideasContent.length === 0)) {
      if (grade < 0) {
        return (
          <Link
            to={`/gradelist/${process.env.REACT_APP_CONTENTFUL_GRADE_PRE_K_ID}`}
          >
            Go to Pre-K age topics
          </Link>
        )
      }

      if (grade < 6) {
        return (
          <Link
            to={`/gradelist/${process.env.REACT_APP_CONTENTFUL_GRADE_ELEMENTARY_ID}`}
          >
            Go to Elementary age topics
          </Link>
        )
      }

      return (
        <Link
          to={`/gradelist/${process.env.REACT_APP_CONTENTFUL_GRADE_ADOLESCENT_ID}`}
        >
          Go to Adolescent age topics
        </Link>
      )
    }

    return (
      <>
        {atThisAgeContent && (
          <RichText content={atThisAgeContent.atThisAge?.json} />
        )}

        {ideasContent && ideasContent.length > 0 && (
          <Accordion defaultActiveKey={defaultActiveKey}>
            {ideasContent.map((idea, index) => {
              if (!idea) return <></>

              const ideaId = parseChildIdeaId(grade, idea)

              return (
                <Accordion.Item
                  key={idea.sys.id}
                  id={ideaId}
                  eventKey={idea.sys.id}
                >
                  <Accordion.Header>
                    {index + 1}. {idea.ideaTitle}
                  </Accordion.Header>
                  <Accordion.Body>
                    <RichText content={idea.idea?.json} />
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>
        )}
      </>
    )
  }, [atThisAge, defaultActiveKey, grade, ideas])

  return (
    <div>
      <h3 className="sectionHeader">SUPPORTING {getChildLabel(grade)}</h3>

      {content}
    </div>
  )
}
