import { useEffect, useMemo, useState } from 'react'
import { useAtomValue } from 'jotai'
import { welcomeKitAtom } from 'pages/resource_room/core/atoms'
import { SummaryTab } from './tabs/summary/summary_tab'
import { WelcomeTab } from './tabs/welcome_tab'
import { ScienceTab } from './tabs/science_tab'
import { ConnectionTab } from './tabs/connection_tab'
import { EmpowermentTab } from './tabs/empowerment_tab'
import { ParentSelfCareTab } from './tabs/parent_self_care_tab'
import { CustomTooltip } from 'components'
import { cn } from 'utils/cn'
import { ParentQuizResults } from 'pages/quiz/core/types'
import { currentUserAtom } from 'pages/auth'
import { KitTab } from 'pages/resource_room/core/types'
import { PageModuleSale } from 'components/page_module_sale/page_module_sale'

type Props = {
  results: ParentQuizResults
  isQuizResults?: boolean
}

export function WelcomeKitContent({ results, isQuizResults }: Props) {
  const currentUser = useAtomValue(currentUserAtom)

  const showSubscriptionCTA =
    !isQuizResults && (!currentUser || !currentUser.has_active_subscription)

  const welcomeKit = useAtomValue(welcomeKitAtom)

  const [currentTab, setCurrentTab] = useState('')
  const [tabs, setTabs] = useState<string[]>([])

  const content = useMemo<Array<KitTab | undefined> | undefined>(
    () =>
      welcomeKit?.welcomeKitPagesCollection?.items.map((item) => {
        if (!item) return undefined

        const tab = item?.pageTitle?.toLocaleLowerCase().replace(' ', '-')

        if (!tab) return undefined

        // If the current item is not yet in the list of tabs from our state,
        // we have to add it
        if (!tabs.includes(tab)) {
          setTabs([...tabs, tab])
        }

        let bodyContent
        let tabDisabled = !currentUser?.has_active_subscription ?? false

        if (isQuizResults) {
          if (tab.includes('summary')) {
            bodyContent = (
              <SummaryTab
                pageContent={item}
                results={results}
                isQuizResults={isQuizResults}
              />
            )
          } else {
            tabDisabled = true
          }
        } else {
          if (tab.includes('welcome')) {
            bodyContent = <WelcomeTab />
          } else if (tab.includes('science')) {
            bodyContent = <ScienceTab />
          } else if (tab.includes('summary')) {
            bodyContent = (
              <SummaryTab
                pageContent={item}
                results={results}
                isQuizResults={false}
              />
            )
          } else if (tab.includes('connection')) {
            bodyContent = <ConnectionTab results={results} />
          } else if (tab.includes('empowerment')) {
            bodyContent = <EmpowermentTab results={results} />
          } else if (tab.includes('parent')) {
            bodyContent = <ParentSelfCareTab results={results} />
          }
        }

        if (tabDisabled) {
          const tabContent = (
            <li className={cn('nav-item', 'disabled')}>
              <button>{item?.pageTitle}</button>
            </li>
          )

          return {
            key: tab,
            tab:
              tab === 'snapshot-summary' ? (
                tabContent
              ) : (
                <CustomTooltip
                  message="Subscribe below to unlock your full Welcome Kit!"
                  placement="bottom"
                  key={tab}
                  children={tabContent}
                />
              ),
            body: bodyContent,
            enabled: false
          }
        }

        return {
          key: tab,
          tab: <button>{item?.pageTitle}</button>,
          body: bodyContent,
          enabled: true
        }
      }),
    [
      welcomeKit?.welcomeKitPagesCollection?.items,
      tabs,
      isQuizResults,
      results,
      currentUser?.has_active_subscription
    ]
  )

  useEffect(() => {
    if (currentTab.length === 0) {
      let summaryIndex = -1

      tabs.forEach((item, index) => {
        if (item.includes('summary')) {
          summaryIndex = index
          return
        }
      })

      if (summaryIndex >= 0) {
        setCurrentTab(tabs[summaryIndex])
      }
    }
  }, [currentTab.length, tabs])

  return (
    <section className="WelcomeKitContent">
      <div className="container">
        <ul className="nav nav-tabs">
          {content?.map((item) => (
            <li
              key={item?.key}
              className={cn(
                'nav-item',
                currentTab === item?.key ? 'active' : ''
              )}
              onClick={() => {
                if (item?.enabled) {
                  setCurrentTab(item!.key)
                }
              }}
            >
              {item?.tab}
            </li>
          ))}
        </ul>

        <div className="tab-content">
          {content?.filter((item) => item?.key === currentTab)[0]?.body}
        </div>

        {showSubscriptionCTA && <PageModuleSale />}
      </div>
    </section>
  )
}
