import { Link } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { contentCategoryCollectionAtom } from './atoms'
import { GetInTouchModal } from 'components'
import { useState } from 'react'

/**
 * Renders a footer containing all info like postcard categories, trade mark,
 * links for legal documents and a link to get in touch with us.
 */
export function Footer() {
  const contentCategories = useAtomValue(contentCategoryCollectionAtom)

  const [showingModal, setShowingModal] = useState(false)

  const handleShowModal = () => setShowingModal(true)
  const handleCloseModal = () => setShowingModal(false)

  return (
    <>
      <footer>
        <div className="container">
          <ul>
            {contentCategories?.map((item) => {
              return (
                <li key={item?.sys.id}>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={`/catList/${item?.sys.id}`}
                  >
                    {item?.contentType}
                  </Link>
                </li>
              )
            })}
          </ul>

          <p>Copyright 2023 Postcards for Parents. All rights reserved.</p>

          <p>
            See our{' '}
            <Link style={{ color: 'blue' }} to={'/privacy-policy'}>
              Privacy policy
            </Link>
            {' and '}
            <Link style={{ color: 'blue' }} to={'/terms-of-use'}>
              Terms of Use
            </Link>
            . Or,{' '}
            <span className="link" onClick={handleShowModal}>
              drop us a line.
            </span>
          </p>
        </div>
      </footer>

      <GetInTouchModal
        isModalShowing={showingModal}
        onClose={handleCloseModal}
      />
    </>
  )
}
export default Footer
