import { Document } from '@contentful/rich-text-types'
import { AssetRenderer, RichText } from 'components'
import { AboutPageModule, BasicAsset } from 'data'

type Props = {
  module?: AboutPageModule | null
}

export function AboutPageContentWhatSection({ module }: Props) {
  return (
    <div className="AboutPageContentWhatSection">
      <RichText
        textClassName="title"
        content={module?.moduleText1?.json}
        textColor={module?.contentTextColor?.value}
      />

      <div className="row">
        <div className="col-sm-6 col-lg-3">
          <SectionItem
            content={module?.moduleText2?.json}
            image={module?.moduleImagesCollection?.items[0]}
          />
        </div>
        <div className="col-sm-6 col-lg-3">
          <SectionItem
            content={module?.moduleText3?.json}
            image={module?.moduleImagesCollection?.items[1]}
          />
        </div>
        <div className="col-sm-6 col-lg-3">
          <SectionItem
            content={module?.moduleText4?.json}
            image={module?.moduleImagesCollection?.items[2]}
          />
        </div>
        <div className="col-sm-6 col-lg-3">
          <SectionItem
            content={module?.moduleTest5?.json}
            image={module?.moduleImagesCollection?.items[3]}
          />
        </div>
      </div>
    </div>
  )
}

type SectionItemProps = {
  image?: BasicAsset | null
  content?: Document
}

function SectionItem({ image, content }: SectionItemProps) {
  return (
    <div className="SectionItem">
      <AssetRenderer asset={image} />

      <RichText content={content} />
    </div>
  )
}
