import { CustomText, RichText } from 'components'
import { BasicPageModule } from 'data'

type Props = {
  moduleData: BasicPageModule
}

export function PageModuleText({ moduleData }: Props) {
  const title = moduleData.title

  return (
    <>
      <CustomText
        textStyle="headerLarge"
        textColor={moduleData.titleTextColor?.value}
      >
        {title}
      </CustomText>

      <p />

      <RichText
        content={moduleData.moduleText1?.json}
        title={title}
        textColor={moduleData.contentTextColor?.value}
      />

      {moduleData.moduleText2 && (
        <RichText
          content={moduleData.moduleText2?.json}
          textColor={moduleData.contentTextColor?.value}
        />
      )}

      {moduleData.moduleText3 && (
        <RichText
          content={moduleData.moduleText3?.json}
          textColor={moduleData.contentTextColor?.value}
        />
      )}

      {moduleData.moduleText4 && (
        <RichText
          content={moduleData.moduleText4?.json}
          textColor={moduleData.contentTextColor?.value}
        />
      )}

      {moduleData.moduleTest5 && (
        <RichText
          content={moduleData.moduleTest5?.json}
          textColor={moduleData.contentTextColor?.value}
        />
      )}

      {moduleData.moduleText6 && (
        <RichText
          content={moduleData.moduleText6?.json}
          textColor={moduleData.contentTextColor?.value}
        />
      )}

      {moduleData.moduleTest7 && (
        <RichText
          content={moduleData.moduleTest7?.json}
          textColor={moduleData.contentTextColor?.value}
        />
      )}
    </>
  )
}
