import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  ChartData
} from 'chart.js'
import { useState } from 'react'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, CategoryScale, LinearScale)

type Props = {
  data: ChartData<'doughnut', number[], string>
  chartColor: string
  targetZone: number[]
}

export function GaugeChart({ data, chartColor, targetZone }: Props) {
  const [gaugeChartData, setGaugeChartData] = useState<
    ChartData<'doughnut', number[], string>
  >({ datasets: [{ data: [0] }] })

  return (
    <div className="GaugeChart">
      <Doughnut
        data={{
          datasets: [
            {
              data: targetZone,
              backgroundColor: ['transparent', chartColor],
              borderColor: 'transparent'
            }
          ]
        }}
        options={{
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          animation: false,
          rotation: -135,
          circumference: 270,
          cutout: '98%',
          maintainAspectRatio: true,
          responsive: true,
          events: []
        }}
      />
      <Doughnut
        data={{
          datasets: [
            {
              data: [100],
              backgroundColor: ['lightgrey'],
              borderColor: 'transparent'
            }
          ]
        }}
        options={{
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          animation: false,
          rotation: -135,
          circumference: 270,
          cutout: '60%',
          maintainAspectRatio: true,
          responsive: true,
          events: []
        }}
      />
      <Doughnut
        className="chart"
        data={gaugeChartData}
        options={{
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          animation: {
            onComplete: () => {
              setGaugeChartData(data)
            }
          },
          rotation: -135,
          circumference: 270,
          cutout: '60%',
          maintainAspectRatio: true,
          responsive: true,
          events: []
        }}
      />
    </div>
  )
}
