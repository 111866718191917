import { AssetRenderer } from 'components'
import { BasicUsefulTools } from 'data/contentful/api.graphql'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

type Props = {
  usefulTools?: Array<BasicUsefulTools | null>
}

export function PostcardDetailsUsefulTools({ usefulTools }: Props) {
  if (!usefulTools || usefulTools.length === 0) return <></>

  return (
    <div className="PostcardDetailsUsefulTools">
      <h3 className="sectionHeader">USEFUL TOOLS</h3>

      <Row>
        {usefulTools.map((tool) => (
          <Col key={tool?.sys.id}>
            <Link to={`/toolkitDetails/${tool?.sys.id}`}>
              <AssetRenderer asset={tool?.toolIcon} />
              <p>{tool?.name}</p>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  )
}
