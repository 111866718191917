import { FilteredDataView, PostcardPreview } from 'components'
import { useAtom, useAtomValue } from 'jotai'
import { useState, useEffect } from 'react'
import { gradeFilterDataAtom, postcardsByGradeAtom } from '../core/atoms'

type Props = {
  recordsPerPage: number
  showGrade?: boolean
}

export function PostcardsPageRecentPostcards({
  recordsPerPage,
  showGrade
}: Props) {
  const [gradeFilterData, setGradeFilterData] = useAtom(gradeFilterDataAtom)
  const postcards = useAtomValue(postcardsByGradeAtom)

  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setGradeFilterData({
      ...gradeFilterData!,
      skip: (currentPage - 1) * recordsPerPage
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const numberOfPages = Math.ceil(
    (postcards?.postcardCollection?.total ?? 0) / recordsPerPage
  )

  return (
    <FilteredDataView
      data={postcards?.postcardCollection}
      numberOfPages={numberOfPages}
      currentPage={currentPage}
      showHeader={false}
      setCurrentPage={setCurrentPage}
      buildItem={(data) => (
        <PostcardPreview
          postcard={data}
          showReadMore={false}
          showGrade={showGrade}
        />
      )}
    />
  )
}
