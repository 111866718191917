import { RichText } from 'components'
import { useAtomValue } from 'jotai'
import { postcardLoginBoxAtom, postcardSubscribeBoxAtom } from '../core/atoms'
import { PostcardDetailsUsefulTools } from './postcard_details_useful_tools'
import { BasicPostcardDetails } from 'data/contentful/api.graphql'
import { PostcardDetailsReflection } from './postcard_details_reflection'
import { PostcardDetailsSources } from './postcard_details_sources'
import { PostcardDetailsTags } from './postcard_details_tags'
import {
  ForgotPasswordModal,
  LoginModalContent,
  currentUserAtom
} from 'pages/auth'
import { User } from 'pages/auth'
import { productOfferDataAtom } from 'core/atoms'
import { Link } from 'react-router-dom'
import { PostcardDetailsSupportYourChild } from './postcard_details_support_your_child'
import { PostcardDetailsParentSelfCare } from './postcard_details_parent_self_care'
import { useState } from 'react'

type Props = {
  postcard: BasicPostcardDetails
}

export function PostcardDetailsBottomSection({ postcard }: Props) {
  const currentUser = useAtomValue(currentUserAtom)

  return (
    <div className="container">
      {currentUser ? (
        <PostcardDetailsLoggedInSection
          postcard={postcard}
          currentUser={currentUser}
        />
      ) : (
        <PostcardDetailsLoginSection />
      )}
    </div>
  )
}

function PostcardDetailsLoginSection() {
  const content = useAtomValue(postcardLoginBoxAtom)

  const [showingForgotPasswordModal, setShowingForgotPasswordModal] =
    useState(false)

  const handleShowForgotPasswordModal = () =>
    setShowingForgotPasswordModal(true)
  const handleCloseForgotPasswordModal = () =>
    setShowingForgotPasswordModal(false)

  return (
    <>
      <div className="PostcardDetailsLoginSection">
        <LoginModalContent
          header={<RichText content={content?.description?.json} />}
          onShowForgotPasswordClick={handleShowForgotPasswordModal}
        />
      </div>

      <ForgotPasswordModal
        isModalShowing={showingForgotPasswordModal}
        onClose={handleCloseForgotPasswordModal}
      />
    </>
  )
}

type PostcardDetailsLoggedInSectionProps = {
  postcard: BasicPostcardDetails
  currentUser: User
}

function PostcardDetailsLoggedInSection({
  postcard,
  currentUser
}: PostcardDetailsLoggedInSectionProps) {
  if (!currentUser.has_active_subscription) {
    return <PostcardDetailsLoggedInNoActiveSubscriptionSection />
  }

  const isParentSelfCarePostcard =
    postcard.contentType?.sys.id ===
    process.env.REACT_APP_CONTENTFUL_PARENT_SELF_CARE

  return (
    <div>
      {isParentSelfCarePostcard ? (
        <PostcardDetailsParentSelfCare
          content={postcard.supportingYourChild?.json}
          ideas={postcard.ideasCollection?.items}
        />
      ) : (
        <PostcardDetailsSupportYourChild
          currentUser={currentUser}
          atThisAge={postcard.atThisAgeLinkedCollection?.items}
          ideas={postcard.ideasCollection?.items}
        />
      )}

      <PostcardDetailsUsefulTools
        usefulTools={postcard?.usefulToolsCollection?.items}
      />

      <PostcardDetailsReflection reflection={postcard?.reflection} />

      <PostcardDetailsSources citations={postcard?.sourcesCollection?.items} />

      <PostcardDetailsTags tags={postcard?.tagsCollection?.items} />
    </div>
  )
}

function PostcardDetailsLoggedInNoActiveSubscriptionSection() {
  const content = useAtomValue(postcardSubscribeBoxAtom)
  const productOffer = useAtomValue(productOfferDataAtom)

  return (
    <div className="PostcardDetailsLoggedInNoActiveSubscriptionSection">
      <h3>{content?.name}</h3>

      <RichText content={content?.description?.json} />

      <div className="subscriptionOptions">
        <Link className="yearly" to={`/register?time=year`}>
          <span>Annual</span>
          <h4>{productOffer?.callOut}</h4>
          <span>{productOffer?.priceYearlyLabel}</span>
        </Link>

        <Link className="monthly" to={`/register?time=month`}>
          <span>Monthly</span>
          <h4>{productOffer?.callOut}</h4>
          <span>{productOffer?.priceMonthlyLabel}</span>
        </Link>
      </div>
    </div>
  )
}
