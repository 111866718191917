import { CustomButton } from 'components'
import { BasicEmailWrapper, p4pApiAtom } from 'data'
import { useAtomValue } from 'jotai'
import { currentUserAtom } from 'pages/auth'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {
  emailWrapper: BasicEmailWrapper
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * Renders each item from a list of email wrappers.
 *
 * @param emailWrapper the data for the wrapper to be rendered.
 * @param setIsLoading dispatch function that sets whether there is an
 * async process running or not. This comes from a useState hook.
 */
export function EmailWrapperItem({ emailWrapper, setIsLoading }: Props) {
  const api = useAtomValue(p4pApiAtom)

  const currentUser = useAtomValue(currentUserAtom)

  async function handleSendTestPostcard() {
    setIsLoading(true)

    const response = await api.sendTestPostcard({
      email_wrapper_id: emailWrapper.sys.id
    })

    if (response?.status === 200 && response?.data.success) {
      toast.success('Email sent successfully')
    } else {
      toast.error('Error while sending email')
    }

    setIsLoading(false)
  }

  return (
    <div className="EmailWrapperItem">
      <h4 className="title">{emailWrapper.emailSequence}</h4>

      <div className="info">
        <div className="item">
          <h5>Month</h5>
          <h4 className="month">{emailWrapper.month?.monthName}</h4>
        </div>
        <div className="item">
          <h5>Order in month</h5>
          <h4 className="month">{emailWrapper.orderInMonth}</h4>
        </div>
        <div className="item">
          <h5>Content category</h5>
          <h4 className="month">{emailWrapper.contentCategory?.contentType}</h4>
        </div>
      </div>

      <h5 className="gradesLabel">Send to grades</h5>
      <div className="grades row">
        {emailWrapper.sendToGradesCollection?.items.map((grade) => (
          <h4 key={grade?.sys.id} className="col-4 col-sm-4 col-md-2">
            {grade?.gradeTitle}
          </h4>
        ))}
      </div>

      <h5 className="postcardLabel">Postcard</h5>
      <Link
        className="viewPostcard"
        to={`/details/${emailWrapper.postcard?.sys.id}`}
        target="_blank"
      >
        {emailWrapper.postcard?.title}
      </Link>

      <CustomButton onClick={handleSendTestPostcard}>
        Send test postcard to {currentUser?.email}
      </CustomButton>
    </div>
  )
}
