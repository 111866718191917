import { Document } from '@contentful/rich-text-types'
import { AssetRenderer, RichText } from 'components'
import { AboutPageModule, BasicAsset } from 'data'

type Props = {
  module?: AboutPageModule | null
}

export function AboutPageContentTeamSection({ module }: Props) {
  return (
    <div className="AboutPageContentTeamSection">
      <RichText
        textClassName="title"
        content={module?.moduleText1?.json}
        textColor={module?.contentTextColor?.value}
      />

      <div className="row">
        {module?.moduleImagesCollection?.items.map((item, index) => {
          // This is admittedly a very hacky way of addressing this, but it's
          // how we're forced to do it because of how the Contentful data is set up.

          // Module texts start at index 1, but the first position contains a little
          // description, that's why we're adding +2 below.
          const normalizedIndex = index + 2

          // When this content was first created, there was a typo on the positions below,
          // that's the reason we have to address those two separately.
          const textKey =
            normalizedIndex === 5 || normalizedIndex === 7
              ? 'moduleTest'
              : 'moduleText'

          return (
            <div className="col-md-6">
              <SectionItem
                content={module?.[`${textKey}${normalizedIndex}`]?.json}
                image={item}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

type SectionItemProps = {
  image?: BasicAsset | null
  content?: Document
}

function SectionItem({ image, content }: SectionItemProps) {
  return (
    <div className="SectionItem row">
      <div className="col-lg-6">
        <AssetRenderer asset={image} />
      </div>
      <div className="col-lg-6">
        <RichText content={content} />
      </div>
    </div>
  )
}
