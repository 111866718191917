import { useAtomValue } from 'jotai'
import { useParams } from 'react-router-dom'
import { OverrideAtom } from 'utils/jotai'
import { postcardDetailsAtom, postcardIdAtom } from '../core/atoms'
import { PostcardDetailsBottomSection } from '../components/postcard_details_bottom_section'
import { RecentPostcardsSection } from 'components'
import { PostcardDetailsContent } from '../components/postcard_details_content'
import { registerGoogleAnalyticsCustomEvent } from 'utils/google_analytics'
import { useEffect } from 'react'

export function PostcardDetailsPage() {
  const params = useParams()

  return (
    <OverrideAtom atom={postcardIdAtom} value={params.id}>
      <PostcardDetailsPageContent />
    </OverrideAtom>
  )
}

function PostcardDetailsPageContent() {
  const postcardDetails = useAtomValue(postcardDetailsAtom)

  useEffect(() => {
    if (!postcardDetails || !postcardDetails.title) return

    registerGoogleAnalyticsCustomEvent('idea', postcardDetails.title)
  }, [postcardDetails])

  if (!postcardDetails) return <></>

  return (
    <div className="PostcardDetails container">
      <div className="row">
        <aside className="col-md-9">
          <PostcardDetailsContent postcard={postcardDetails} />

          <PostcardDetailsBottomSection postcard={postcardDetails} />
        </aside>

        <aside className="col-md-3">
          <RecentPostcardsSection />
        </aside>
      </div>
    </div>
  )
}
