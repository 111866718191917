import { Document } from '@contentful/rich-text-types'
import { RichText } from 'components'
import { BasicIdea } from 'data'
import { useMemo } from 'react'
import { Accordion } from 'react-bootstrap'
import { parseParentSelfCareIdeaId } from '../core/utils'
import { useLocationHash } from 'utils/hooks/use_location_hash'

type Props = {
  content?: Document | null
  ideas?: Array<BasicIdea | null>
}

export function PostcardDetailsParentSelfCare({ content, ideas }: Props) {
  const hash = useLocationHash()

  const defaultActiveKey = useMemo<string | undefined>(() => {
    let key

    if (hash) {
      ideas?.forEach((idea) => {
        if (idea) {
          const ideaId = parseParentSelfCareIdeaId(idea)

          if (ideaId === hash) {
            key = idea.sys.id
            return
          }
        }
      })
    }

    return key
  }, [hash, ideas])

  return (
    <section className="PostcardDetailsParentSelfCare">
      <h3 className="sectionHeader">SUPPORTING YOUR SELF</h3>

      <RichText content={content} />

      <Accordion defaultActiveKey={defaultActiveKey}>
        {ideas?.map((idea, index) => {
          if (!idea) return <></>

          const ideaId = parseParentSelfCareIdeaId(idea)

          return (
            <Accordion.Item
              key={idea.sys.id}
              id={ideaId}
              eventKey={idea.sys.id}
            >
              <Accordion.Header>
                {index + 1}. {idea.ideaTitle}
              </Accordion.Header>
              <Accordion.Body>
                <RichText content={idea.idea?.json} />
              </Accordion.Body>
            </Accordion.Item>
          )
        })}
      </Accordion>
    </section>
  )
}
