import { ReactNode } from 'react'
import { useWindowData } from 'utils/hooks/use_window_data'

type Props = {
  children: ReactNode
  totalAmountOfItems: number
  numberOfColumns: number
}

/**
 * Renders a responsive grid that changes the number of columns depending
 * on both [numberOfColumns] and the width of the window running the app.
 *
 * @param children list of components to render.
 * @param totalAmountOfItems total number of children this component will have.
 * @param numberOfColumns default number of columns to render. This is overwritten
 * to 1 on mobile. See {@link useWindowData} to learn more.
 */
export function ResponsiveGrid({
  children,
  totalAmountOfItems,
  numberOfColumns
}: Props) {
  const windowData = useWindowData()

  const style = resolveGridStyle(
    totalAmountOfItems,
    numberOfColumns,
    windowData.isMobile
  )

  return (
    <div className="ResponsiveGrid grid" style={style}>
      {children}
    </div>
  )
}

function resolveGridStyle(
  totalAmountOfItems: number,
  numberOfColumns: number,
  isMobile: boolean
) {
  // If running on mobile, we only want to override [numberOfColumns] and only
  // show 1 column.
  const normalizedNumberOfColumns = isMobile ? 1 : numberOfColumns

  const numberOfRows = Math.ceil(totalAmountOfItems / normalizedNumberOfColumns)

  return { gridTemplateRows: `repeat(${numberOfRows}, 1fr)` }
}
