export const imgCloseBlackSmaller =
  'https://postcardsforparents.com/images/close_black_smaller.webp'
export const imgCloseBlack =
  'https://postcardsforparents.com/images/close_black.webp'
export const imgCloseRed =
  'https://postcardsforparents.com/images/close_red.webp'
export const imgCloseWhite =
  'https://postcardsforparents.com/images/close_white.webp'
export const imgCloseWhiteSmaller =
  'https://postcardsforparents.com/images/close_white_smaller.webp'

export const imgLogo = 'https://postcardsforparents.com/images/logo.webp'
export const imgLogoCondensed =
  'https://postcardsforparents.com/images/logo_condensed.webp'

export const imgRightArrowBlack =
  'https://postcardsforparents.com/images/right_arrow_black.webp'
