import { contentfulApiAtom } from 'data'
import { atomsWithQuery } from 'jotai-tanstack-query'

function pageModuleSaleKey(moduleId: string) {
  return ['pageModuleSaleAtom', moduleId]
}

/**
 * Queries the content that should be shown for users containing promotional
 * text for subscription sale.
 * */
export const [pageModuleSaleAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)

  const homePageId = process.env.REACT_APP_CONTENTFUL_HOMEPAGE!
  const moduleId = process.env.REACT_APP_CONTENTFUL_PAGE_MODULE_SALE!

  return {
    queryKey: pageModuleSaleKey(moduleId),
    queryFn: async () => {
      const data = await api.getPageModule({
        pageId: homePageId,
        moduleId: moduleId
      })

      const items = data.newPage?.pageModulesCollection?.items

      if (items && items.length > 0) {
        return items[0]
      }

      return null
    }
  }
})
