import { User, currentUserAtom } from 'pages/auth'
import { grades } from 'core/grades'
import { p4pApiAtom } from 'data'
import { useFormik } from 'formik'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'

type Props = {
  isModalShowing: boolean
  onClose?: (reloadData: boolean) => void
}

export function EditAccountInfoModal({ isModalShowing, onClose }: Props) {
  const currentUser = useAtomValue(currentUserAtom)

  return (
    <Modal
      size="xl"
      show={isModalShowing}
      onHide={() => onClose?.(false)}
      centered
    >
      <EditAccountInfoModalContent
        currentUser={currentUser}
        onClose={onClose}
      />
    </Modal>
  )
}

type EditAccountInfoModalContentProps = {
  currentUser: User | null
  onClose?: (reloadData: boolean) => void
}

export function EditAccountInfoModalContent({
  currentUser,
  onClose
}: EditAccountInfoModalContentProps) {
  const api = useAtomValue(p4pApiAtom)

  const [formData, setFormData] = useState(buildInitialValues(currentUser))

  const formik = useFormik({
    initialValues: formData,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      await formik.validateForm(values)

      try {
        const response = await api.updateAccountInfo(formData)

        if (response.status !== 200) {
          toast.error('Error while processing request')
          return
        }

        toast.success('Account info updated successfully')
        onClose?.(true)
      } catch (e) {
        toast.error('Error while processing request')
        console.error(e)
      }
    },
    validate: (values) => {
      const error = {}

      if (!values.fname || values.fname.length === 0) {
        error['fname'] = 'Please provide a first name'
      }

      if (!values.lname || values.lname.length === 0) {
        error['lname'] = 'Please provide a last name'
      }

      if (!values.grades || Object.keys(values.grades).length === 0) {
        error['grades'] = 'Please select at least one grade'
      }

      return error
    }
  })

  function handleFormDataChanged(event) {
    let newValues = formData

    switch (event.target.name) {
      case 'fname':
        newValues = { ...newValues, fname: event.target.value }
        break

      case 'lname':
        newValues = { ...newValues, lname: event.target.value }
        break

      default:
        if (event.target.name.toString().includes('=')) {
          const grade = parseInt(event.target.name.split('=')[1])

          const grades = newValues.grades

          if (!grades) return

          const gradesIndex = grades.findIndex((item) => item === grade)

          if (gradesIndex >= 0) {
            grades.splice(gradesIndex, 1)
          } else {
            grades.push(grade)
          }

          grades.sort((a, b) => (a > b ? 1 : -1))

          newValues = { ...newValues, grades: grades }
        }
    }

    setFormData(newValues)

    formik.handleChange(event)
  }

  return (
    <div className="EditAccountInfoModal">
      <h3 className="levHeading">Edit your account info</h3>

      <form onSubmit={formik.handleSubmit}>
        <div className="userDataContainer">
          <input
            className={`userData ${formik.errors.fname ? 'error' : ''}`}
            type="text"
            name="fname"
            placeholder="First Name"
            onChange={handleFormDataChanged}
            value={formData.fname}
          />
          {formik.errors.fname && (
            <span className="errorMessage">{formik.errors.fname}</span>
          )}

          <input
            className={`userData ${formik.errors.lname ? 'error' : ''}`}
            type="text"
            name="lname"
            placeholder="Last Name"
            onChange={handleFormDataChanged}
            value={formData.lname}
          />
          {formik.errors.lname && (
            <span className="errorMessage">{formik.errors.lname}</span>
          )}
        </div>

        <p className="gradeListHeader">Your child's grade(s)</p>

        <div>
          {grades.map((grade) => (
            <div key={grade.key} className="gradeListContainer">
              <h4>{grade.gradeLabel}</h4>
              <div
                className={`gradeList ${formik.errors.grades ? 'error' : ''}`}
              >
                {grade.items.map((gradeItem) => {
                  return (
                    <label key={gradeItem.key}>
                      <input
                        type="checkbox"
                        name={`grade=${gradeItem.key}`}
                        onChange={handleFormDataChanged}
                        checked={formData.grades?.includes(gradeItem.key)}
                      />
                      <span className="checkmark"></span>
                      <span className="grade">{gradeItem.label}</span>
                    </label>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
        {formik.errors.grades && (
          <span className="errorMessage">{formik.errors.grades}</span>
        )}

        <div className="actions">
          <button
            className="cancel"
            type="button"
            onClick={() => onClose?.(false)}
          >
            Cancel
          </button>

          <button className="submit" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

function buildInitialValues(currentUser: User | null) {
  if (!currentUser) return {}

  const name = currentUser?.name
  const fname = name?.substring(0, name?.indexOf(' '))
  const lname = name?.substring(name?.indexOf(' ') + 1)

  return {
    fname,
    lname,
    grades: currentUser.grades
  }
}
