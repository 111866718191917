type Props = {
  value?: number
  minValue?: number
  maxValue?: number
  onSliderValueChanged?: (value: number) => void
  onSliderMouseUp?: (value: number) => void
}

/**
 * Renders the default slider for the application.
 *
 * @param value current value selected in the slider. This has
 * to be >= [minValue] and <= [maxValue].
 * @param minValue value for the slider's minimum range.
 * @param maxValue value for the slider's maximum range.
 * @param onSliderValueChanged callback function triggered whenever
 * the slider's current value changes.
 * @param onSliderMouseUp callback function triggered whenever user
 * releases their mouse after interacting with the slider.
 */
export function CustomSlider({
  value,
  minValue = 1,
  maxValue = 7,
  onSliderValueChanged,
  onSliderMouseUp
}: Props) {
  return (
    <input
      className="CustomSlider"
      type="range"
      min={minValue}
      max={maxValue}
      value={value}
      onChange={(event) => {
        const sliderValue = (event.target as HTMLInputElement).value

        if (sliderValue) {
          onSliderValueChanged?.(parseInt(sliderValue))
        }
      }}
      onMouseUp={(event) => {
        const sliderValue = (event.target as HTMLInputElement).value

        if (sliderValue) {
          onSliderMouseUp?.(parseInt(sliderValue))
        }
      }}
    />
  )
}
