import {
  BasicWelcomeKitContentChunk,
  p4pApiAtom,
  contentfulApiAtom
} from 'data'
import { CustomerDetails, UserDashboardData } from 'data/p4p_api/types'
import { atom } from 'jotai'
import { atomsWithQuery } from 'jotai-tanstack-query'
import { KidQuizResults, ParentQuizResults } from 'pages/quiz'

function quizResultsContentKey(quizResultsContentId: string) {
  return ['quizResultsContentAtom', quizResultsContentId]
}

export const [quizResultsContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const quizResultsContentId = process.env.REACT_APP_CONTENTFUL_QUIZ_RESULTS!

  return {
    queryKey: quizResultsContentKey(quizResultsContentId),
    queryFn: async () => {
      const response = await api.getQuizResultsContent({
        id: quizResultsContentId
      })

      return response.pageModule
    }
  }
})

function welcomeKitKey(welcomeKitId: string) {
  return ['welcomeKitAtom', welcomeKitId]
}

export const [welcomeKitAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const welcomeKitId = process.env.REACT_APP_CONTENTFUL_WELCOME_KIT!

  return {
    queryKey: welcomeKitKey(welcomeKitId),
    queryFn: async () => {
      const response = await api.getWelcomeKit({
        id: welcomeKitId
      })

      return response.welcomeKit
    }
  }
})

export const parentPersonaIdAtom = atom<string | undefined>(undefined)

function parentPersonaKey(parentPersonaId?: string) {
  return ['parentPersonaAtom', parentPersonaId]
}

export const [parentPersonaAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const parentPersonaId = get(parentPersonaIdAtom)

  return {
    queryKey: parentPersonaKey(parentPersonaId),
    queryFn: async () => {
      if (!parentPersonaId) return undefined

      const response = await api.getWelcomeKitContentChunk({
        id: parentPersonaId
      })

      return response.welcomeKitPersonalFeedback
    }
  }
})

function parentQuizResultsKey() {
  return ['parentQuizResultsAtom']
}

export const [parentQuizResultsAtom] = atomsWithQuery<ParentQuizResults | null>(
  (get) => {
    const api = get(p4pApiAtom)

    return {
      queryKey: parentQuizResultsKey(),
      queryFn: async () => {
        try {
          const response = await api.getParentQuizResults()

          if (response.status === 200 && response.data) {
            return response.data
          }
        } catch (e) {
          console.error(e)
        }

        return null
      }
    }
  }
)

function welcomeTabContentKey(welcomeTabId: string) {
  return ['welcomeTabContentAtom', welcomeTabId]
}

export const [welcomeTabContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const welcomeTabId = process.env.REACT_APP_CONTENTFUL_WELCOME_TAB!

  return {
    queryKey: welcomeTabContentKey(welcomeTabId),
    queryFn: async () => {
      const response = await api.getWelcomeTabContent({
        id: welcomeTabId
      })

      return response.welcomeKitPage
    }
  }
})

function scienceTabContentKey(scienceTabId: string) {
  return ['scienceTabContentAtom', scienceTabId]
}

export const [scienceTabContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const scienceTabId = process.env.REACT_APP_CONTENTFUL_SCIENCE_TAB!

  return {
    queryKey: scienceTabContentKey(scienceTabId),
    queryFn: async () => {
      const response = await api.getScienceTabContent({
        id: scienceTabId
      })

      return response.welcomeKitPage
    }
  }
})

function connectionTabContentKey(connectionTabId: string) {
  return ['connectionTabContentAtom', connectionTabId]
}

export const [connectionTabContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const connectionTabId = process.env.REACT_APP_CONTENTFUL_CONNECTION_TAB!

  return {
    queryKey: connectionTabContentKey(connectionTabId),
    queryFn: async () => {
      const response = await api.getConnectionTabContent({
        id: connectionTabId
      })

      return response.welcomeKitPage
    }
  }
})

export const connectionTabAdditionalContentIdsAtom = atom<string[]>([])

function connectionTabAdditionalContentKey(
  connectionTabAdditionalContentIds: string[]
) {
  return [
    'connectionTabAdditionalContentAtom',
    [...connectionTabAdditionalContentIds]
  ]
}

export const [connectionTabAdditionalContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const connectionTabAdditionalContentIds = get(
    connectionTabAdditionalContentIdsAtom
  )

  return {
    queryKey: connectionTabAdditionalContentKey(
      connectionTabAdditionalContentIds
    ),
    queryFn: async () => {
      if (connectionTabAdditionalContentIds.length > 0) {
        const additionalContent: BasicWelcomeKitContentChunk[] = []

        for (let i = 0; i < connectionTabAdditionalContentIds.length; i++) {
          const response = await api.getWelcomeKitContentChunk({
            id: connectionTabAdditionalContentIds[i]
          })

          if (response.welcomeKitPersonalFeedback) {
            additionalContent.push(response.welcomeKitPersonalFeedback)
          }
        }

        return additionalContent
      }

      return []
    }
  }
})

function empowermentTabContentKey(empowermentTabId: string) {
  return ['empowermentTabContentAtom', empowermentTabId]
}

export const [empowermentTabContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const empowermentTabId = process.env.REACT_APP_CONTENTFUL_EMPOWERMENT_TAB!

  return {
    queryKey: empowermentTabContentKey(empowermentTabId),
    queryFn: async () => {
      const response = await api.getEmpowermentTabContent({
        id: empowermentTabId
      })

      return response.welcomeKitPage
    }
  }
})

export const empowermentTabAdditionalContentIdsAtom = atom<string[]>([])

function empowermentTabAdditionalContentKey(
  empowermentTabAdditionalContentIds: string[]
) {
  return [
    'empowermentTabAdditionalContentAtom',
    [...empowermentTabAdditionalContentIds]
  ]
}

export const [empowermentTabAdditionalContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const empowermentTabAdditionalContentIds = get(
    empowermentTabAdditionalContentIdsAtom
  )

  return {
    queryKey: empowermentTabAdditionalContentKey(
      empowermentTabAdditionalContentIds
    ),
    queryFn: async () => {
      if (empowermentTabAdditionalContentIds.length > 0) {
        const additionalContent: BasicWelcomeKitContentChunk[] = []

        for (let i = 0; i < empowermentTabAdditionalContentIds.length; i++) {
          const response = await api.getWelcomeKitContentChunk({
            id: empowermentTabAdditionalContentIds[i]
          })

          if (response.welcomeKitPersonalFeedback) {
            additionalContent.push(response.welcomeKitPersonalFeedback)
          }
        }

        return additionalContent
      }

      return []
    }
  }
})

function parentSelfCareTabContentKey(parentSelfCareTabId: string) {
  return ['parentSelfCareTabContentAtom', parentSelfCareTabId]
}

export const [parentSelfCareTabContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const parentSelfCareTabId =
    process.env.REACT_APP_CONTENTFUL_PARENT_SELF_CARE_TAB!

  return {
    queryKey: parentSelfCareTabContentKey(parentSelfCareTabId),
    queryFn: async () => {
      const response = await api.getParentSelfCareTabContent({
        id: parentSelfCareTabId
      })

      return response.welcomeKitPage
    }
  }
})

export const parentSelfCareTabAdditionalContentIdsAtom = atom<string[]>([])

function parentSelfCareTabAdditionalContentKey(
  parentSelfCareTabAdditionalContentIds: string[]
) {
  return [
    'parentSelfCareTabAdditionalContentAtom',
    [...parentSelfCareTabAdditionalContentIds]
  ]
}

export const [parentSelfCareTabAdditionalContentAtom] = atomsWithQuery(
  (get) => {
    const api = get(contentfulApiAtom)
    const parentSelfCareTabAdditionalContentIds = get(
      parentSelfCareTabAdditionalContentIdsAtom
    )

    return {
      queryKey: parentSelfCareTabAdditionalContentKey(
        parentSelfCareTabAdditionalContentIds
      ),
      queryFn: async () => {
        if (parentSelfCareTabAdditionalContentIds.length > 0) {
          const additionalContent: BasicWelcomeKitContentChunk[] = []

          for (
            let i = 0;
            i < parentSelfCareTabAdditionalContentIds.length;
            i++
          ) {
            const response = await api.getWelcomeKitContentChunk({
              id: parentSelfCareTabAdditionalContentIds[i]
            })

            if (response.welcomeKitPersonalFeedback) {
              additionalContent.push(response.welcomeKitPersonalFeedback)
            }
          }

          return additionalContent
        }

        return []
      }
    }
  }
)

function parentTestimonialCollectionKey() {
  return ['parentTestimonialCollectionAtom']
}

export const [parentTestimonialCollectionAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)

  return {
    queryKey: parentTestimonialCollectionKey(),
    queryFn: async () => {
      const response = await api.getParentTestimonialCollection()

      return response.parentTestimonialCollection?.items
    }
  }
})

function childStyleIntroContentKey(childStyleIntroId: string) {
  return ['childStyleIntroContentAtom', childStyleIntroId]
}

export const [childStyleIntroContentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const childStyleIntroId = process.env.REACT_APP_CONTENTFUL_CHILD_STYLE_INTRO!

  return {
    queryKey: childStyleIntroContentKey(childStyleIntroId),
    queryFn: async () => {
      const response = await api.getChildStyleIntroContent({
        id: childStyleIntroId
      })

      return response.welcomeKitPage
    }
  }
})

function customerDetailsKey() {
  return ['customerDetailsAtom']
}

export const [customerDetailsAtom] = atomsWithQuery<CustomerDetails | null>(
  (get) => {
    const api = get(p4pApiAtom)

    return {
      queryKey: customerDetailsKey(),
      queryFn: async () => {
        const response = await api.getCustomerDetails()

        if (response.status === 200 && response.data['details']) {
          return response.data['details']
        }

        return null
      }
    }
  }
)

function userDashboardDataKey() {
  return ['userDashboardDataAtom']
}

export const [userDashboardDataAtom] = atomsWithQuery<UserDashboardData | null>(
  (get) => {
    const api = get(p4pApiAtom)

    return {
      queryKey: userDashboardDataKey(),
      queryFn: async () => {
        const response = await api.getUserDashboardData()

        if (response.status === 200) {
          return response.data
        }

        return null
      }
    }
  }
)

export const refetchUserDashboardDataAtom = atom(null, (_, set) => {
  return set(userDashboardDataAtom, { type: 'refetch' })
})

function kidQuizResultsKey() {
  return ['kidQuizResultsAtom']
}

export const [kidQuizResultsAtom] = atomsWithQuery<KidQuizResults[] | null>(
  (get) => {
    const api = get(p4pApiAtom)

    return {
      queryKey: kidQuizResultsKey(),
      queryFn: async () => {
        const response = await api.getKidQuizResults()

        if (response?.status === 200) {
          return response.data
        }

        return null
      }
    }
  }
)

export const refetchKidQuizResultsAtom = atom(null, (_, set) => {
  return set(kidQuizResultsAtom, { type: 'refetch' })
})

function childStyleResultsHeaderKey(childStyleResultsHeaderId: string) {
  return ['childStyleResultsHeaderAtom', childStyleResultsHeaderId]
}

export const [childStyleResultsHeaderAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const childStyleResultsHeaderId =
    process.env.REACT_APP_CONTENTFUL_CHILD_STYLE_RESULTS_HEADER!

  return {
    queryKey: childStyleResultsHeaderKey(childStyleResultsHeaderId),
    queryFn: async () => {
      const response = await api.getChildStyleHeader({
        id: childStyleResultsHeaderId
      })

      return response.welcomeKitPage
    }
  }
})

export type UserPostcardsSearchData = {
  searchQuery?: string
  contentType?: string
  grades: number[]
  limit: number
  skip: number
}

export const userPostcardsSearchDataAtom = atom<
  UserPostcardsSearchData | undefined
>(undefined)

function userPostcardsSearchKey(
  userPostcardsSearchData?: UserPostcardsSearchData
) {
  return [
    'userPostcardsSearchAtom',
    userPostcardsSearchData?.searchQuery,
    userPostcardsSearchData?.contentType,
    userPostcardsSearchData?.grades,
    userPostcardsSearchData?.limit,
    userPostcardsSearchData?.skip
  ]
}

export const [userPostcardsSearchAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const userPostcardsSearchData = get(userPostcardsSearchDataAtom)

  return {
    queryKey: userPostcardsSearchKey(userPostcardsSearchData),
    queryFn: async () => {
      if (
        !userPostcardsSearchData ||
        userPostcardsSearchData.grades.length === 0
      )
        return {
          total: 0,
          items: []
        }

      const response = await api.searchUserPostcards({
        searchQuery: userPostcardsSearchData.searchQuery,
        contentType: userPostcardsSearchData.contentType,
        grades: userPostcardsSearchData.grades,
        limit: userPostcardsSearchData.limit,
        skip: userPostcardsSearchData.skip
      })

      return response.postcardCollection
    }
  }
})

function userPostcardsFilterOptionsKey(userPostcardsFilterOptions?: string[]) {
  return ['userPostcardsFilterOptionsAtom', userPostcardsFilterOptions]
}

export const [userPostcardsFilterOptionsAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const userPostcardsFilterOptions = [
    process.env.REACT_APP_CONTENTFUL_CONTENT_TYPE_SOCIAL_DEVT!,
    process.env.REACT_APP_CONTENTFUL_CONTENT_TYPE_EMOTIONAL_DEVT!,
    process.env.REACT_APP_CONTENTFUL_CONTENT_TYPE_COGNITIVE_DEVT!,
    process.env.REACT_APP_CONTENTFUL_PARENT_SELF_CARE!
  ]

  return {
    queryKey: userPostcardsFilterOptionsKey(userPostcardsFilterOptions),
    queryFn: async () => {
      const response = await api.getUserPostcardsFilterOptions({
        options: userPostcardsFilterOptions
      })

      if (response.contentCategoryCollection?.items) {
        return [undefined, ...response.contentCategoryCollection.items]
      }

      return []
    }
  }
})

function welcomeKitParentQuizNotAnsweredKey(
  welcomeKitParentQuizNotAnswered: string
) {
  return [
    'welcomeKitParentQuizNotAnsweredAtom',
    welcomeKitParentQuizNotAnswered
  ]
}

export const [welcomeKitParentQuizNotAnsweredAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const welcomeKitParentQuizNotAnswered =
    process.env.REACT_APP_CONTENTFUL_WELCOME_KIT_PARENT_QUIZ_NOT_ANSWERED!

  return {
    queryKey: welcomeKitParentQuizNotAnsweredKey(
      welcomeKitParentQuizNotAnswered
    ),
    queryFn: async () => {
      const response = await api.getWelcomeKitParentQuizNotAnswered({
        id: welcomeKitParentQuizNotAnswered
      })

      return response.postcardPage
    }
  }
})

function childStyleKidQuizNotAnsweredKey(
  childStyleKidQuizNotAnsweredKey: string
) {
  return ['childStyleKidQuizNotAnsweredAtom', childStyleKidQuizNotAnsweredKey]
}

export const [childStyleKidQuizNotAnsweredAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const childStyleKidQuizNotAnswered =
    process.env.REACT_APP_CONTENTFUL_CHILD_STYLE_KID_QUIZ_NOT_ANSWERED!

  return {
    queryKey: childStyleKidQuizNotAnsweredKey(childStyleKidQuizNotAnswered),
    queryFn: async () => {
      const response = await api.getChildStyleKidQuizNotAnswered({
        id: childStyleKidQuizNotAnswered
      })

      return response.postcardPage
    }
  }
})
