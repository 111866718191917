import { Spinner } from 'react-bootstrap'

/**
 * Renders the default loading component used throughout the
 * whole application.
 */
export function LoadingView() {
  return (
    <div className="LoadingView">
      <Spinner animation="border" />
      <span>Loading...</span>
    </div>
  )
}
