import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartData
} from 'chart.js'
import { useState } from 'react'
import { Bar } from 'react-chartjs-2'

ChartJS.register(BarElement, CategoryScale, LinearScale)

type Props = {
  data: ChartData<'bar', number[], string>
  labels: string[]
}

export function BarChart({ data, labels }: Props) {
  const [barChartData, setBarChartData] = useState<
    ChartData<'bar', number[], string>
  >({
    labels: labels,
    datasets: [{ data: [0] }]
  })

  return (
    <div className="BarChart">
      <Bar
        data={barChartData}
        options={{
          indexAxis: 'y' as const,
          elements: {
            bar: {
              borderRadius: Number.MAX_VALUE,
              borderSkipped: false
            }
          },
          maintainAspectRatio: true,
          responsive: true,
          scales: {
            x: {
              border: {
                display: false
              },
              grid: {
                color: 'skyblue'
              },
              ticks: {
                display: false
              },
              min: 0,
              max: 12
            },
            y: {
              border: {
                display: false
              },
              grid: {
                display: false
              },
              ticks: {
                color: 'black',
                font: {
                  weight: 600
                }
              },
              min: 0,
              max: 12
            }
          },
          animation: {
            onComplete: (event) => {
              if (event.currentStep && event.currentStep === event.numSteps) {
                const datasets = data.datasets.map((dataset) => {
                  return {
                    ...dataset,
                    data: dataset.data.map((item) => item + 6)
                  }
                })

                setBarChartData({ ...data, datasets: datasets })
              }
            }
          },
          animations: {
            y: {
              duration: 0
            }
          },
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          }
        }}
      />
    </div>
  )
}
