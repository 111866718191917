import { BasicQuizQuestion } from 'data/contentful/api.graphql'
import { useAtomValue, useSetAtom } from 'jotai'
import { p4pApiAtom } from 'data'
import { KidQuiz } from '../core/types'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { refetchKidQuizResultsAtom } from 'pages/resource_room'

type Props = {
  quizQuestion: BasicQuizQuestion
  quizAnswers: KidQuiz
}

type PayloadProps = {
  name: string
  value: string | number
}

export function KidQuizGetResults({ quizQuestion, quizAnswers }: Props) {
  const api = useAtomValue(p4pApiAtom)

  const refetchKidQuizResults = useSetAtom(refetchKidQuizResultsAtom)

  const navigate = useNavigate()

  async function handleSubmitQuizAnswers() {
    const sliderQuestions = quizAnswers.sliderQuestions.map((answer) => ({
      name: answer.variableName,
      value: answer.value
    }))

    let payload: PayloadProps[] = []

    for (const iterator in sliderQuestions) {
      if (!sliderQuestions[iterator].name || !sliderQuestions[iterator].value) {
        continue
      }
      payload.push({
        name: 'scalequestion[]',
        value: sliderQuestions[iterator].name
      })
      payload.push({
        name: `scaleOption${+iterator + 1}`,
        value: sliderQuestions[iterator].value
      })
    }

    let grade: number | null = null

    for (const iterator in quizAnswers) {
      if (iterator === 'grade') {
        grade = quizAnswers[iterator]!
        payload.push({ name: 'grade', value: grade })
      } else if (iterator !== 'sliderQuestions') {
        payload.push({ name: iterator, value: quizAnswers[iterator] })
      }
    }

    try {
      const response = await api.calculateKidQuizResults({
        resultdata: payload
      })

      if (response.status !== 200) {
        toast.error('Error while processing request')
        return
      }

      await refetchKidQuizResults()

      if (grade) {
        navigate(`/child-style?child=${grade.toString()}`)
      } else {
        navigate('/child-style')
      }
    } catch (e) {
      toast.error('Error while processing request')
      console.error(e)
    }
  }

  return (
    <div className="KidQuizGetResults">
      <h3>{quizQuestion.quizQuestion}</h3>

      <button className="submit" onClick={handleSubmitQuizAnswers}>
        Get results!
      </button>
    </div>
  )
}
