import { imgCloseWhiteSmaller } from 'core/images'
import { p4pApiAtom } from 'data'
import { useFormik } from 'formik'
import { useAtomValue } from 'jotai'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

type Props = {
  isModalShowing: boolean
  onClose?: () => void
}

/**
 * Renders a modal containing a form to let users send us a message.
 *
 * *WARNING*: for this component to work properly, do not forget to integrate it in
 * the component tree and update [isModalShowing] according to the desired visibility.
 *
 * @param isModalShowing controls whether the modal should be visible or not.
 * @param onClose callback function triggered whenever the modal is closed.
 */
export function GetInTouchModal({ isModalShowing, onClose }: Props) {
  return (
    <Modal
      size="xl"
      show={isModalShowing}
      onHide={onClose}
      centered
      className="GetInTouchModal"
    >
      <GetInTouch onClose={onClose} />
    </Modal>
  )
}

type GetInTouchProps = {
  onClose?: () => void
}

/**
 * Renders a form to let users send us a message.
 *
 * @param onClose callback function triggered whenever the modal is closed.
 */
export function GetInTouch({ onClose }: GetInTouchProps) {
  const api = useAtomValue(p4pApiAtom)

  const initialValues = {
    email: '',
    message: ''
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await api.getInTouch(values)

        if (response?.status !== 200 || !response?.data?.message) {
          toast.error('Error while processing request')
          return
        }

        toast.success('Thank you for your message!')

        // This function comes from Formik and it basically clears the form
        // so users are not confused after sending the message successfully.
        resetForm()

        onClose?.()
      } catch (e) {
        toast.error('Error while processing request')
        console.error(e)
      }
    },
    validate: (values) => {
      const error = {}

      if (values.email.length === 0) {
        error['email'] = 'Please provide an email'
      }

      if (values.message.length === 0) {
        error['message'] = 'Please type your message here'
      }

      return error
    }
  })

  return (
    <section className="GetInTouch">
      <div className="container">
        {onClose && (
          <div
            className="closeBtn"
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              onClose?.()
            }}
          >
            <img
              src={imgCloseWhiteSmaller}
              className="img-fluid"
              alt="Close button"
            />
          </div>
        )}

        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-lg-3">
              <h3>Get in touch.</h3>
            </div>

            <div className="col-lg-6">
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />

              {formik.errors.email && (
                <span className="errorMessage">{formik.errors.email}</span>
              )}

              <textarea
                name="message"
                placeholder="Message"
                onChange={formik.handleChange}
                value={formik.values.message}
              />

              {formik.errors.message && (
                <span className="errorMessage">{formik.errors.message}</span>
              )}
            </div>

            <div className="d-flex col-md-4 col-lg-3">
              <input
                className="btnSubmit"
                id="btngetINAbout"
                type="submit"
                name="submit"
                value="Submit!"
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}
