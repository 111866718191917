import { BasicIdea } from 'data'

export function parseParentSelfCareIdeaId(idea: BasicIdea) {
  return idea.ideaTitle?.toLowerCase().replace(/[\s]/g, '_')
}

export function parseChildIdeaId(grade: number, idea: BasicIdea) {
  const ideaId = parseParentSelfCareIdeaId(idea)
  return `${grade}_${ideaId}`
}
