import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { subscriptionOptionsAtom } from '../core/atoms'
import { Dropdown } from 'react-bootstrap'
import { Coupon, Subscription } from '../core/types'
import { CheckoutForm } from './checkout_form/checkout_form'
import { useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

type Props = {
  email: string
  name: string
}

export function SubscriptionUserSignedIn({ email, name }: Props) {
  const [searchParams] = useSearchParams()

  const subscriptions = useAtomValue(subscriptionOptionsAtom)

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [subscription, setSubscription] = useState<Subscription | undefined>()
  const [coupon, setCoupon] = useState<Coupon | undefined>()

  useEffect(() => {
    const time = searchParams.get('time')

    if (time && subscriptions.length > 0) {
      subscriptions.forEach((item) => {
        if (item.interval === time) {
          setSubscription(item)
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="SubscriptionUserSignedIn">
      <Dropdown show={isDropdownVisible}>
        <div
          className="selected"
          onClick={() => setIsDropdownVisible(!isDropdownVisible)}
        >
          <p className="navbar-item">
            {subscription?.name ?? 'Select a subscription'}
          </p>
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
        <Dropdown.Menu>
          {subscriptions.map((item) => (
            <Dropdown.Item
              key={item.price_id}
              onClick={() => {
                if (item.price_id !== subscription?.price_id) {
                  setSubscription(item)
                }

                setIsDropdownVisible(false)
              }}
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {subscription && (
        <CheckoutForm
          name={name}
          email={email}
          subscription={subscription}
          coupon={coupon}
          setCoupon={setCoupon}
        />
      )}
    </div>
  )
}
