import { Suspense } from 'react'
import { LoadingView } from '../loading_view'
import { useAtomValue } from 'jotai'
import { pageModuleSaleAtom } from './atoms'
import { PageModuleSaleContent } from 'components/page_module_sale/page_module_sale_content'

/**
 * Renders the main promotional component for the application.
 *
 * This components contains a brief promotional text alongside all subscription
 * options currently available.
 */
export function PageModuleSale() {
  return (
    <Suspense fallback={<LoadingView />}>
      <PageModuleSaleContentWrapper />
    </Suspense>
  )
}

function PageModuleSaleContentWrapper() {
  const content = useAtomValue(pageModuleSaleAtom)

  if (!content) return <></>

  return <PageModuleSaleContent moduleData={content} />
}
