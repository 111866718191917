import { useState, useEffect } from 'react'
import { DefaultErrorMessage } from './default_error_message'
import { useLocation } from 'react-router-dom'

export function ErrorBoundary(props) {
  const { pathname } = useLocation()

  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const errorHandler = (error) => {
      console.error('Error caught by ErrorBoundary:', error)

      if ('Stripe' in error.target && error.message === 'Script error.') return

      setHasError(true)
    }

    window.addEventListener('error', errorHandler)

    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [])

  useEffect(() => {
    setHasError(false)
  }, [pathname])

  if (hasError) {
    return <DefaultErrorMessage />
  }

  return props.children
}
