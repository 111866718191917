import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { cn } from 'utils/cn'
import { NavbarUserActions } from './navbar_user_actions'
import { useState } from 'react'
import { imgLogo, imgLogoCondensed } from 'core/images'

type Props = {
  scrolledPastThreshold: boolean
  onClickMenuItem?: (id?: string, isGradeFilter?: boolean) => void
  onClickShowLoginModal?: () => void
}

/**
 * Renders the desktop version of the navigation bar.
 *
 * By "desktop" we mean the width of the screen rendering this component should be
 * above a certain threshold. See {@link useWindowData} to learn more.
 *
 * @param scrolledPastThreshold determines whether the user scrolled a certain threshold
 * and the component should react to it.
 * @param onClickMenuItem callback function triggered whenver user taps an item from
 * the navigation bar.
 * @param onClickShowLoginModal callback function triggered whenever the user taps the log in button.
 */
export function NavbarDesktop({
  scrolledPastThreshold,
  onClickMenuItem,
  onClickShowLoginModal
}: Props) {
  const [isDropdownAgeGroupsVisible, setIsDropdownAgeGroupsVisible] =
    useState(false)

  const showDropdownAgeGroups = () => setIsDropdownAgeGroupsVisible(true)
  const hideDropdownAgeGroups = () => setIsDropdownAgeGroupsVisible(false)

  return (
    <header
      className={cn('Navbar', scrolledPastThreshold ? 'CondensedNavbar' : '')}
    >
      <div className="container">
        <div className="row">
          <Link
            className="logo col-md-4 col-sm-12 order-md-2 d-flex justify-content-center"
            to="/"
          >
            <img
              src={scrolledPastThreshold ? imgLogoCondensed : imgLogo}
              alt="Postcards for parents logo"
            />
          </Link>

          <div className="col-md-4 col-sm-6 menu">
            <ul>
              <li>
                <Dropdown
                  className="postcardsDropdown"
                  show={isDropdownAgeGroupsVisible}
                  onMouseEnter={showDropdownAgeGroups}
                  onMouseLeave={hideDropdownAgeGroups}
                >
                  <Link to={'/postcards'}>
                    <p
                      className={cn(
                        'navbar-item',
                        'navbar-item-dropdown',
                        isDropdownAgeGroupsVisible ? 'arrow-up' : 'arrow-down'
                      )}
                    >
                      Postcards <FontAwesomeIcon icon={faAngleUp} />
                    </p>
                  </Link>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        onClickMenuItem?.(
                          process.env.REACT_APP_CONTENTFUL_GRADE_PRE_K_ID
                        )
                      }
                    >
                      Pre K
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        onClickMenuItem?.(
                          process.env.REACT_APP_CONTENTFUL_GRADE_ELEMENTARY_ID
                        )
                      }
                    >
                      Elementary
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        onClickMenuItem?.(
                          process.env.REACT_APP_CONTENTFUL_GRADE_ADOLESCENT_ID
                        )
                      }
                    >
                      Adolescent
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        onClickMenuItem?.(
                          process.env.REACT_APP_CONTENTFUL_PARENT_SELF_CARE
                        )
                      }
                    >
                      Parent Self-care
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

              <li>
                <Link to="/toolkit" className="navbar-item">
                  Toolkit
                </Link>
              </li>

              <li>
                <Link to="/about" className="navbar-item">
                  About
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-4 col-sm-6 order-md-3 d-flex justify-content-end">
            <NavbarUserActions
              scrolledPastThreshold={scrolledPastThreshold}
              onClickLogin={onClickShowLoginModal}
            />
          </div>
        </div>
      </div>
    </header>
  )
}
