import { LoadingView, RichText } from 'components'
import { useAtomValue } from 'jotai'
import { welcomeKitParentQuizNotAnsweredAtom } from 'pages/resource_room/core/atoms'
import { Suspense } from 'react'
import { Link } from 'react-router-dom'

export function WelcomeKitQuizNotAnswered() {
  return (
    <Suspense fallback={<LoadingView />}>
      <WelcomeKitQuizNotAnsweredContent />
    </Suspense>
  )
}

export function WelcomeKitQuizNotAnsweredContent() {
  const content = useAtomValue(welcomeKitParentQuizNotAnsweredAtom)

  return (
    <div className="WelcomeKitQuizNotAnswered">
      <h1 style={{ color: content?.titleTextColor?.value ?? '' }}>
        {content?.name}
      </h1>

      <RichText content={content?.description?.json} />

      <Link to="/quiz">Go to Parent Quiz</Link>
    </div>
  )
}
