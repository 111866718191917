import { AssetRenderer, CustomButton, PageModuleSaleContent } from 'components'
import { PageModuleText } from './page_module_text'
import { PageModuleWelcome } from './page_module_welcome'
import { useNavigate } from 'react-router-dom'
import { BasicPageModule } from 'data'

type Props = {
  moduleData: BasicPageModule
}

export function PageModuleTwoColumns({ moduleData }: Props) {
  const navigate = useNavigate()

  const isWelcomeModule =
    moduleData.sys.id === process.env.REACT_APP_CONTENTFUL_HOMEPAGE_WELCOME_FROM

  if (isWelcomeModule) {
    return <PageModuleWelcome moduleData={moduleData} />
  }

  const isSaleModule =
    moduleData.sys.id === process.env.REACT_APP_CONTENTFUL_HOMEPAGE_LEARN_MORE

  if (isSaleModule) {
    return <PageModuleSaleContent moduleData={moduleData} />
  }

  const isPersonalizedModule =
    moduleData.sys.id ===
    process.env.REACT_APP_CONTENTFUL_HOMEPAGE_PERSONALIZED_FOR_YOUR_FAMILY

  const leftViewClassName = isPersonalizedModule ? 'col-md-4' : 'col-md-6'
  const rightViewClassName = isPersonalizedModule
    ? 'd-flex col-md-6 my-auto align-items-center'
    : 'col-md-6'

  return (
    <div className="row align-items-center justify-content-between">
      <div className={leftViewClassName}>
        <PageModuleText moduleData={moduleData} />
        {isPersonalizedModule && (
          <CustomButton onClick={() => navigate('/quiz')}>
            Find out
          </CustomButton>
        )}
      </div>
      <div className={rightViewClassName}>
        <AssetRenderer asset={moduleData.moduleImagesCollection!.items![0]!} />
      </div>
    </div>
  )
}
