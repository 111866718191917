import { useAtom, useAtomValue } from 'jotai'
import { useParams } from 'react-router-dom'
import { OverrideAtom } from 'utils/jotai'
import { categoryFilterDataAtom, postcardsByCategoryAtom } from '../core/atoms'
import { Suspense, useEffect, useState } from 'react'
import { FilteredDataView, LoadingView, PostcardPreview } from 'components'

const recordsPerPage = 12

export function PostcardsByCategoryPage() {
  const params = useParams()

  return (
    <Suspense fallback={<LoadingView />}>
      <OverrideAtom
        atom={categoryFilterDataAtom}
        value={{ categoryId: params.id, limit: recordsPerPage, skip: 0 }}
      >
        <PostcardsByCategoryPageContent />
      </OverrideAtom>
    </Suspense>
  )
}

function PostcardsByCategoryPageContent() {
  const [categoryFilterData, setCategoryFilterData] = useAtom(
    categoryFilterDataAtom
  )

  const postcards = useAtomValue(postcardsByCategoryAtom)

  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setCategoryFilterData({
      ...categoryFilterData!,
      skip: (currentPage - 1) * recordsPerPage
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const numberOfPages = Math.ceil(
    (postcards?.postcardCollection?.total ?? 0) / recordsPerPage
  )

  return (
    <FilteredDataView
      data={postcards?.postcardCollection}
      title={postcards?.contentCategory?.contentType}
      numberOfPages={numberOfPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      buildItem={(data) => (
        <PostcardPreview postcard={data} showReadMore={false} />
      )}
    />
  )
}
