import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { User } from 'pages/auth'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

type Props = {
  currentUser?: User | null
  isCondensed?: boolean
  onClickLogin?: (event) => void
  onClickLogout?: (event) => void
}

/**
 * Renders the authentication section of the navbar.
 *
 * If there is a signed in user, we show a greeting with their name that
 * when tapped, opens a dropdown with the actions they can take. Otherwise,
 * we show just a link to open the login modal.
 *
 * @param currentUser currently signed in user.
 * @param isCondensed determines whether the user scroll the view past a certain threshold
 * and we should show the condensed version of this component.
 * @param onClickLogin callback function triggered whenever the user taps the log in button.
 * @param onClickLogout callback function triggered whenever the user taps the log out button.
 */
export function NavbarAuthSection({
  currentUser,
  isCondensed,
  onClickLogin,
  onClickLogout
}: Props) {
  const navigate = useNavigate()

  if (currentUser)
    return (
      <div className="NavbarAuthSection">
        <Dropdown>
          {isCondensed ? (
            <Dropdown.Toggle>
              <span>{currentUser?.name?.split(' ')[0]}</span>
            </Dropdown.Toggle>
          ) : (
            <Dropdown.Toggle>
              <FontAwesomeIcon icon={faUser} />
              <span>Hi {currentUser?.name?.split(' ')[0]}!</span>
            </Dropdown.Toggle>
          )}
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => navigate('/welcomeKit')}>
              Resource Room
            </Dropdown.Item>

            <Dropdown.Item onClick={onClickLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )

  return (
    <div className="NavbarAuthSection">
      <h3>
        <span
          className="loginButton"
          data-toggle="modal"
          data-target="/loginModal"
          onClick={onClickLogin}
        >
          {isCondensed ? 'Login' : 'Member Login'}
        </span>
      </h3>
    </div>
  )
}
