import { useAtomValue } from 'jotai'
import { allIndexPostcardsAtom } from '../core/atoms'
import { BasicIndexPostcard } from 'data'
import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ageGroupIds } from '../core/data'
import { ResponsiveGrid } from 'components'

const parentSelfCareId = process.env.REACT_APP_CONTENTFUL_PARENT_SELF_CARE

export function PostcardsPageAllPostcards() {
  const params = useParams()

  const postcards = useAtomValue(allIndexPostcardsAtom)

  const sortedCategories = useMemo(() => sortPostcards(postcards), [postcards])

  return (
    <section className="PostcardsPageAllPostcards row">
      <div className="header">
        {sortedCategories &&
          Object.keys(sortedCategories).map((item) => {
            return (
              <button
                key={item}
                onClick={() =>
                  window.location.replace(`/gradelist/${params.id}/#${item}`)
                }
              >
                {item}
              </button>
            )
          })}
      </div>

      {sortedCategories &&
        Object.keys(sortedCategories).map((item) => {
          const totalAmountOfItems = calculateTotalAmountOfItems(
            sortedCategories[item]
          )

          return (
            <div key={item}>
              <h2 id={`${item}`} className="grade">
                {item}
              </h2>

              <div className="divider" />

              <ResponsiveGrid
                totalAmountOfItems={totalAmountOfItems}
                numberOfColumns={3}
              >
                {Object.keys(sortedCategories[item]).map((indexCategory) => {
                  return (
                    <>
                      {sortedCategories[item][indexCategory].map(
                        (category, index) => {
                          const isLastCategory =
                            index ===
                            sortedCategories[item][indexCategory].length - 1

                          return (
                            <>
                              {index === 0 && (
                                <div className="gridItemContainer">
                                  <h3 className="title col-md-3">
                                    {indexCategory}
                                  </h3>
                                </div>
                              )}
                              <div
                                key={category.id}
                                className="gridItemContainer"
                              >
                                <Link
                                  className={`postcardLink col-md-3 ${
                                    isLastCategory ? 'last' : ''
                                  }`}
                                  to={`/details/${category.id}`}
                                >
                                  {category.indexTopic}
                                </Link>
                              </div>
                            </>
                          )
                        }
                      )}
                    </>
                  )
                })}
              </ResponsiveGrid>
            </div>
          )
        })}
    </section>
  )
}

function sortPostcards(postcards?: Array<BasicIndexPostcard | null> | null) {
  if (!postcards) return undefined

  const sorted = {}

  const temp = {}

  postcards.forEach((postcard) => {
    let key

    ageGroupIds.forEach((ageGroupId) => {
      if (!ageGroupId) return

      const contentTypeId = postcard?.contentType?.sys.id

      if (contentTypeId === parentSelfCareId) {
        if (contentTypeId === ageGroupId) {
          key = postcard?.contentType?.contentType

          if (!temp[ageGroupId]) {
            temp[ageGroupId] = key
          }
        }
      }

      const gradeLevelId = postcard?.gradeLevel?.sys.id

      if (ageGroupId === gradeLevelId) {
        key = postcard?.gradeLevel?.gradeTitle

        if (!temp[ageGroupId]) {
          temp[ageGroupId] = key
        }
      }
    })

    postcard?.indexCategoryCollection?.items.forEach((category) => {
      const indexCategory = category?.indexCategory

      if (indexCategory) {
        if (!sorted[key]) {
          sorted[key] = []
        }

        if (!sorted[key][indexCategory]) {
          sorted[key][indexCategory] = []
        }

        sorted[key][indexCategory] = [
          ...sorted[key][indexCategory],
          { id: postcard.sys.id, indexTopic: postcard.indexTopic }
        ]
      }
    })
  })

  const normalizedSorted = {}

  ageGroupIds.forEach((ageGroupId) => {
    const index = temp[ageGroupId!]

    normalizedSorted[index] = sorted[index]
  })

  return normalizedSorted
}

function calculateTotalAmountOfItems(sortedCategories: object) {
  let totalItems = 0

  Object.keys(sortedCategories).forEach((indexCategory) => {
    totalItems++

    sortedCategories[indexCategory].forEach((_) => {
      totalItems++
    })
  })

  return totalItems
}
