import { contentfulApiAtom } from 'data'
import { atom } from 'jotai'
import { atomsWithQuery } from 'jotai-tanstack-query'

/**
 * Holds the id for the document to be queried by {@link legalDocumentAtom}.
 */
export const legalDocumentIdAtom = atom<string | undefined>(undefined)

function legalDocumentKey(legalDocumentId?: string) {
  return ['legalDocumentAtom', legalDocumentId]
}

/**
 * Queries all content for a given legal document.
 *
 * To change which one to load, change {@link legalDocumentIdAtom}'s
 * value.
 */
export const [legalDocumentAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const legalDocumentId = get(legalDocumentIdAtom)

  return {
    queryKey: legalDocumentKey(legalDocumentId),
    queryFn: async () => {
      if (!legalDocumentId) return null

      const toolDetails = await api.getBasicPageModule({
        id: legalDocumentId
      })

      return toolDetails.pageModule
    }
  }
})
