import { useMemo } from 'react'
import { PageModuleText } from './page_module_text'
import { PageModuleTwoColumns } from './page_module_two_columns'
import { PageModuleSlideshow } from './page_module_slideshow'
import { PageModuleHowItWorks } from './page_module_how_it_works'
import { PageModuleSixColumns } from './page_module_six_columns'
import { BasicPageModule } from 'data'

type Props = {
  moduleData: BasicPageModule
}

export function PageModule({ moduleData }: Props) {
  const body = useMemo(() => {
    switch (moduleData.pageModuleType?.pageModuleType) {
      case '2 columns':
        return <PageModuleTwoColumns moduleData={moduleData} />

      case '6 columns':
        return <PageModuleSixColumns moduleData={moduleData} />

      case 'Side slideshow':
        return <PageModuleSlideshow moduleData={moduleData} />

      case 'Staggered boxes':
        return <PageModuleHowItWorks moduleData={moduleData} />

      default:
        return <PageModuleText moduleData={moduleData} />
    }
  }, [moduleData])

  const id =
    moduleData.sys.id === process.env.REACT_APP_CONTENTFUL_PAGE_MODULE_SALE
      ? 'learnMore'
      : ''

  return (
    <div
      id={id}
      className="PageModule"
      style={{
        ...(moduleData.backgroundColor?.value && {
          backgroundColor: moduleData.backgroundColor?.value
        })
      }}
    >
      <section className="container">{body}</section>
    </div>
  )
}
