import { currentUserAtom } from 'pages/auth'
import { authServiceAtom } from 'pages/auth/core/atoms'
import { useAtomValue, useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { NavbarUserActionsCompleteView } from './navbar_user_actions_complete_view'
import { NavbarUserActionsCondensedView } from './navbar_user_actions_condensed_view'

type Props = {
  scrolledPastThreshold: boolean
  onClickLogin?: (event) => void
}

/**
 * Renders the actions users have access in the navigation bar.
 *
 * @param scrolledPastThreshold determines whether the user scrolled a certain threshold
 * and the component should react to it.
 * @param onClickLogin callback function triggered whenever the user taps the log in button.
 */
export function NavbarUserActions({
  scrolledPastThreshold,
  onClickLogin
}: Props) {
  const authService = useAtomValue(authServiceAtom)
  const [currentUser] = useAtom(currentUserAtom)

  const navigate = useNavigate()

  async function logout(event) {
    event.preventDefault()

    await authService.logout(() => {
      navigate('/')
    })
  }

  if (scrolledPastThreshold) {
    return (
      <NavbarUserActionsCondensedView
        currentUser={currentUser}
        onClickLogin={onClickLogin}
        onClickLogout={logout}
      />
    )
  }

  return (
    <NavbarUserActionsCompleteView
      currentUser={currentUser}
      onClickLogin={onClickLogin}
      onClickLogout={logout}
    />
  )
}
