import { AssetRenderer, RichText } from 'components'
import { BasicWelcomeKitContentChunk } from 'data'

type Props = {
  contentChunk: BasicWelcomeKitContentChunk
}

export function WelcomeKitContentChunkRenderer({ contentChunk }: Props) {
  return (
    <div className="row">
      <div className="col-md-7">
        <RichText content={contentChunk.parentFeedback?.json} />
      </div>
      <div className="col-md-5">
        {contentChunk.imageToGoWithContentCollection?.items.map((item) => (
          <AssetRenderer key={item?.fileName} asset={item} />
        ))}
      </div>
    </div>
  )
}
