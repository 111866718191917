import { useAtomValue } from 'jotai'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper/modules'
import { PostcardPreview } from 'components/postcard_preview'
import { recentPostcardsAtom } from 'core/atoms'

export function RecentPostcards() {
  const recentPostcards = useAtomValue(recentPostcardsAtom)

  return (
    <section className="RecentPostcards">
      <div className="container">
        <h2>Recent Postcards</h2>
        <div className="swiperContainer">
          <div className="swiper-rtl swiper-button-prev custom-swiper-button-prev" />
          <Swiper
            loop={true}
            speed={1000}
            breakpoints={{
              640: {
                slidesPerView: 1
              },
              768: {
                slidesPerView: 2
              },
              1024: {
                slidesPerView: 3
              }
            }}
            navigation={{
              nextEl: '.custom-swiper-button-next',
              prevEl: '.custom-swiper-button-prev'
            }}
            modules={[Autoplay, Navigation]}
          >
            {recentPostcards?.slice(0, 10).map((postcard) => (
              <SwiperSlide key={postcard?.sys.id}>
                {postcard && <PostcardPreview postcard={postcard} />}
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-rtl swiper-button-next custom-swiper-button-next" />
        </div>
      </div>
    </section>
  )
}
