import { contentfulApiAtom } from 'data'
import { atomsWithQuery } from 'jotai-tanstack-query'

function parentQuizKey(parentQuizId: string) {
  return ['parentQuizAtom', parentQuizId]
}

export const [parentQuizAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const parentQuizId = process.env.REACT_APP_CONTENTFUL_PARENT_QUIZ_ID!

  return {
    queryKey: parentQuizKey(parentQuizId),
    queryFn: async () => {
      const parentQuiz = await api.getQuiz({
        id: parentQuizId
      })

      parentQuiz.quiz?.quizQuestionsCollection?.items.shift()

      return parentQuiz.quiz?.quizQuestionsCollection?.items
    }
  }
})

function kidQuizKey(kidQuizId: string) {
  return ['kidQuizAtom', kidQuizId]
}

export const [kidQuizAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const kidQuizId = process.env.REACT_APP_CONTENTFUL_KID_QUIZ_ID!

  return {
    queryKey: kidQuizKey(kidQuizId),
    queryFn: async () => {
      const kidQuiz = await api.getQuiz({
        id: kidQuizId
      })

      return kidQuiz.quiz?.quizQuestionsCollection?.items
    }
  }
})

function kidQuizUserSignedOutKey(kidQuizUserSignedOutId: string) {
  return ['kidQuizUserSignedOutAtom', kidQuizUserSignedOutId]
}

export const [kidQuizUserSignedOutAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const kidQuizUserSignedOutId =
    process.env.REACT_APP_CONTENTFUL_KID_QUIZ_USER_SIGNED_OUT!

  return {
    queryKey: kidQuizUserSignedOutKey(kidQuizUserSignedOutId),
    queryFn: async () => {
      const kidQuiz = await api.getKidQuizUserSignedOut({
        id: kidQuizUserSignedOutId
      })

      return kidQuiz.postcardPage
    }
  }
})

function kidQuizNoActiveSubscriptionKey(kidQuizNoActiveSubscription: string) {
  return ['kidQuizNoActiveSubscriptionAtom', kidQuizNoActiveSubscription]
}

export const [kidQuizNoActiveSubscriptionAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const kidQuizNoActiveSubscription =
    process.env.REACT_APP_CONTENTFUL_KID_QUIZ_NO_ACTIVE_SUBSCRIPTION!

  return {
    queryKey: kidQuizNoActiveSubscriptionKey(kidQuizNoActiveSubscription),
    queryFn: async () => {
      const kidQuiz = await api.getKidQuizNoActiveSubscription({
        id: kidQuizNoActiveSubscription
      })

      return kidQuiz.postcardPage
    }
  }
})
