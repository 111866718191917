import { AssetRenderer } from 'components'
import { PageModuleText } from './page_module_text'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper/modules'
import { PageModuleTestimonials } from './page_module_testimonials'
import { BasicPageModule } from 'data'

type Props = {
  moduleData: BasicPageModule
}

export function PageModuleSlideshow({ moduleData }: Props) {
  const isTestimonialModule =
    moduleData.pageModuleName?.includes('Testimonial text')

  if (isTestimonialModule) {
    return <PageModuleTestimonials moduleData={moduleData} />
  }

  const items = moduleData.moduleImagesCollection?.items

  return (
    <div className="row align-items-center">
      <div className="col-md-5">
        <PageModuleText moduleData={moduleData} />
      </div>
      <div className="col-md-7">
        <Swiper
          slidesPerView={1}
          spaceBetween={32}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            waitForTransition: true
          }}
          speed={1000}
          navigation={true}
          modules={[Autoplay, Navigation]}
        >
          {items?.map((item) => (
            <SwiperSlide key={item?.sys.id}>
              <div className="slider-image">
                <AssetRenderer asset={item} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
