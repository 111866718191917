import { AssetRenderer, RichText } from 'components'
import { useAtomValue } from 'jotai'
import { ParentQuizResults } from 'pages/quiz/core/types'
import {
  parentPersonaAtom,
  parentPersonaIdAtom
} from 'pages/resource_room/core/atoms'
import { OverrideAtom } from 'utils/jotai'

type Props = {
  results: ParentQuizResults
}

export function ParentPersona({ results }: Props) {
  if (results.snapshot_cluster_1) {
    return (
      <OverrideAtom
        atom={parentPersonaIdAtom}
        value={results.snapshot_cluster_1}
      >
        <ParentPersonaContent />
      </OverrideAtom>
    )
  }

  if (results.snapshot_cluster_2) {
    return (
      <OverrideAtom
        atom={parentPersonaIdAtom}
        value={results.snapshot_cluster_2}
      >
        <ParentPersonaContent />
      </OverrideAtom>
    )
  }

  if (results.snapshot_cluster_3) {
    return (
      <OverrideAtom
        atom={parentPersonaIdAtom}
        value={results.snapshot_cluster_3}
      >
        <ParentPersonaContent />
      </OverrideAtom>
    )
  }

  if (results.snapshot_cluster_4) {
    return (
      <OverrideAtom
        atom={parentPersonaIdAtom}
        value={results.snapshot_cluster_4}
      >
        <ParentPersonaContent />
      </OverrideAtom>
    )
  }

  if (results.snapshot_cluster_5) {
    return (
      <OverrideAtom
        atom={parentPersonaIdAtom}
        value={results.snapshot_cluster_5}
      >
        <ParentPersonaContent />
      </OverrideAtom>
    )
  }

  if (results.snapshot_cluster_6) {
    return (
      <OverrideAtom
        atom={parentPersonaIdAtom}
        value={results.snapshot_cluster_6}
      >
        <ParentPersonaContent />
      </OverrideAtom>
    )
  }

  return <></>
}

function ParentPersonaContent() {
  const parentPersona = useAtomValue(parentPersonaAtom)

  return (
    <div className="ParentPersona row">
      <div className="col-lg-6 col-md-6">
        {parentPersona?.imageToGoWithContentCollection?.items.map((asset) => (
          <AssetRenderer key={asset?.sys.id} asset={asset} />
        ))}
      </div>
      <div className="col-lg-6 col-md-6">
        <RichText content={parentPersona?.parentFeedback?.json} />
      </div>
    </div>
  )
}
