import { User } from 'pages/auth'
import { getGradeLabel } from 'core/grades'
import { Link } from 'react-router-dom'
import { KidQuiz } from '../core/types'

type Props = {
  currentUser: User
  quizAnswers: KidQuiz
  setQuizAnswers: React.Dispatch<React.SetStateAction<KidQuiz>>
  onGoToNextSection: (grade: number) => void
}

export function KidQuizGradeSelection({
  currentUser,
  quizAnswers,
  setQuizAnswers,
  onGoToNextSection
}: Props) {
  const selectedGrade = quizAnswers.grade

  const sortedGrades = Object.values(currentUser.grades ?? []).sort((a, b) =>
    a > b ? 1 : -1
  )

  function handleGradeSelected(grade: number) {
    setQuizAnswers({ ...quizAnswers, grade: grade })
  }

  return (
    <div className="KidQuizGradeSelection">
      <h3>Your child's age/grade is...</h3>

      <div className="gradeList">
        {Object.values(sortedGrades).map((grade: number) => {
          const label = getGradeLabel(grade)

          return (
            <label key={grade}>
              <input
                type="checkbox"
                name={`grade=${grade}`}
                onChange={() => handleGradeSelected(grade)}
                checked={grade === selectedGrade}
              />
              <span className="checkmark"></span>
              <span className="grade">{label}</span>
            </label>
          )
        })}
      </div>

      <p className="updateGrades">
        Not the right grades? <Link to="/userDashboard">Change here.</Link>
      </p>

      <button
        className="next"
        onClick={() => {
          if (selectedGrade === undefined) return

          onGoToNextSection?.(selectedGrade)
        }}
        disabled={selectedGrade === undefined}
      >
        Next
      </button>
    </div>
  )
}
