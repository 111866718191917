import { useAtomValue } from 'jotai'
import { Quotes } from '../quotes'
import { ChildStyleContent } from './child_style_content/child_style_content'
import { kidQuizResultsAtom } from 'pages/resource_room/core/atoms'
import { ChildStyleKidQuizNotAnswered } from './child_style_kid_quiz_not_answered'

type Props = {
  userFirstName: string
}

export function ChildStyle({ userFirstName }: Props) {
  const kidQuizResults = useAtomValue(kidQuizResultsAtom)

  return (
    <section className="ChildStyle">
      <div className="container">
        <h2>{userFirstName}’s Room</h2>

        <Quotes />

        {kidQuizResults ? (
          <ChildStyleContent kidQuizResults={kidQuizResults} />
        ) : (
          <ChildStyleKidQuizNotAnswered />
        )}
      </div>
    </section>
  )
}
