import { CustomSlider } from 'components'
import { BasicQuizQuestion } from 'data/contentful/api.graphql'
import { QuizQuestion } from '../core/types'

type Props = {
  reference?: React.RefObject<HTMLDivElement>
  quizQuestion: BasicQuizQuestion
  quizAnswer: QuizQuestion
  onAnswerChanged: (value: number) => void
  onAnswerMouseUp: (value: number) => void
}

export function QuizItem({
  reference,
  quizQuestion,
  quizAnswer,
  onAnswerChanged,
  onAnswerMouseUp
}: Props) {
  return (
    <div className="QuizItem" ref={reference}>
      <h3>{quizQuestion.quizQuestion}</h3>
      <p />
      <CustomSlider
        value={quizAnswer.value}
        onSliderValueChanged={(value) => {
          onAnswerChanged?.(value)
        }}
        onSliderMouseUp={(value) => {
          onAnswerMouseUp?.(value)
        }}
      />
      <p />
      <div className="optionsContainer">
        {quizQuestion.text1 && (
          <p className="quizOption">{quizQuestion.text1}</p>
        )}
        {quizQuestion.text2 && (
          <p className="quizOption">{quizQuestion.text2}</p>
        )}
        {quizQuestion.text3 && (
          <p className="quizOption">{quizQuestion.text3}</p>
        )}
      </div>
    </div>
  )
}
