import { AssetRenderer, CustomText, RichText } from 'components'
import { useAtomValue } from 'jotai'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { productOfferDataAtom } from 'core/atoms'
import { BasicPageModule } from 'data'

type Props = {
  moduleData: BasicPageModule
}

/**
 * Renders the content for the main promotional component for the application.
 *
 * This components contains a brief promotional text alongside all subscription
 * options currently available.
 *
 * @param moduleData comes from Contenful and contains all content to render.
 */
export function PageModuleSaleContent({ moduleData }: Props) {
  const productOffer = useAtomValue(productOfferDataAtom)

  return (
    <div
      className="PageModuleSaleContent row align-items-center justify-content-between"
      id="learnMore"
    >
      <div className="col-md-5 mb-3 mb-md-0">
        <CustomText
          textStyle="headerLarge"
          textColor={moduleData.titleTextColor?.value}
        >
          {moduleData.title}
        </CustomText>

        <p />

        <RichText
          textClassName="content"
          title={moduleData.title}
          content={moduleData.moduleText1?.json}
          textColor={moduleData.contentTextColor?.value}
          fontFamily="Libre Baskerville"
        />

        <AssetRenderer asset={moduleData.moduleImagesCollection?.items[0]} />
      </div>

      <div className="col-md-5">
        <div className="saleView">
          <h3>{productOffer?.productTitle}</h3>

          <p />

          {productOffer?.productFeaturesCollection?.items.map((feature) => (
            <div key={feature?.sys.id} className="feature">
              <FontAwesomeIcon icon={faCheck} color="white" />
              <p>{feature?.productFeature}</p>
            </div>
          ))}

          <div className="subscriptionOptions">
            <Link className="yearly" to={`/register?time=year`}>
              <span>Annual</span>
              <h4>{productOffer?.callOut}</h4>
              <span>{productOffer?.priceYearlyLabel}</span>
            </Link>

            <Link className="monthly" to={`/register?time=month`}>
              <span>Monthly</span>
              <h4>{productOffer?.callOut}</h4>
              <span>{productOffer?.priceMonthlyLabel}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
