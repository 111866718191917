import { Grade } from './types'

/**
 * List of all grades available.
 */
export const grades: Grade[] = [
  {
    key: 'pre_k',
    gradeLabel: 'Pre-K',
    items: [
      { key: -4, label: '1 year', childLabel: 'Your 1 year old' },
      { key: -3, label: '2 yr', childLabel: 'Your 2 year old' },
      { key: -2, label: '3 yr', childLabel: 'Your 3 year old' },
      { key: -1, label: '4 yr', childLabel: 'Your 4 year old' }
    ]
  },
  {
    key: 'elementary',
    gradeLabel: 'Elementary',
    items: [
      { key: 0, label: 'K', childLabel: 'Your kindergartner' },
      { key: 1, label: '1st', childLabel: 'Your 1st Grader' },
      { key: 2, label: '2nd', childLabel: 'Your 2nd Grader' },
      { key: 3, label: '3rd', childLabel: 'Your 3rd Grader' },
      { key: 4, label: '4th', childLabel: 'Your 4th Grader' },
      { key: 5, label: '5th', childLabel: 'Your 5th Grader' }
    ]
  },
  {
    key: 'middle_high',
    gradeLabel: 'Middle / High',
    items: [
      { key: 6, label: '6th', childLabel: 'Your 6th Grader' },
      { key: 7, label: '7th', childLabel: 'Your 7th Grader' },
      { key: 8, label: '8th', childLabel: 'Your 8th Grader' },
      { key: 9, label: '9th', childLabel: 'Your 9th Grader' },
      { key: 10, label: '10th', childLabel: 'Your 10th Grader' },
      { key: 11, label: '11th', childLabel: 'Your 11th Grader' },
      { key: 12, label: '12th', childLabel: 'Your 12th Grader' }
    ]
  }
]

/**
 * Returns the text we use when refering to a child in a
 * certain grade.
 */
export function getChildLabel(grade: number) {
  for (let i = 0; i < grades.length; i++) {
    const gradeItems = grades[i].items

    for (let j = 0; j < gradeItems.length; j++) {
      const item = gradeItems[j]

      if (item.key === grade) {
        return item.childLabel
      }
    }
  }

  return ''
}

/**
 * Returns the text to show users to identify a certain grade.
 */
export function getGradeLabel(grade: number) {
  for (let i = 0; i < grades.length; i++) {
    const gradeItems = grades[i].items

    for (let j = 0; j < gradeItems.length; j++) {
      const item = gradeItems[j]

      if (item.key === grade) {
        return item.label
      }
    }
  }

  // If the grade is larger than 12, it means it is outside of the
  // supported range, so we have to treat it a little differently.
  if (grade > 12) {
    if (grade <= 20) {
      return `${grade}th`
    } else if (grade % 10 === 1) {
      return `${grade}st`
    } else if (grade % 10 === 2) {
      return `${grade}nd`
    } else if (grade % 10 === 3) {
      return `${grade}rd`
    } else {
      return `${grade}th`
    }
  }

  return ''
}
