import { p4pApiAtom } from 'data'
import { useAtomValue } from 'jotai'
import { Suspense, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AdminPageContent } from '../components/admin_page_content'
import { LoadingView } from 'components'

/**
 * Page that contains all of the administrative options for this
 * project.
 *
 * It is important to note that if there is no currently signed in
 * user or they do not have administrative privileges, they are
 * redirected to the home page.
 */
export function AdminPage() {
  const api = useAtomValue(p4pApiAtom)

  const navigate = useNavigate()

  const [isUserAdmin, setIsUserAdmin] = useState(false)

  useEffect(() => {
    // Checks if the currently signed in user have the correct privileges
    // to access this page.
    async function checkIfUserIsAdmin() {
      const response = await api.isUserAdmin()

      if (response && response.status === 200) {
        const isAdmin = response.data.is_admin

        // If the user is an admin, redirect them to the home page.
        if (!isAdmin) {
          navigate('/')
          return
        }

        setIsUserAdmin(isAdmin)
      }
    }

    checkIfUserIsAdmin()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="AdminPage">
      {isUserAdmin && (
        <Suspense fallback={<LoadingView />}>
          <AdminPageContent />
        </Suspense>
      )}
    </div>
  )
}
