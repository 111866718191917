import { useAtomValue } from 'jotai'
import { useParams } from 'react-router-dom'
import { OverrideAtom } from 'utils/jotai'
import {
  gradeFilterDataAtom,
  postcardsPageBannerByIdAtom,
  postcardsPageBannerIdAtom
} from '../core/atoms'
import { Suspense, useState } from 'react'
import { DraggableNotice, LoadingView, PageBanner } from 'components'
import { cn } from 'utils/cn'
import { PostcardsPageRecentPostcards } from '../components/postcards_page_recent_postcards'
import { PostcardsPageAllPostcards } from '../components/postcards_page_all_postcards'

const recordsPerPage = 12

type PostcardsFilter = 'recent' | 'all_topics'

type Props = {
  id?: string
}

export function PostcardsByGradePage() {
  const params = useParams()

  return (
    <Suspense fallback={<LoadingView />}>
      <OverrideAtom
        atom={gradeFilterDataAtom}
        value={{ id: params.id, limit: recordsPerPage, skip: 0 }}
      >
        <OverrideAtom atom={postcardsPageBannerIdAtom} value={params.id}>
          <PostcardsByGradePageContent id={params.id} />
        </OverrideAtom>
      </OverrideAtom>
    </Suspense>
  )
}

function PostcardsByGradePageContent({ id }: Props) {
  const postcardsPageBanner = useAtomValue(postcardsPageBannerByIdAtom)

  return (
    <div className="PostcardsByGradePage container">
      <PageBanner pageBanner={postcardsPageBanner} />

      <Suspense fallback={<LoadingView />}>
        <PostcardsByGradePageFilteredContent id={id} />
      </Suspense>

      <DraggableNotice />
    </div>
  )
}

function PostcardsByGradePageFilteredContent({ id }: Props) {
  const [currentFilter, setCurrentFilter] = useState<PostcardsFilter>('recent')

  const handleClickFilterItem = (filter: PostcardsFilter) =>
    setCurrentFilter(filter)

  const showGrade = id !== process.env.REACT_APP_CONTENTFUL_PARENT_SELF_CARE

  return (
    <div>
      <section className="filterContainer">
        <FilterItem
          filter="recent"
          label="RECENT"
          currentFilter={currentFilter}
          onClick={handleClickFilterItem}
        />
        <span>/</span>
        <FilterItem
          filter="all_topics"
          label="ALL TOPICS"
          currentFilter={currentFilter}
          onClick={handleClickFilterItem}
        />
      </section>

      {currentFilter === 'recent' && (
        <PostcardsPageRecentPostcards
          recordsPerPage={recordsPerPage}
          showGrade={showGrade}
        />
      )}

      {currentFilter === 'all_topics' && <PostcardsPageAllPostcards />}
    </div>
  )
}

type FilterItemProps = {
  filter: PostcardsFilter
  label: string
  currentFilter: PostcardsFilter
  onClick?: (filter: PostcardsFilter) => void
}

export function FilterItem({
  filter,
  label,
  currentFilter,
  onClick
}: FilterItemProps) {
  return (
    <div
      className={cn('filterItem', currentFilter === filter ? 'active' : '')}
      onClick={() => onClick?.(filter)}
    >
      {label}
    </div>
  )
}
