import { contentfulApiAtom } from 'data'
import { atomsWithQuery } from 'jotai-tanstack-query'

function aboutUsKey(aboutUsId?: string) {
  return ['aboutUsAtom', aboutUsId]
}

/**
 * Queries a all content to be shown in the About page.
 */
export const [aboutUsAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const aboutUsId = process.env.REACT_APP_CONTENTFUL_ABOUT_US!

  return {
    queryKey: aboutUsKey(aboutUsId),
    queryFn: async () => {
      const toolDetails = await api.getAboutPageContent({
        id: aboutUsId
      })

      return toolDetails.newPage
    }
  }
})
