import { AboutPageModule } from 'data'
import { useMemo } from 'react'
import { AboutPageContentWhySection } from './about_page_content_why_section'
import { AboutPageContentHowSection } from './about_page_content_how_section'
import { AboutPageContentWhatSection } from './about_page_content_what_section'
import { AboutPageContentTeamSection } from './about_page_content_team_section'

type Props = {
  module?: AboutPageModule | null
}

export function AboutPageContent({ module }: Props) {
  const body = useMemo(() => {
    const title = module?.title?.toLowerCase()

    if (title?.startsWith('why')) {
      return <AboutPageContentWhySection module={module} />
    }

    if (title?.startsWith('what')) {
      return <AboutPageContentWhatSection module={module} />
    }

    if (title?.startsWith('how')) {
      return <AboutPageContentHowSection module={module} />
    }

    if (title?.startsWith('team')) {
      return <AboutPageContentTeamSection module={module} />
    }

    return <></>
  }, [module])

  return (
    <section
      className="AboutPageContent"
      style={{ backgroundColor: module?.backgroundColor?.value ?? '' }}
    >
      <div className="container">
        <span
          className="subtitle"
          style={{ color: module?.titleTextColor?.value ?? '' }}
        >
          {module?.title}
        </span>

        {body}
      </div>
    </section>
  )
}
