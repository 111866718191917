import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useLocationHash } from 'utils/hooks/use_location_hash'

/**
 * Helper component that detects whenever the user navigates to
 * a new url and automatically scrolls to the top of the page.
 */
export function ScrollToTop() {
  const { pathname } = useLocation()
  const hash = useLocationHash()

  useEffect(() => {
    if (hash.length === 0) {
      window.scrollTo(0, 0)
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }, [hash, pathname])

  return null
}
