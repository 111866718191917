import { AssetRenderer, CustomText, RichText } from 'components'
import { BasicAsset, BasicPageModule } from 'data/contentful/api.graphql'
import { Document } from '@contentful/rich-text-types'

type Props = {
  moduleData: BasicPageModule
}

export function PageModuleSixColumns({ moduleData }: Props) {
  return (
    <div className="PageModuleSixColumns">
      <CustomText
        textStyle="headerLarge"
        textColor={moduleData.titleTextColor?.value}
      >
        {moduleData.title}
      </CustomText>

      <p />

      <div className="row text-center">
        <ColumnItem
          content={moduleData.moduleText1?.json}
          image={moduleData.moduleImagesCollection?.items[0]}
          contentTextColor={moduleData.contentTextColor?.value}
        />
        <ColumnItem
          content={moduleData.moduleText2?.json}
          image={moduleData.moduleImagesCollection?.items[1]}
          contentTextColor={moduleData.contentTextColor?.value}
        />
        <ColumnItem
          content={moduleData.moduleText3?.json}
          image={moduleData.moduleImagesCollection?.items[2]}
          contentTextColor={moduleData.contentTextColor?.value}
        />
        <ColumnItem
          content={moduleData.moduleText4?.json}
          image={moduleData.moduleImagesCollection?.items[3]}
          contentTextColor={moduleData.contentTextColor?.value}
        />
        <ColumnItem
          content={moduleData.moduleTest5?.json}
          image={moduleData.moduleImagesCollection?.items[4]}
          contentTextColor={moduleData.contentTextColor?.value}
        />
        <ColumnItem
          content={moduleData.moduleText6?.json}
          image={moduleData.moduleImagesCollection?.items[5]}
          contentTextColor={moduleData.contentTextColor?.value}
        />
      </div>
    </div>
  )
}

type ColumnItemProps = {
  content?: Document | null
  image?: BasicAsset | null
  contentTextColor?: string | null
}

function ColumnItem({ content, image, contentTextColor }: ColumnItemProps) {
  return (
    <div className="ColumnItem col-sm-3 col-md-2 mb-2 mb-md-0">
      <div className="icon rounded-circle">
        <AssetRenderer asset={image} />
      </div>
      <RichText textColor={contentTextColor} content={content} />
    </div>
  )
}
